import ChatPermisionBuilder from "../chat_permission_builder";
import Interactions from "../interactions";

export default class BaseChatPermissionBuilder implements ChatPermisionBuilder {
  private _source: string = '';

  build(): string {
    return this._source;
  }

  reset(): void {
    this._source = '';
  }

  setTemplate(update: string): void {
    this._source += update;
  }

  setStyle(update: string): void {
    this._source += `<style id="${Interactions.ChatPermissionKeys.permissionContainerStyle}">${update}</style>`;
  }
}
