import { BaseInteractionState, ChatReady, DialogState } from "../../index";
import {
  fadeIn,
  fadeOut,
  findBySelector,
  setContent,
} from "../../../../../../../service/dom/dom_utilities";

import AppConfig from "../../../../../app.config";
import DialogStateError from "./dialog_state_error";
import Interactions from "../../../interactions";
import LeadTypes from "../../../../../enum/lead_types";
import { isString } from "../../../../../../../service/lang";

export abstract class BaseDialog
  extends BaseInteractionState
  implements DialogState
{
  get leadType(): LeadTypes {
    throw DialogStateError.undefinedLeadType();
  }

  close(): void {
    this.removeContainer();
    this.switchChatState(ChatReady);
  }

  closeTerms(): void {
    fadeOut(findBySelector(Interactions.PopupSelectors.termsAndConsText));
  }

  protected disableSubmitButton(): void {
    this.service.disable(Interactions.PopupSelectors.submitButton);
  }

  protected enableSubmitButton(): void {
    this.service.enable(Interactions.PopupSelectors.submitButton);
  }

  formatPhone(element: any): void {
    element.value = this.service.formatPhoneNumber(element?.value);
  }

  protected hideError(): void {
    fadeOut(findBySelector(Interactions.PopupSelectors.error));
  }

  protected markVisitorAsConverted(): void {
    this.storage.setVisitorConverted();
  }

  protected notifyFailure(): void {
    throw DialogStateError.undefinedFailureCallback();
  }

  protected notifyGoogleAnalytics(): void {
    this.notifier.notifyGoogleAnalyticsLead();
  }

  protected notifySuccess(): void {
    throw DialogStateError.undefinedSuccessCallback();
  }

  openTerms(): void {
    fadeIn(findBySelector(Interactions.PopupSelectors.termsAndConsText));
  }

  protected removeContainer(): void {
    this.service.remove(Interactions.ModalSelectors.container);
  }

  protected scheduleClose(): void {
    const callback = () => this.removeContainer();
    const timeout = AppConfig.instance.offerCloseTimeout.toMilliseconds();
    this.service.schedule(callback, timeout);
  }

  protected showError(error: string): void {
    const element = findBySelector(Interactions.PopupSelectors.error);
    setContent(element, error);
    fadeIn(element);
  }

  protected showSuccess(): void {
    fadeIn(findBySelector(Interactions.PopupSelectors.success));
  }

  protected startButtonLoader(): void {
    this.service.addClass(
      Interactions.PopupSelectors.submitButton,
      Interactions.StyleKeys.buttonLoading
    );
  }

  protected stopButtonLoader(): void {
    this.service.removeClass(
      Interactions.PopupSelectors.submitButton,
      Interactions.StyleKeys.buttonLoading
    );
  }

  submit(): Promise<void> {
    return new Promise((resolve) => {
      const data = this.service.getFormData();
      const error = this.service.validateFormData(data);

      if (isString(error)) {
        this.showError(error);
        resolve();
      } else {
        this.disableSubmitButton();
        this.startButtonLoader();
        this.service
          .submitLead(this.leadType, data, this.offerEngagement)
          .then((result) => {
            this.hideError();
            this.logger.info("Successfully submitted lead:", result);
            this.suspend();
            this.showSuccess();
            this.scheduleClose();
            this.notifySuccess();
            this.notifyGoogleAnalytics();
            this.markVisitorAsConverted();
            resolve();
            location.reload();
          })
          .catch((error) => {
            this.logger.error("Error submitting lead:", error);
            this.enableSubmitButton();
            this.stopButtonLoader();
            this.showError(error?.message);
            resolve();
          });
      }
    });
  }
}
