import BaseRequest from './base_request';
import GetRequest from './get_request';
import HTTPMethods from '../http_methods';
import HTTPRequest from '../http_request';
import HTTPResponse from '../http_response';
import HTTPService from '../http_service';
import UnimplementedError from '../../../behaviour/src/utilities/errors/unimplemented_error';

export default class BaseHTTPService implements HTTPService {
  protected call(args: HTTPRequest): Promise<HTTPResponse> {
    let request;

    switch (args.method) {
      case HTTPMethods.GET:
        request = new GetRequest(args);
        break;
      case HTTPMethods.POST:
      case HTTPMethods.PUT:
        request = new BaseRequest(args);
        break;
      default:
        throw new UnimplementedError(args.method);
    }

    return request.execute();
  }

  get(args: HTTPRequest): Promise<HTTPResponse> {
    args.method = HTTPMethods.GET;
    return this.call(args);
  }

  post(args: HTTPRequest): Promise<HTTPResponse> {
    args.method = HTTPMethods.POST;
    return this.call(args);
  }

  put(args: HTTPRequest): Promise<HTTPResponse> {
    args.method = HTTPMethods.PUT;
    return this.call(args);
  }
}
