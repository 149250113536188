import BaseFormConstraints from "../../manager/interaction/base/base_form_constraints";
import BasePersistentChatOptions from "./base_persistent_chat_options";
import ChatModes from "../../enum/chat_modes";
import Dealership from "../dealership";
import FormConstraints from "../../manager/interaction/form_constraints";
import PersistentChatOptions from "../persistent_chat_options";

export default class BaseDealership implements Dealership {
  private readonly _json: any;

  constructor(json: any) {
    this._json = json;
  }

  get chatMode(): ChatModes {
    return this._json?.chatMode ?? ChatModes.disabled;
  }

  get constraints(): FormConstraints {
    return new BaseFormConstraints(this._json);
  }

  get googleAnalyticsTrackingId(): string {
    return this._json?.googleAnalyticsTrackingId;
  }

  get id(): number {
    return this._json?.id;
  }

  get paused(): boolean {
    return this._json?.paused ?? false;
  }

  get persistentChatOptions(): PersistentChatOptions | undefined {
    return this._json?.persistentChat
      ? new BasePersistentChatOptions(this._json.persistentChat)
      : undefined;
  }

  get zIndex(): number {
    return this._json?.zIndex ?? 0;
  }

  get pixlId(): string {
    return this._json?.pixlId;
  }

  get m1Enabled(): boolean {
    return this._json?.m1Enabled;
  }

  get crmSubdomainName(): string {
    return this._json?.crmSubdomainName;
  }

  get blockOffersEnabled(): boolean {
    return this._json?.blockOffersEnabled;
  }

  get engagementScoreThreshold(): number {
    return this._json?.engagementScoreThreshold;
  }
}
