import BaseDatetimeService from "../../../../service/datetime/base/base_datetime_service";
import BaseStorageRegistrar from "../../service/storage/base/base_storage_registrar";
import DatetimeService from "../../../../service/datetime/datetime_service";
import DeviceTypes from "../../enum/device_types";
import Factory from "../../../../service/factory";
import Geolocation from "../../service/geolocation/geolocation";
import State from "../state";
import StorageRegistrar from "../../service/storage/storage_registrar";
import VisitorStatus from "../../enum/visitor_status";
import { isUndefined } from "../../../../service/lang";

export default class BaseState implements State {
  private _datetime: DatetimeService | undefined;
  private _storage: StorageRegistrar | undefined;

  private _customItems: object | undefined;
  private _dealershipScore: number | undefined;
  private _offerId: string | undefined;
  private _pageClass: string | undefined;
  private _pageType: string | undefined;
  private _pageViewId: string | undefined;
  private _popupShown: boolean | undefined;
  private _pullupClicked: boolean | undefined;
  private _pullupShown: boolean | undefined;
  private _scrollDepth: number | undefined;
  private _timeSpent: number | undefined;
  private _visitorScore: number | undefined;
  private _url: string | undefined;

  get botOrgPaid(): string | undefined {
    return this.storage.getCampaign();
  }

  get customItems(): object | undefined {
    return this._customItems;
  }

  get dealershipId(): string | undefined {
    return this.storage.getDealershipId();
  }

  get dealershipScore(): number {
    return this._dealershipScore ?? 0;
  }

  get deviceType(): DeviceTypes | undefined {
    return this.storage.getDeviceType();
  }

  get fingerprint(): string | null | undefined {
    return this.storage.getFingerprint();
  }

  get geolocation(): Geolocation | null | undefined {
    return this.storage.getGeolocation();
  }

  get offerId(): string | undefined {
    return this._offerId;
  }

  get pageClass(): string | undefined {
    return this._pageClass;
  }

  get pageType(): string | undefined {
    return this._pageType;
  }

  get pageViewId(): string | undefined {
    return this._pageViewId;
  }

  get pageViews(): number {
    return this.storage.getUniquePageViews() ?? 0;
  }

  get popupShown(): boolean | undefined {
    return this._popupShown;
  }

  get pullupClicked(): boolean | undefined {
    return this._pullupClicked;
  }

  get pullupShown(): boolean | undefined {
    return this._pullupShown;
  }

  get scrollDepth(): number | undefined {
    return this._scrollDepth;
  }

  get sessionId(): string | undefined {
    return this.storage.getSessionId();
  }

  get shownOffersCount(): number {
    return this.storage.getShownOffersCount() ?? 0;
  }

  get timeSpent(): number {
    return this._timeSpent ?? 0;
  }

  get visitorId(): string | undefined {
    return this.storage.getVisitorId();
  }

  get visitorScore(): number {
    return this._visitorScore ?? 0;
  }

  get visitorStatus(): VisitorStatus | undefined {
    return this.storage.getVisitorStatus();
  }

  get url(): string | undefined {
    return this._url;
  }

  protected get datetime(): DatetimeService {
    if (isUndefined(this._datetime)) {
      this._datetime = Factory.instance.build(BaseDatetimeService);
    }

    return this._datetime;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  setCustomItems(update: object): void {
    this._customItems = update;
  }

  setDealershipScore(update: number): void {
    this._dealershipScore = update;
  }

  setOfferId(update: string): void {
    this._offerId = update;
  }

  setPageClass(update: string): void {
    this._pageClass = update;
  }

  setPageType(update: string): void {
    this._pageType = update;
  }

  setPageViewId(update: string): void {
    this._pageViewId = update;
  }

  setPopupShown(update: boolean): void {
    this._popupShown = update;
  }

  setPullupClicked(update: boolean): void {
    this._pullupClicked = update;
  }

  setPullupShown(update: boolean): void {
    this._pullupShown = update;
  }

  setScrollDepth(update: number): void {
    this._scrollDepth = update;
  }

  setStorageRegistrar(update: StorageRegistrar): void {
    this._storage = update;
  }

  setTimeSpent(update: number): void {
    this._timeSpent = update;
  }

  setVisitorScore(update: number): void {
    this._visitorScore = update;
  }

  setURL(update: string): void {
    this._url = update;
  }
}
