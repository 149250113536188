import { BasePullup, PullupState } from "../../index";

export class PullupSuspended extends BasePullup implements PullupState {
  private _explain(): void {
    this.logger.warning('PullupSuspended: Pullup functionality is suspended!');
  }

  /**
   * @override
   */
  openPullup(): void {
    this._explain();
  }

  /**
   * @override
   */
  showPullup(): void {
    this._explain();
  }
}
