import { BasePullup, PullupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PullupOpened extends BasePullup implements PullupState {
  /**
   * @override
   */
  showPullup(_offer: Offer): void {
    this.logger.warning('PullupOpened: pullup already shown!');
  }
}
