import PersistentChatOptions from "../persistent_chat_options";

export default class BasePersistentChatOptions implements PersistentChatOptions {
  private readonly _json: any;

  constructor(json: any) {
    this._json = json;
  }

  get avatar(): string {
    return this._json?.avatar;
  }

  get engagementScore(): number {
    return this._json?.engagementScore;
  }

  get message(): string {
    return this._json?.message;
  }
}
