import Factory from "../../../../../service/factory";
import { isUndefined } from "../../../../../service/lang";
import Offer from "../../../model/offer";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import StateSpecification from "./state_specification";

export default class EngagementScoreReached extends StateSpecification {
  private _storage: StorageRegistrar | undefined;

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const config = this.storage.getMasterConfig();
    const blockOffersEnabled = config?.dealership?.blockOffersEnabled;
    const engagementScoreThreshold =
      config?.dealership?.engagementScoreThreshold;
    const visitorScore = this.getVisitorScore();
    if (blockOffersEnabled && engagementScoreThreshold) {
      if (visitorScore > engagementScoreThreshold) {
        isSatisfied = true;
      } else isSatisfied = false;
    } else isSatisfied = false;
    return isSatisfied;
  }
}
