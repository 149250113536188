import { BaseChat, ChatState } from "../../index";

import { ChatOptions } from "./typedef";

export class ChatAccepting extends BaseChat implements ChatState {
  /**
   * @override
   */
  accept(): void {
    this._explain();
  }

  /**
   * @override
   */
  askPermission(engagament: ChatOptions): void {
    this.logger.warning('ChatAccepting: chat set as pending!');
    this.storage.setPendingChat(engagament);
  }

  /**
   * @override
   */
  continue(): void {
    this._explain();
  }

  /**
   * @override
   */
  closeNotify(): void {
    this._explain();
  }

  /**
   * @override
   */
  deny(): void {
    this._explain();
  }

  /**
   * @override
   */
  destroy(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('ChatAccepting: chat is only accepting future interactions!');
  }

  /**
   * @override
   */
  join(_engagement: ChatOptions): Promise<void> {
    this._explain();
    throw new Error();
  }
}
