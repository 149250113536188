import { BaseChat, ChatState, ChatWaiting, DialogSuspended, OfferAccepting, PopupSuspended, PullupSuspended } from "../../index";

import { ChatOptions } from "./typedef";

export class ChatReady extends BaseChat implements ChatState {
  /**
   * @override
   */
  accept(): void {
    this._explain();
  }

  /**
   * @override
   */
  askPermission(engagement: ChatOptions): void {
    super.askPermission(engagement);
    this.switchChatState(ChatWaiting);
    this.switchDialogState(DialogSuspended);
    this.switchOfferState(OfferAccepting);
    this.switchPopupState(PopupSuspended);
    this.switchPullupState(PullupSuspended);
  }

  /**
   * @override
   */
  continue(): void {
    this._explain();
  }

  /**
   * @override
   */
  closeNotify(): void {
    this._explain();
  }

  /**
   * @override
   */
  deny(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('ChatReady: no permission asked yet!');
  }
}
