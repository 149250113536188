import { isBoolean, isEmpty, isNull, isNumber, isObject, isString, isUndefined } from "../../../../service/lang";

import AppConfig from "../../app.config";
import BaseDatetimeService from "../../../../service/datetime/base/base_datetime_service";
import BaseDealershipService from "../../../../service/dealership/base/base_dealership_service";
import BaseDeviceService from "../../service/device/base/base_device_service";
import BaseFPService from "../../service/fingerprint/base/base_fp_service";
import BaseGenerator from "../../../../service/generator/base/base_generator";
import BaseGeolocationService from "../../service/geolocation/base/base_geolocation_service";
import BaseLogger from "../../../../service/logger/base/base_logger";
import BaseState from "./base_state";
import BaseStorageRegistrar from "../../service/storage/base/base_storage_registrar";
import BaseURLService from "../../service/url/base/base_url_service";
import DatetimeService from "../../../../service/datetime/datetime_service";
import DealershipService from "../../../../service/dealership/dealership_service";
import DeviceService from "../../service/device/device_service";
import DeviceTypes from "../../enum/device_types";
import FPService from "../../service/fingerprint/fp_service";
import Factory from "../../../../service/factory";
import Generator from "../../../../service/generator/generator";
import Geolocation from "../../service/geolocation/geolocation";
import GeolocationService from "../../service/geolocation/geolocation_service";
import Logger from "../../../../service/logger/logger";
import PageClass from "../../enum/page_class";
import PageTypes from "../../enum/page_types";
import RTSResponse from "../../service/rts/rts_response";
import State from "../state";
import StateBuilder from "../state_builder";
import StorageRegistrar from "../../service/storage/storage_registrar";
import URLService from "../../service/url/url_service";
import VisitorStatus from "../../enum/visitor_status";

export default class BaseStateBuilder implements StateBuilder {
  protected state: State = new BaseState();

  private _datetime: DatetimeService | undefined;
  private _dealership: DealershipService | undefined;
  private _device: DeviceService | undefined;
  private _fingerprint: FPService | undefined;
  private _generator: Generator | undefined;
  private _geolocation: GeolocationService | undefined;
  private _logger: Logger | undefined;
  private _storage: StorageRegistrar | undefined;
  private _url: URLService | undefined;

  protected get datetime(): DatetimeService {
    if (isUndefined(this._datetime)) {
      this._datetime = Factory.instance.build(BaseDatetimeService);
    }

    return this._datetime;
  }

  protected get dealership(): DealershipService {
    if (isUndefined(this._dealership)) {
      this._dealership = Factory.instance.build(BaseDealershipService);
    }

    return this._dealership;
  }

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  protected get fingerprint(): FPService {
    if (isUndefined(this._fingerprint)) {
      this._fingerprint = Factory.instance.build(BaseFPService);
    }

    return this._fingerprint;
  }

  protected get generator(): Generator {
    if (isUndefined(this._generator)) {
      this._generator = Factory.instance.build(BaseGenerator);
    }

    return this._generator;
  }

  protected get geolocation(): GeolocationService {
    if (isUndefined(this._geolocation)) {
      this._geolocation = Factory.instance.build(BaseGeolocationService);
    }

    return this._geolocation;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  get snapshot(): State {
    return this.state;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  incrementShownOffersCount(): void {
    const shownOffersCount: number = this.storage.getShownOffersCount() ?? 0;
    const update: number = shownOffersCount + 1;
    this.storage.setShownOffersCount(update);
  }

  incrementTimeSpent(seconds: number): void {
    this.state.setTimeSpent(this.state.timeSpent + seconds);
  }

  incrementUniquePageViews(): void {
    const current = this.storage.getUniquePageViews() ?? 0;
    const update = current + 1;
    this.storage.setUniquePageViews(update);
  }

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      this.setDealershipId();
      this.setDeviceType();
      this.setScrollDepth();
      this.setTimeSpent();
      this.setURL();
      Promise.all([
        this.setFingerprint(),
        this.setGeolocation()
      ])
        .then(() => resolve())
        .catch(error => {
          this.logger.error(error);
          resolve();
        });
    });
  }

  reset(): void {
    this.state = new BaseState();
  }

  setBotOrgPaid(update?: string): void {
    if (isString(update)) {
      this.storage.setCampaign(update);
    }
  }

  setCustomItems(update?: any): void {
    if (isObject(update) && !isEmpty(update)) {
      this.state.setCustomItems(update);
    }
  }

  hasCustomItems() {
    return isObject(this.state.customItems) && Object.keys(this.state.customItems).length > 0;
  }

  setDealershipId(update?: string): void {
    if (isString(update) && !isEmpty(update)) {
      this.storage.setDealershipId(update);
    } else if (!this.storage.hasDealershipId()) {
      const dealershipId = this.dealership.findDealershipId();
      this.storage.setDealershipId(dealershipId);
    }
  }

  setDealershipScore(update?: number): void {
    const score: number = isNumber(update) ? update : 0;
    this.state.setDealershipScore(score);
  }

  setDealershipService(update: DealershipService): void {
    this._dealership = update;
  }

  setDeviceService(update: DeviceService): void {
    this._device = update;
  }

  setDeviceType(update?: DeviceTypes): void {
    if (isString(update)) {
      this.storage.setDeviceType(update);
    } else if (!this.storage.hasDeviceType()) {
      const deviceType: DeviceTypes = this.device.getDeviceType();
      this.storage.setDeviceType(deviceType);
    }
  }

  setFingerprint(update?: string): Promise<void> {
    return new Promise((resolve) => {
      const exit = (value: string | null) => {
        this.storage.setFingerprint(value);
        resolve();
      };

      if (isString(update)) {
        exit(update);
      } else {
        const current = this.storage.getFingerprint();

        if (isString(current) || isNull(current)) {
          exit(current);
        } else {
          this.fingerprint.generateFingerprint()
            .then(result => {
              this.logger.info('Generated fingerprint:', result);
              exit(result);
            })
            .catch(error => {
              this.logger.error('Error genereting fingerprint:', error);
              exit(null);
            });
        }
      }
    });
  }

  setFingerprintService(update: FPService): void {
    this._fingerprint = update;
  }

  setFromJSON(update?: any): void {
    const data = update as RTSResponse;
    this.incrementUniquePageViews();
    this.setBotOrgPaid(data?.botOrgPaid);
    this.setDealershipScore(data?.avgScore);
    this.setPageClass(data?.pageClass);
    this.setPageType(data?.pageType);
    this.setPageViewId(data?.pageViewID);
    this.setVisitorId(data?.visitorID);
    this.setVisitorScore(data?.eScore);
    this.setCustomItems(data?.extras);
    this.setVisitorStatus(data?.status as VisitorStatus);
  }

  setGeolocation(update?: Geolocation): Promise<void> {
    return new Promise((resolve) => {
      const exit = (result: Geolocation | null): void => {
        this.storage.setGeolocation(result);
        resolve();
      };

      if (isUndefined(update)) {
        const current = this.storage.getGeolocation();

        if (isUndefined(current)) {
          this.geolocation.getGeolocation()
            .then(result => {
              this.logger.info('Fetched geolocation:', result);
              exit(result);
            })
            .catch(error => {
              /**
               * If the Geolocation service fails for some reason, e.g. the 
               * browser is blocking the request, we still need to continue
               * so we set an empty geolocation.
               */
              this.logger.error('Error getting geolocation:', error);
              exit(null);
            });
        } else {
          exit(current);
        }
      } else {
        exit(update);
      }
    });
  }

  setGeolocationService(update: GeolocationService): void {
    this._geolocation = update;
  }

  setOfferId(update?: string): void {
    if (isString(update)) {
      this.state.setOfferId(update);
    }
  }

  setPageClass(update?: string): void {
    const pageClass = isString(update) ? update : PageClass.other;
    this.state.setPageClass(pageClass);
  }

  setPageType(update?: string): void {
    const pageType = isString(update) ? update : PageTypes.other;
    this.state.setPageType(pageType);
  }

  setPageViewId(update?: string): void {
    if (isString(update)) {
      this.state.setPageViewId(update);
    }
  }

  setPopupShown(update?: boolean): void {
    if (isBoolean(update)) {
      this.state.setPopupShown(update);
    }
  }

  setPullupClicked(update?: boolean): void {
    if (isBoolean(update)) {
      this.state.setPullupClicked(update);
    }
  }

  setPullupShown(update?: boolean): void {
    if (isBoolean(update)) {
      this.state.setPullupShown(update);
    }
  }

  setScrollDepth(update?: number): void {
    const scrollDepth: number = update ?? 0;
    this.state.setScrollDepth(scrollDepth);
  }

  setSessionId(update?: string): void {
    const exit = (value: string) => this.storage.setSessionId(value);

    if (isString(update)) {
      exit(update);
    } else {
      const current = this.storage.getSessionId();
      const value = isString(current) ? current : this.generator.id(AppConfig.instance.idsLength);
      exit(value);
    }
  }

  setStorage(update: StorageRegistrar): void {
    this._storage = update;
  }

  setTimeSpent(update?: number): void {
    this.state.setTimeSpent(update ?? 0);
  }

  setVisitorId(update?: string): void {
    if (isString(update)) {
      this.storage.setVisitorId(update);
    }
  }

  setVisitorScore(update?: number): void {
    const score: number = isNumber(update) ? update : 0;
    this.state.setVisitorScore(score);
  }

  setVisitorStatus(update?: VisitorStatus): void {
    const exit = (value: VisitorStatus): void => this.storage.setVisitorStatus(value);

    if (isUndefined(update)) {
      const current = this.storage.getVisitorStatus();

      if (isUndefined(current)) {
        exit(VisitorStatus.returning);
      }
    } else {
      const status = VisitorStatus.isValid(update) ? update : VisitorStatus.returning;
      exit(status);
    }
  }

  setURL(update?: string): void {
    if (isString(update)) {
      this.state.setURL(update);
    } else {
      const current = this.url.getCurrentURL();

      if (isString(current)) {
        this.state.setURL(current);
      }
    }
  }

  setURLService(update: URLService): void {
    this._url = update;
  }
}
