import { BaseSpecification } from "./index";
import Specification from "./specification";

export class NotSpecification extends BaseSpecification {
  private _condition: Specification;

  constructor(condition: Specification) {
    super();
    this._condition = condition;
  }

  /**
   * @override
   */
  isSatisfiedBy(candidate: any): boolean {
    const not = this._condition.isSatisfiedBy(candidate) !== true;

    return not;
  }
}
