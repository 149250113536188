import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class YearIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.ignoreYears ? [] : source?.includeYears; //source?.ignoreYears ? [] :
  }
}
