import Interactions from "../../manager/interaction/interactions";

export const ModalHTML = `
<div id="${Interactions.ModalKeys.container}"
  style="
    display: none;
    position: fixed;
    z-index: ${Interactions.ModalPlaceholders.zIndex};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    text-align: center;
    opacity: 1;">
  <div
    style="
      border-radius: 40px;
      top: 50%;
      background-color: rgb(254, 254, 254);
      margin: auto;
      transform: translateY(-50%);
      position: relative;
      border: 1px solid rgb(136, 136, 136);
      display: inline-block;">
    ${Interactions.ModalPlaceholders.content}
  </div>
</div>
`;
