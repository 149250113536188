import CRMValidator from "./crm_validator";
import DisclaimerValidator from "./disclaimer_validator";
import EmailValidator from "./email_validator";
import Factory from "../../../../service/factory";
import LengthValidator from "./length_validator";
import PhoneValidator from "./phone_validator";
import RequiredValidator from "./required_validator";
import { isUndefined } from "../../../../service/lang";

export default class Validation {
  private static _crm: CRMValidator | undefined;
  private static _disclaimer: DisclaimerValidator | undefined;
  private static _email: EmailValidator | undefined;
  private static _phone: PhoneValidator | undefined;
  private static _required: RequiredValidator | undefined;

  static get crm(): CRMValidator {
    if (isUndefined(this._crm)) {
      this._crm = Factory.instance.build(CRMValidator);
    }

    return this._crm;
  }

  static get disclaimer(): DisclaimerValidator {
    if (isUndefined(this._disclaimer)) {
      this._disclaimer = Factory.instance.build(DisclaimerValidator);
    }

    return this._disclaimer;
  }

  static get email(): EmailValidator {
    if (isUndefined(this._email)) {
      this._email = Factory.instance.build(EmailValidator);
    }

    return this._email;
  }

  static get phone(): PhoneValidator {
    if (isUndefined(this._phone)) {
      this._phone = Factory.instance.build(PhoneValidator);
    }

    return this._phone;
  }

  static get required(): RequiredValidator {
    if (isUndefined(this._required)) {
      this._required = Factory.instance.build(RequiredValidator);
    }

    return this._required;
  }

  static size(length: number): LengthValidator {
    return new LengthValidator(length);
  }
}
