import Offer from "../../../../model/offer";
import URLSpecification from "../url_specification";

export default class SegmentSpecification extends URLSpecification {
  getOriginlessHref(): string | undefined {
    return this.url.getOriginlessHref();
  }

  protected getSegmentsData(_source: Offer): string[] {
    throw new Error('Please implement in a subclass!');
  }
}