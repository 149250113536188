import { BaseInteractionMachine, PopupState, PopupTest, PullupState, PullupTest } from "../index";

import InteractionStateFactory from "../states/interaction_state_factory";

export class MachineTest extends BaseInteractionMachine {
  /**
   * @override
   */
  get popup(): PopupState {
    return InteractionStateFactory.build(PopupTest, this.context, this) as PopupState;
  }

  /**
   * @override
   */
  get pullup(): PullupState {
    return InteractionStateFactory.build(PullupTest, this.context, this) as PullupState;
  }
}
