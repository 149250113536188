import { isNumber, isUndefined, throwError } from "../../../../service/lang";

import AppConfig from "../../app.config";
import BaseLogger from "../../../../service/logger/base/base_logger";
import BaseNotifier from "../../service/notifier/base/base_notifier";
import BaseStorageRegistrar from "../../service/storage/base/base_storage_registrar";
import { ChatTypes } from "../../enum/chat_types";
import Factory from "../../../../service/factory";
import HTTPStatus from "../../../../service/http/http_status";
import Logger from "../../../../service/logger/logger";
import { NetworkEvent } from "../../../../service/http/network_event";
import { NetworkListener } from "../../../../service/http/network_listener";
import { NetworkStatus } from "../../../../service/http/network_status";
import Notifier from "../../service/notifier/notifier";
import StorageRegistrar from "../../service/storage/storage_registrar";

export class ChatNetworkListener implements NetworkListener {
  private _logger: Logger | undefined;
  private _notifier: Notifier | undefined;
  private _storage: StorageRegistrar | undefined;

  protected get hasError(): boolean {
    return this.storage.hasChatNetworkError();
  }

  protected get isChatSession(): boolean {
    return this.storage.hasChatSession();
  }

  protected get isPersistentChat(): boolean {
    return this.storage.getChatSession()?.type === ChatTypes.persistent;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get notifier(): Notifier {
    if (isUndefined(this._notifier)) {
      this._notifier = Factory.instance.build(BaseNotifier);
    }

    return this._notifier;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  handle(event: NetworkEvent): void {
    const isPayloadAPI = event.url === AppConfig.instance.urlRTSPayload;

    if (isPayloadAPI) {
      switch (event.status) {
        case NetworkStatus.done:
          this._handleDone(event);
          break;
        case NetworkStatus.error:
          this._handleError(event);
          break;
        default:
          throwError(`${event.status} not implemented: ${event}`);
      }
    }
  }

  private _handleDone(event: NetworkEvent): void {
    const isSuccessful = event.code === HTTPStatus.Accepted;

    if (isSuccessful && this.isChatSession && this.isPersistentChat && this.hasError) {
      const options = this.storage.getChatSession();

      if (isNumber(options.historyId)) {
        this.logger.warning('Paylod API OK: notifying!');
        this.notifier.notifyPersistentChatActivity(options.historyId);
        this.storage.clearPersistentChatNetworkError();
      }
    }
  }

  private _handleError(_event: NetworkEvent): void {
    if (this.isChatSession && this.isPersistentChat && !this.hasError) {
      this.logger.warning('Payload API error during chat session, setting pending notify!');
      this.storage.setPersistentChatNetworkError();
    }
  }
}