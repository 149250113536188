import { BaseChat, ChatReady, ChatRunning, ChatState } from "../../index";

import { ChatOptions } from "./typedef";
import EngagementCodes from "../../../../../enum/engagement_codes";

export class ChatWaiting extends BaseChat implements ChatState {
  protected get acceptCode(): EngagementCodes {
    return this.isChatbot ? EngagementCodes.AIChatAccepted : EngagementCodes.chatAccepted;
  }

  protected get engagementKey(): string {
    return this.chatEngagement?.engagementKey ?? '';
  }

  protected get engagementOwner(): string {
    return this.chatEngagement?.user?.id ?? '';
  }

  protected get historyId(): number | undefined {
    return this.chatEngagement?.historyId;
  }

  protected get ignoreCode(): EngagementCodes {
    return this.isChatbot ? EngagementCodes.AIChatIgnored : EngagementCodes.chatIgnored;
  }

  protected get isChatbot(): boolean {
    return !!this.chatEngagement?.chatbot;
  }

  /**
   * @override
   */
  accept(): void {
    super.accept();
    this.notifyAccepted();
    this.saveChatEngagement();
    this.switchChatState(ChatRunning);
  }

  /**
   * @override
   */
  askPermission(_engagement: ChatOptions): void {
    this._explain();
  }

  /**
   * @override
   */
  continue(_engagement: ChatOptions): void {
    this._explain();
  }

  /**
   * @override
   */
  closeNotify(): void {
    this._explain();
  }

  /**
   * @override
   */
  deny(): void {
    super.deny();
    this.notifyDenied();
    this.clearChatEngagement();
    this.switchChatState(ChatReady);
  }

  /**
   * @override
   */
  destroy(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('ChatWaiting: chat is awaiting response!');
  }

  /**
   * @override
   */
  join(_engagement: ChatOptions): Promise<void> {
    this._explain();
    throw new Error();
  }

  protected notifyAccepted(): void {
    if (this.hasChatEngagement) {
      if (this.isChatbot) {
        this.notifier.notifyEngagement({
          engagement: this.acceptCode,
          engagementKey: this.engagementKey
        });
      } else {
        this.notifier.notifyEngagement({
          engagement: this.acceptCode,
          engagementKey: this.engagementKey,
          engagementOwner: this.engagementOwner
        });
      }

      this.notifier.notifyEngagementChatAccepted({
        id: this.historyId,
        isBot: this.isChatbot,
      });
    }
  }

  protected notifyDenied(): void {
    if (this.hasChatEngagement) {
      this.notifier.notifyDeniedChat(this.historyId);
      this.notifier.notifyEngagement({
        engagement: this.ignoreCode,
        engagementKey: this.engagementKey
      });
    }
  }
}
