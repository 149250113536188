import DeviceTypes from "../enum/device_types";
import EngagementTypes from "../enum/engagement_types";
import OfferTypes from "../enum/offer_types";
import UnimplementedError from "../utilities/errors/unimplemented_error";
import VisitorStatus from "../enum/visitor_status";

export default class PureinfluencerAdapter {
  static deviceType(source: any): DeviceTypes[] {
    let adapted: DeviceTypes[];

    if (source === PIDeviceTypes.desktop) {
      adapted = [DeviceTypes.desktop];
    } else if (source === PIDeviceTypes.mobile) {
      adapted = [DeviceTypes.mobile];
    } else {
      adapted = [DeviceTypes.desktop, DeviceTypes.mobile];
    }

    return adapted;
  }

  static engagementType(source: any): EngagementTypes | undefined {
    let adapted: EngagementTypes;

    if (source === PIEngagementTypes.chat) {
      adapted = EngagementTypes.chat;
    } else if (source === PIEngagementTypes.offer) {
      adapted = EngagementTypes.offer;
    } else {
      throw new UnimplementedError(source);
    }

    return adapted;
  }

  static offerType(source: any): OfferTypes {
    let adapted: OfferTypes;

    if (source === PIOfferTypes.new) {
      adapted = OfferTypes.new;
    } else if (source === PIOfferTypes.service) {
      adapted = OfferTypes.service;
    } else if (source === PIOfferTypes.used) {
      adapted = OfferTypes.used;
    } else {
      adapted = OfferTypes.general;
    }

    return adapted;
  }

  static visitorStatus(source: any): VisitorStatus[] {
    let adapted: VisitorStatus[];

    if (source === PIVisitorStatus.new) {
      adapted = [VisitorStatus.new];
    } else if (source === PIVisitorStatus.returning) {
      adapted = [VisitorStatus.returning];
    } else {
      adapted = [VisitorStatus.new, VisitorStatus.returning];
    }

    return adapted;
  }
}

export class PIDeviceTypes {
  static readonly all = 'ALL';
  static readonly desktop = 'DESKTOP';
  static readonly mobile = 'MOBILE';
}

export class PIEngagementTypes {
  static readonly chat = 'ENGAGEMENT_CHAT';
  static readonly offer = 'ENGAGEMENT_OFFER';
  static readonly persistentChat = 'PERSISTENT_CHAT';
}

export class PIOfferTypes {
  static readonly general: string = 'GENERAL';
  static readonly new: string = 'NEW';
  static readonly service: string = 'SERVICE';
  static readonly used: string = 'USED';
}

export class PIVisitorStatus {
  static readonly all = 'ALL';
  static readonly new = 'NEW';
  static readonly returning = 'RETURNING';
}
