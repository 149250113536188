import Events from "../../../behaviour/src/enum/events";
import Observable from "../observable";
import Observer from "../observer";
import { isNumber } from "../../lang";

export default class BaseObservable implements Observable {
  private _subscriptions: Map<Events, Observer[]> = new Map();

  notify(event: Events, data?: any): void {
    const observers = this._subscriptions.get(event);
    observers?.forEach((s) => s.update(this, event, data));
  }

  subscribe(observer: Observer, events: Events[]): void {
    events.forEach((event) => {
      if (!this._isInitialized(event)) {
        this._subscriptions.set(event, []);
      }

      this._subscriptions.get(event)?.push(observer);
    });
  }

  unsubscribe(observer: Observer, events: Events[]): void {
    events.forEach((event) => {
      if (this._isInitialized(event)) {
        const index = this._subscriptions.get(event)?.indexOf(observer);

        if (isNumber(index)) {
          this._subscriptions.get(event)?.splice(index, 1);
        }
      }
    });
  }

  private _isInitialized(event: Events): boolean {
    return this._subscriptions.has(event);
  }
}