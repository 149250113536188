import { BaseChat, ChatState } from "../../index";

import { ChatOptions } from "./typedef";

export class ChatRunning extends BaseChat implements ChatState {
  /**
   * @override
   */
  accept(): void {
    this._explain();
  }

  /**
   * @override
   */
  askPermission(): void {
    this._explain();
  }

  /**
   * @override
   */
  continue(_engagement: ChatOptions): void {
    this._explain();
  }

  /**
   * @override
   */
  closeNotify(): void {
    super.closeNotify();
  }

  /**
   * @override
   */
  deny(): void {
    this._explain();
  }

  /**
   * @override
   */
  destroy(): void {
    super.destroy();
  }

  private _explain(): void {
    this.logger.warning('ChatRunning: a chat is running!');
  }

  /**
   * @override
   */
  join(_engagement: ChatOptions): Promise<void> {
    this._explain();
    throw new Error();
  }
}
