import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";

export default class DynamicScoreReached extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const useEngagementScore = candidate?.useEngagementScore;

    if (useEngagementScore) {
      const useStaticThreshold = candidate?.hasStaticEngagementThreshold;

      if (useStaticThreshold) {
        isSatisfied = false;
      } else {
        const visitorScore = this.getVisitorScore();

        if (visitorScore === 0) {
          isSatisfied = true;
        } else {
          const dealershipScore = this.getDealershipScore();
          isSatisfied = visitorScore >= dealershipScore;
        }
      }
    } else {
      isSatisfied = false;
    }

    return isSatisfied;
  }
}