import { BasePopup, PopupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PopupShown extends BasePopup implements PopupState {
  /**
   * @override
   */
  showPopup(_offer: Offer): void {
    this.logger.warning('PopupShown: popup already shown!');
  }
}
