import { isNumber, isString, isUndefined } from "../../../../../../../../service/lang";

import AppConfig from "../../../../../../app.config";
import BackendService from "../../../../../../service/backend/backend_service";
import BaseBackendService from "../../../../../../service/backend/base/base_backend_service";
import BaseChatWidgetConfigBuilder from "./base/base_chat_widget_config_builder";
import BaseDOMService from "../../../../../../service/dom/base/base_dom_service";
import BaseInteractionManager from "../../../../base/base_interaction_manager";
import BaseLogger from "../../../../../../../../service/logger/base/base_logger";
import BaseNotifier from "../../../../../../service/notifier/base/base_notifier";
import BaseStateBuilder from "../../../../../../state/base/base_state_builder";
import BaseStorageRegistrar from "../../../../../../service/storage/base/base_storage_registrar";
import { ChatOptions } from "../typedef";
import { ChatTypes } from "../../../../../../enum/chat_types";
import { ChatWidgetConfig } from "./typedef";
import ChatWidgetConfigBuilder from "./chat_widget_config_builder";
import DOMService from "../../../../../../service/dom/dom_service";
import Events from "../../../../../../enum/events";
import Factory from "../../../../../../../../service/factory";
import InteractionManager from "../../../../interaction_manager";
import Logger from "../../../../../../../../service/logger/logger";
import Notifier from "../../../../../../service/notifier/notifier";
import State from "../../../../../../state/state";
import StateBuilder from "../../../../../../state/state_builder";
import StorageRegistrar from "../../../../../../service/storage/storage_registrar";
import { triggerEvent } from "../../../../../../../../service/dom/dom_utilities";

export default class ChatWidgetConfigComposer {
  private _backend: BackendService | undefined;
  private _configBuilder: ChatWidgetConfigBuilder | undefined;
  private _dom: DOMService | undefined;
  private _interaction: InteractionManager | undefined;
  private _logger: Logger | undefined;
  private _notifier: Notifier | undefined;
  private _stateBuilder: StateBuilder | undefined;
  private _storage: StorageRegistrar | undefined;

  protected get backend(): BackendService {
    if (isUndefined(this._backend)) {
      this._backend = Factory.instance.build(BaseBackendService);
    }

    return this._backend;
  }

  protected get configBuilder(): ChatWidgetConfigBuilder {
    if (isUndefined(this._configBuilder)) {
      this._configBuilder = Factory.instance.build(BaseChatWidgetConfigBuilder);
    }

    return this._configBuilder;
  }

  protected get dom(): DOMService {
    if (isUndefined(this._dom)) {
      this._dom = Factory.instance.build(BaseDOMService);
    }

    return this._dom;
  }

  protected get interaction(): InteractionManager {
    if (isUndefined(this._interaction)) {
      this._interaction = Factory.instance.build(BaseInteractionManager);
    }

    return this._interaction;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get notifier(): Notifier {
    if (isUndefined(this._notifier)) {
      this._notifier = Factory.instance.build(BaseNotifier);
    }

    return this._notifier;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  compose(engagement: ChatOptions): ChatWidgetConfig {
    this.configBuilder.reset();
    this.configBuilder.setAppId(engagement.apiKey);
    this.configBuilder.setDefaultAvatar(this.getDefaultAvatar(engagement));
    this.configBuilder.setDefaultNickname(this.getDefaultNickname(engagement));
    this.configBuilder.setDefaultUserTitle(this.getDefaultUserTitle(engagement));
    this.configBuilder.setMessageDataGetter(this.messageDataGetter.bind(this));
    this.configBuilder.setOnChatEnded(this.onChatEnded.bind(this));
    this.configBuilder.setOnChoicesNoResponse(this.onChoicesNoResponse.bind(this));
    this.configBuilder.setOnMessageSent(this.onMessageSent.bind(this));
    this.configBuilder.setTimeoutChoicesNoResponse(AppConfig.instance.chatTimeoutNoResponse.toMilliseconds());

    if (isString(engagement.visitorId)) {
      this.configBuilder.setUserId(engagement.visitorId);
    }

    if (isString(this.state.visitorId)) {
      this.configBuilder.setUserName(this.state.visitorId);
    }

    return this.configBuilder.build();
  }

  protected getDefaultAvatar(engagement: ChatOptions): string {
    return engagement?.user?.avatar ?? engagement?.chatbot?.avatar ?? AppConfig.instance.chatAvatarURL;
  }

  protected getDefaultNickname(engagement: ChatOptions): string {
    return engagement?.user?.nickname ?? engagement?.chatbot?.nickname ?? AppConfig.instance.chatDefaultNickname;
  }

  protected getDefaultUserTitle(engagement: ChatOptions): string {
    let userTitle;

    if (engagement.user) {
      userTitle = '';
    } else if (engagement.chatbot) {
      userTitle = AppConfig.instance.chatbotTitle;
    } else {
      userTitle = AppConfig.instance.chatDefaultUserTitle;
    }

    return userTitle
  }

  protected messageDataGetter(): string {
    const engagement = this.storage.getChatSession();

    return JSON.stringify({
      botOrgPaid: this.state.botOrgPaid,
      channelId: engagement.channelId,
      chatHistoryId: engagement.historyId,
      chatType: engagement.type,
      engagementScore: this.state.visitorScore,
      make: this.state.customItems?.make,
      model: this.state.customItems?.model,
      notificationId: engagement.notificationId,
      pageType: this.state.pageClass,
      sessionTime: this.state.timeSpent,
      url: this.state.url,
      visitorSession: this.state.sessionId,
      year: this.state.customItems?.year
    });
  }

  protected onChatEnded(result: any, error: any): void {
    if (result) {
      this.logger.info('Chat ended:', result);
    }

    if (error) {
      this.logger.error(error);
    }

    const engagement = this.storage.getChatSession();

    if (isNumber(engagement?.historyId)) {
      switch (engagement.type) {
        case ChatTypes.engagement:
          this.backend.notifyChatEnded(engagement.historyId);
          break;
        case ChatTypes.persistent:
          this.backend.notifyPersistentChatEnded(engagement.historyId);
          break;
        default:
          this.logger.error(`Chat type: ${engagement.type} is not implemented!`);
          break;
      }
    } else {
      this._logInvalidHistoryId(engagement);
    }

    triggerEvent(Events.chatClosed);
  }

  protected onChoicesNoResponse(): void {
    const engagement = this.storage.getChatSession();

    this.backend.notifyChatbotTimeout({
      channelUrl: engagement.channelId,
      chatHistoryId: engagement.historyId ?? null,
      dealershipExtRefId: this.state.dealershipId,
      visitorEexRefId: this.state.visitorId,
    });
  }

  protected onMessageSent(message: any, error: Error): void {
    if (message) {
      this.logger.info('Message sent:', message);
    }

    if (error) {
      this.logger.error(error);
    }
  }

  private _logInvalidHistoryId(engagement: ChatOptions): void {
    this.logger.warning('Invalid chat history ID in engagement:', engagement);
  }
}
