enum VisitorStatus {
  new = 'new',
  returning = 'returning'
}

namespace VisitorStatus {
  export function isAll(source: VisitorStatus[]): boolean {
    return source.includes(VisitorStatus.new) && source.includes(VisitorStatus.returning);
  }

  export function isValid(source: any): boolean {
    return [VisitorStatus.new, VisitorStatus.returning].includes(source);
  }
}

export default VisitorStatus;
