import Interactions from "../interactions";
import PullupBuilder from "../pullup_builder";
import { PullupHTML } from "../../../asset/html/pullup";

export default class BasePullupBuilder implements PullupBuilder {
  private _source = PullupHTML;

  build(): string {
    return this._source;
  }

  reset(): void {
    this._source = PullupHTML;
  }

  setOfferId(update: string): void {
    this._source = this._source.replace(Interactions.PullupPlaceholders.offerId, update);
  }

  setTemplate(update: string): void {
    this._source = this._source.replace(Interactions.PullupPlaceholders.template, update);
  }
}
