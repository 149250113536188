import { isNull, isNumber, isObject, isString, isUndefined } from "../../lang";

import BaseDatetimeService from "../../datetime/base/base_datetime_service";
import DatetimeService from "../../datetime/datetime_service";
import Factory from "../../factory";
import StorageItem from "../storage_item";
import StorageKeys from "../storage_keys";
import StorageService from "../storage_service";

export default class BaseStorageService implements StorageService {
  private _datetime: DatetimeService | undefined;
  private _storage: Storage | undefined;

  protected get datetime(): DatetimeService {
    if (isUndefined(this._datetime)) {
      this._datetime = Factory.instance.build(BaseDatetimeService);
    }

    return this._datetime;
  }

  get isEnabled(): boolean {
    let enabled: boolean;

    try {
      const hasTestKey = (): boolean =>
        this.getKeyData(StorageKeys.test) === true;
      const setTestKey = (): void =>
        this.setKey(StorageKeys.test, { data: true });

      if (hasTestKey()) {
        enabled = true;
      } else {
        setTestKey();
        enabled = hasTestKey();
      }
    } catch (error) {
      enabled = false;
    }

    return enabled;
  }

  protected get storage(): Storage {
    if (isUndefined(this._storage)) {
      this._storage = window.localStorage;
    }

    return this._storage;
  }

  deleteKey(key: string): void {
    this.storage.removeItem(key);
  }

  extendExpiry(key: string, expiry: number): void {
    const data: any = this.getKeyData(key);

    if (!isUndefined(data) && isNumber(expiry)) {
      this.setKey(key, { data: data, expiry: expiry });
    }
  }

  getKey(key: string): StorageItem | undefined {
    let storageItem: StorageItem | undefined;
    const source: string | null = this.storage.getItem(key);

    if (isString(source)) {
      const parsed: any = JSON.parse(source);

      if (isObject(parsed)) {
        const expiry: any = parsed?.expiry;

        if (isNumber(expiry)) {
          const isExpired = this.datetime.timestamp > expiry;

          if (isExpired) {
            this.deleteKey(key);
          } else {
            storageItem = parsed;
          }
        } else {
          storageItem = parsed;
        }
      }
    }

    return storageItem;
  }

  getKeyData(key: string): any {
    return this.getKey(key)?.data;
  }

  getKeyDataLegacy(key: string): any {
    return (this.getKey(key) as any)?.value;
  }

  hasKey(key: string): boolean {
    return !isNull(this.storage.getItem(key));
  }

  setDatetimeService(update: DatetimeService): void {
    this._datetime = update;
  }

  setKey(key: string, item: StorageItem): void {
    this.storage.setItem(key, JSON.stringify(item));
  }

  setStorage(update: Storage): void {
    this._storage = update;
  }
}
