import AppConfig from "../../../app.config";
import BaseGeolocation from "./base_geolocation";
import BaseHTTPService from "../../../../../service/http/base/base_http_service";
import Factory from "../../../../../service/factory";
import Geolocation from "../geolocation";
import GeolocationService from "../geolocation_service";
import HTTPService from "../../../../../service/http/http_service";
import { isUndefined } from "../../../../../service/lang";

export default class BaseGeolocationService implements GeolocationService {
  private _http: HTTPService | undefined;

  protected get http(): HTTPService {
    if (isUndefined(this._http)) {
      this._http = Factory.instance.build(BaseHTTPService);
    }

    return this._http;
  }

  getGeolocation(): Promise<Geolocation> {
    return new Promise<Geolocation>((resolve, reject) => {
      this.http
        .get({ url: AppConfig.instance.urlGeolocation })
        .then(response => {
          if (isUndefined(response?.data)) {
            reject('Cannot fetch Geolocation!');
          } else {
            resolve(new BaseGeolocation(response.data));
          }
        })
        .catch(error => reject(error));
    });
  }
}
