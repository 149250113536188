import EngagementCodes from "../../../enum/engagement_codes";
import RTSDataBuilder from "../rts_data_builder";
import RTSDataKeys from "../rts_data_keys";
import RTSMetadata from "../rts_metadata";
import State from "../../../state/state";

export default class BaseRTSDataBuilder implements RTSDataBuilder {
  protected _data: any;

  build() {
    return this._data;
  }

  reset(): void {
    this._data = {};
  }

  setBlocked(update: boolean): void {
    this._data[RTSDataKeys.isBlocked] = update;
  }

  setBotOrgPaid(update: string | null): void {
    this._data[RTSDataKeys.botOrgPaid] = update;
  }

  setEngagement(update: EngagementCodes): void {
    this._data[RTSDataKeys.engagement] = update;
  }

  setEngagementAcknowledgement(): void {
    this._data[RTSDataKeys.engagementAcknowledgment] = true;
  }

  setEngagementKey(update: string): void {
    this._data[RTSDataKeys.engagementKey] = update;
  }

  setEngagementOwner(update: string): void {
    this._data[RTSDataKeys.engagementOwner] = update;
  }

  setMetadata(update: RTSMetadata): void {
    this._data[RTSDataKeys.metadata] = update;
  }

  setState(update: State): void {
    this._data[RTSDataKeys.botOrgPaid] = update.botOrgPaid ?? null;
    this._data[RTSDataKeys.dealershipId] = update.dealershipId ?? '';
    this._data[RTSDataKeys.deviceType] = update.deviceType?.toString() ?? '';
    this._data[RTSDataKeys.make] = update.customItems?.make;
    this._data[RTSDataKeys.model] = update.customItems?.model;
    this._data[RTSDataKeys.trim] = update.customItems?.trim;
    this._data[RTSDataKeys.type] = update.customItems?.type;
    this._data[RTSDataKeys.offerId] = update.offerId;
    this._data[RTSDataKeys.pageClass] = update.pageClass?.toString() ?? '';
    this._data[RTSDataKeys.pageViewId] = update.pageViewId ?? '';
    this._data[RTSDataKeys.popupShown] = update.popupShown ?? false;
    this._data[RTSDataKeys.pullupClicked] = update.pullupClicked ?? false;
    this._data[RTSDataKeys.pullupShown] = update.pullupShown ?? false;
    this._data[RTSDataKeys.scrollDepth] = update.scrollDepth ?? 0;
    this._data[RTSDataKeys.sessionId] = update.sessionId ?? '';
    this._data[RTSDataKeys.timeSpent] = update.timeSpent ?? 0;
    this._data[RTSDataKeys.visitorId] = update.visitorId ?? '';
    this._data[RTSDataKeys.url] = update.url ?? '';
    this._data[RTSDataKeys.year] = update.customItems?.year;
    this._data[RTSDataKeys.zip] = update.geolocation?.zip ?? '';
  }
}
