import { BaseDialog, DialogState } from "../../index";

import LeadTypes from "../../../../../enum/lead_types";

export class DialogTest extends BaseDialog implements DialogState {
  /**
   * @override
   */
  get leadType(): LeadTypes {
    return LeadTypes.popup;
  }

  /**
   * @override
   */
  protected notifyFailure(): void { }

  /**
   * @override
   */
  protected notifyGoogleAnalytics(): void { }

  /**
   * @override
   */
  protected notifySuccess(): void { }
}
