import BaseChatWidget from "./base/base_chat_widget";
import { ChatOptions } from "../typedef";
import ChatWidget from "./chat_widget";
import { ChatWidgetConfig } from "./typedef";
import ChatWidgetConfigComposer from "./chat_widget_config_composer";
import Factory from "../../../../../../../../service/factory";
import { isUndefined } from "../../../../../../../../service/lang";

export default class ChatWidgetComposer {
  private _configComposer: ChatWidgetConfigComposer | undefined;

  protected get configComposer(): ChatWidgetConfigComposer {
    if (isUndefined(this._configComposer)) {
      this._configComposer = Factory.instance.build(ChatWidgetConfigComposer);
    }

    return this._configComposer;
  }

  compose(engagement: ChatOptions): ChatWidget {
    const config: ChatWidgetConfig = this.configComposer.compose(engagement);
    const widget: ChatWidget = Factory.instance.build(BaseChatWidget, false);
    widget.setConfig(config);

    return widget;
  }
}
