import BaseLibrary from "../../library/base/base_library";
import FPService from "../fp_service";
import Factory from "../../../../../service/factory";
import Library from "../../library/library";
import { isUndefined } from "../../../../../service/lang";

declare let PIRTSFPJS: any;

export default class BaseFPService implements FPService {
  private _library: Library | undefined;

  protected get library(): Library {
    if (isUndefined(this._library)) {
      this._library = Factory.instance.build(BaseLibrary);
    }

    return this._library;
  }

  generateFingerprint(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.library.loadFingerprint()
        .then((_value: any) => {
          PIRTSFPJS.load()
            .then((fingerprint: any) => fingerprint.get())
            .then((result: any) => resolve(result.visitorId))
            .catch((error: any) => reject(error));
        })
        .catch(error => reject(error));
    });
  }
}
