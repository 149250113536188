export default class DialogStateError extends Error {
  private constructor(message: string) {
    super(message);
  }

  private static _build(message: string): DialogStateError {
    return new DialogStateError(message);
  }

  static undefinedFailureCallback(): DialogStateError {
    return this._build('Undefined failure callback!');
  }

  static undefinedLeadType(): DialogStateError {
    return this._build('Undefined lead type!');
  }

  static undefinedSuccessCallback(): DialogStateError {
    return this._build('Undefined success callback!');
  }
}
