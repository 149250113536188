import { ChatOptions } from "./states/chat/typedef";
import { InteractionMachine } from "./index";
import InteractionManager from "../interaction_manager";
import OfferEngagement from "../../../model/offer_engagement";
import { isUndefined } from "../../../../../service/lang";

export default class InteractionMachineFactory {
  static build(
    type: { new(): InteractionMachine },
    context: InteractionManager,
    base?: InteractionMachine
  ): InteractionMachine {
    const machine = new type();

    if (!isUndefined(base)) {
      const chat = base.chat;
      chat.setMachine(machine);
      machine.setChatState(chat);

      const dialog = base.dialog;
      dialog.setMachine(machine);
      machine.setDialogState(dialog);

      const offer = base.offer;
      offer.setMachine(machine);
      machine.setOfferState(offer);

      const popup = base.popup;
      popup.setMachine(machine);
      machine.setPopupState(popup);

      const pullup = base.pullup;
      pullup.setMachine(machine);
      machine.setPullupState(pullup);

      if (base.hasChatEngagement) {
        machine.setChatEngagement(base.chatOptions as ChatOptions);
      } else {
        machine.clearChatEngagement();
      }

      if (base.hasOfferEngagement) {
        machine.setOfferEngagement(base.offerEngagement as OfferEngagement);
      } else {
        machine.clearOfferEngagement();
      }

      if (!isUndefined(base.chatWidget)) {
        machine.setChatWidget(base.chatWidget);
      }
    }

    machine.setContext(context);

    return machine;
  }
}
