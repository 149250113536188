import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import Factory from "../../../../../service/factory";
import Offer from "../../../model/offer";
import OffersRepository from "../offers_repository";
import Specification from "../../../specification/specification";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { isUndefined } from "../../../../../service/lang";

export default class BaseOffersRepository implements OffersRepository {
  private _storage: StorageRegistrar | undefined;

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  getAvailableOffers(specification: Specification): Offer[] {
    return this.list().filter(o => specification.isSatisfiedBy(o));
  }

  getById(id: string | number): Offer | undefined {
    return this.list().find((i: any) => i?.externalRefId === id);
  }

  list(): Offer[] {
    return this.storage.getOffers() ?? [];
  }

  setStorage(update: StorageRegistrar): void {
    this._storage = update;
  }
}