import {
  isArray,
  isEmpty,
  isObject,
  isString,
} from "../../../../../service/lang";

import URLService from "../url_service";
import UTMParams from "../utm_params";

export default class BaseURLService implements URLService {
  protected get href(): string {
    return window.location.href;
  }

  getCurrentPath(): string | undefined {
    return this.getCurrentURLObject()?.pathname;
  }

  getCurrentUrlObj(): URL | undefined {
    return this.getCurrentURLObject();
  }

  getCurrentSearch(): string | undefined {
    return this.getCurrentURLObject()?.search;
  }

  getCurrentSearchParams(): any {
    const result: any = {};
    const search = this.getCurrentSearch()?.substring(1);
    const pairs = search?.split("&");

    pairs?.forEach((pair) => {
      const split = pair?.split?.("=");
      const key = split?.[0];

      if (isString(key) && !isEmpty(key)) {
        const value = split[1];
        result[key] = value;
      }
    });

    return result;
  }

  getCurrentURL(): string | undefined {
    return this.href;
  }

  protected getCurrentURLObject(): URL | undefined {
    return this.parseURL(this.href);
  }

  getOriginlessHref(): string | undefined {
    const path: string = this.getCurrentPath() ?? "";
    const search: string = this.getCurrentSearch() ?? "";
    return path + search;
  }

  getURLPath(source: string): string | undefined {
    const url: URL | undefined = this.parseURL(source);
    return url?.pathname;
  }

  getUTMParams(): any {
    const result: any = {};
    const searchParams = this.getCurrentSearchParams();
    result[UTMParams.campaign] = searchParams[UTMParams.campaign] ?? null;
    result[UTMParams.content] = searchParams[UTMParams.content] ?? null;
    result[UTMParams.medium] = searchParams[UTMParams.medium] ?? null;
    result[UTMParams.source] = searchParams[UTMParams.source] ?? null;
    result[UTMParams.term] = searchParams[UTMParams.term] ?? null;
    return result;
  }

  objectToParams(source: any): string {
    let params: string = "";

    if (isObject(source)) {
      const buffer = [];

      for (let prop in source) {
        if (!isEmpty(prop)) {
          const key = encodeURIComponent(prop);
          const value = encodeURIComponent(source[prop]);
          const pair = `${key}=${value}`;
          buffer.push(pair);
        }
      }

      params = buffer.join("&");
    }

    return params;
  }

  protected parseURL(source: string): URL | undefined {
    let url: URL | undefined;

    try {
      url = new URL(source);
    } catch (error) {}

    return url;
  }

  URLContains(target: string): boolean {
    return this.href.includes(target);
  }

  URLsToPaths(source: any): string[] {
    let result: string[] = [];

    if (isArray(source)) {
      result = source
        .map((e) => this.getURLPath(e) ?? "")
        .filter((e) => isString(e) && !isEmpty(e));
    }

    return result;
  }
}
