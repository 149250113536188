import { BaseInteractionState, PullupState } from "../../index";

import offer from "../../../../../model/offer";

export abstract class BasePullup extends BaseInteractionState implements PullupState {
  openPullup(offer: offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
  }

  showPullup(offer: offer): void {
    const content = this.service.composePullup(offer);
    this.service.pull(content);
  }
} 
