export default class DealershipError extends Error {
  constructor(message: string) {
    super(message);
  }

  private static _build(message: string): DealershipError {
    return new DealershipError(message);
  }

  static noDealershipId(): DealershipError {
    return this._build('No dealership ID!');
  }
}
