export default class PageTypes {
  static readonly home: string = 'home';
  static readonly other: string = 'other';
  static readonly service: string = 'service';
  static readonly SRP: string = 'srp';
  static readonly VDP: string = 'vdp';

  static isVDP(test: any): boolean {
    return test === this.VDP;
  }
}
