import BaseDealership from "./base_dealership";
import { BaseOffer } from "./base_offer";
import Dealership from "../dealership";
import MasterConfig from "../master_config";
import Offer from "../offer";
import { isArray } from "../../../../service/lang";

export class BaseMasterConfig implements MasterConfig {
  private _json: any;

  constructor(json: any) {
    this._json = json;
  }

  get blocked(): boolean {
    return this._json?.blocked;
  }

  get dealership(): Dealership {
    return new BaseDealership(this._json?.[BaseMasterConfigKeys.dealership]);
  }

  get disposition(): string {
    return this._json?.disposition;
  }

  get offers(): Offer[] {
    let result: Offer[] = [];
    const source = this._json?.[BaseMasterConfigKeys.offers];

    if (isArray(source)) {
      result = source.map((s) => new BaseOffer(s));
    }

    return result;
  }

  toJSON(): any {
    return this._json;
  }
}

export class BaseMasterConfigKeys {
  static readonly blocked: string = "blocked";
  static readonly dealership: string = "dealership";
  static readonly disposition: string = "disposition";
  static readonly offers: string = "offers";
}
