import { BaseSpecification } from "..";
import Factory from "../../../../service/factory";
import OfferSpecificationLogger from "./offer_specification_logger";
import SpecificationLogger from "../specification_logger";
import { isUndefined } from "../../../../service/lang";

export default class OfferSpecification extends BaseSpecification {
  private _specLogger: SpecificationLogger | undefined;

  /**
   * @override
   */
  get specLogger(): SpecificationLogger {
    if (isUndefined(this._specLogger)) {
      this._specLogger = Factory.instance.build(OfferSpecificationLogger);
    }

    return this._specLogger;
  }

  /**
   * @override
   */
  log(candidate: any, specification: any, result: any): void {
    this.specLogger.log(candidate.externalRefId, specification.name, result);
  }
}
