import { isArray, isEmpty, isString } from "../../../../../service/lang";

import Offer from "../../../model/offer";
import ZipRanges from "../../../model/zip_ranges";
import ZIPSpecification from "./zip_specification";

export default class ZIPRangeIncluded extends ZIPSpecification {
  /**
   * @override
   */

  isSatisfiedBy(candidate: Offer): boolean {
    const zip: string | undefined = this.getCurrentZIP();
    if (!isString(zip) || isEmpty(zip)) {
      this.log(candidate, ZIPRangeIncluded, true);
      return true;
    }

    const included: ZipRanges[] | undefined = candidate?.includeZIPsRange;
    if (!isArray(included) || isEmpty(included)) {
      this.log(candidate, ZIPRangeIncluded, true);
      return true;
    }

    const isIncluded = included.some(
      (zipIncluded: ZipRanges) =>
        Number(zip.replace(/\s/g, "")) >= Number(zipIncluded?.start) &&
        Number(zip.replace(/\s/g, "")) <= Number(zipIncluded?.end)
    );

    this.log(candidate, ZIPRangeIncluded, isIncluded);
    return isIncluded;
  }
}
