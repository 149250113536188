enum DeviceTypes {
  desktop = 'desktop',
  mobile = 'mobile'
}

namespace DeviceTypes {
  export function isAll(source: DeviceTypes[]): boolean {
    return source.includes(DeviceTypes.desktop) &&
      source.includes(DeviceTypes.mobile);
  }
}

export default DeviceTypes;
