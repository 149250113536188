import { isArray, isEmpty } from "../../../../../../../service/lang";

import Offer from "../../../../../model/offer";
import SegmentSpecification from "../segment_specification";

export default class SegmentExcluded extends SegmentSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const base: string = this.getOriginlessHref() ?? '';

    if (!isEmpty(base)) {
      const segments: string[] = this.getSegmentsData(candidate);

      if (isArray(segments) && !isEmpty(segments)) {
        isSatisfied = segments.some(s => {
          const pattern: RegExp = new RegExp(s, 'i');
          return pattern.test(base);
        });
      } else {
        isSatisfied = false;
      }
    } else {
      isSatisfied = false;
    }

    this.log(candidate, SegmentExcluded, isSatisfied);

    return isSatisfied;
  }
}
