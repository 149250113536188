import { BaseSpecification } from "./index";
import Specification from "./specification";

export class AndSpecification extends BaseSpecification {
  private _left: Specification;
  private _right: Specification;

  constructor(left: Specification, right: Specification) {
    super();
    this._left = left;
    this._right = right;
  }

  /**
   * @override
   */
  isSatisfiedBy(candidate: any): boolean {
    const left = this._left.isSatisfiedBy(candidate);
    const right = this._right.isSatisfiedBy(candidate);

    return left && right;
  }
}
