import { isEmpty, isNull, isUndefined } from "../../../../service/lang";

import AppConfig from "../../app.config";
import Validator from "./validator";

export default class RequiredValidator implements Validator {
  error(name: string): string {
    return AppConfig.instance.locale.validationRequired(name);
  }

  validate(input: any): boolean {
    return !isUndefined(input) && !isNull(input) && !isEmpty(input);
  }
}
