import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";
import VisitorStatus from "../../../enum/visitor_status";
import { isUndefined } from "../../../../../service/lang";

export default class VisitorStatusMet extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const visitorStatus: VisitorStatus | undefined = this.getVisitorStatus();
    const offerRequirement: VisitorStatus[] = candidate.visitorStatus;
    const isAll: boolean = VisitorStatus.isAll(offerRequirement);

    if (isAll) {
      isSatisfied = true;
    } else if (!isUndefined(visitorStatus)) {
      isSatisfied = offerRequirement.includes(visitorStatus);
    } else {
      isSatisfied = false;
    }

    this.log(candidate, VisitorStatusMet, isSatisfied);

    return isSatisfied;
  }
}
