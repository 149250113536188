import DeviceTypes from "../../../enum/device_types";
import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";
import { isUndefined } from "../../../../../service/lang";

export default class DeviceTypeMet extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const offerRequirement: DeviceTypes[] = candidate.deviceTypes;
    const isAll: boolean = DeviceTypes.isAll(offerRequirement);
    const deviceType: DeviceTypes | undefined = this.getDeviceType();

    if (isAll) {
      isSatisfied = true;
    } else if (!isUndefined(deviceType)) {
      isSatisfied = offerRequirement.includes(deviceType);
    } else {
      isSatisfied = false;
    }

    this.log(candidate, DeviceTypeMet, isSatisfied);

    return isSatisfied;
  }
}
