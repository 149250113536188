import { isEmpty, isString, isUndefined } from "../../../../../../../../service/lang";

import AppConfig from "../../../../../../app.config";
import { ChatOptions } from "../typedef";
import { ChatTypes } from "../../../../../../enum/chat_types";

export default class ChatEngagementOptionsAdapter implements ChatOptions {
  readonly apiKey: string;
  readonly channelId: string;
  readonly chatbot?: {
    readonly avatar?: string,
    readonly id?: string,
    readonly nickname?: string,
    readonly refId?: string,
  };
  readonly engagementKey?: string | null;
  readonly greeting?: {
    readonly style?: string,
    readonly template?: string,
  };
  readonly historyId?: number;
  readonly notificationId?: string | null;
  readonly type: ChatTypes = ChatTypes.engagement;
  readonly user?: {
    readonly avatar?: string,
    readonly id?: string,
    readonly nickname?: string,
  };
  readonly visitorId: string;

  constructor(source: any) {
    this.apiKey = source?.sendBirdApiKey;
    this.channelId = source?.channelId;
    this.chatbot = source?.chatBot ? {
      avatar: source?.user?.chatBot,
      id: source?.chatBot?.refId,
      nickname: this._getChatbotNickname(source?.chatBot),
      refId: source?.chatBot?.chatBotRefId,
    } : undefined;
    this.engagementKey = source?.engagementKey;
    this.greeting = {
      style: source?.chatGreeting?.htmlStyle,
      template: source?.chatGreeting?.htmlBody,
    };
    this.historyId = source?.chatHistoryId;
    this.notificationId = source?.notificationId;
    this.user = source?.user ? {
      avatar: source?.user?.avatarUrl,
      id: source?.user?.refId,
      nickname: this._getUserNickname(source?.user),
    } : undefined;
    this.visitorId = source?.visitorId;
  }

  private _getChatbotNickname(chatbot: any): string {
    let nickname;

    if (isString(chatbot?.nickname)) {
      nickname = chatbot?.nickname;
    } else if (isString(chatbot.refId)) {
      nickname = chatbot.refId;
    }

    return nickname ?? AppConfig.instance.chatDefaultNickname;
  }

  private _getUserNickname(user: any): string {
    let nickname;

    if (!isUndefined(user)) {
      let buffer = '';

      if (isString(user?.firstName)) {
        buffer += user?.firstName;
      }

      if (isString(user?.lastName)) {
        buffer += `${isEmpty(buffer) ? '' : ' '}${user?.lastName}`;
      }

      if (isEmpty(buffer)) {
        nickname = user?.email;
      } else {
        nickname = buffer;
      }
    }

    return nickname ?? AppConfig.instance.chatDefaultNickname;
  }
}
