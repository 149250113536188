import {
  attachDocumentListener,
  attachWindowListener,
  fadeIn_,
  findById,
  hasClass,
  insertBodyContent,
  insertHeadContent,
  isInDOM,
} from "../../../../../service/dom/dom_utilities";
import {
  isEmpty,
  isNumber,
  isString,
  isUndefined,
  sample,
  sampleRank,
} from "../../../../../service/lang";

import AppConfig from "../../../app.config";
import BackendService from "../../../service/backend/backend_service";
import BaseBackendService from "../../../service/backend/base/base_backend_service";
import BaseDOMService from "../../../service/dom/base/base_dom_service";
import BaseDuration from "../../../../../service/duration/base/base_duration";
import BaseGoogleAnalytics from "../../../service/google/analytics/base/base_google_analytics";
import BaseLibrary from "../../../service/library/base/base_library";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseNotifier from "../../../service/notifier/base/base_notifier";
import BaseOfferPicker from "../../../specification/offer/base/base_offer_picker";
import BaseOffersRepository from "../../../repository/offer/base/base_offers_repository";
import BaseOptionsService from "../../../service/options/base/base_options_service";
import BaseStateBuilder from "../../../state/base/base_state_builder";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import BaseURLService from "../../../service/url/base/base_url_service";
import { ChatOptions } from "../machines/states/chat/typedef";
import DOMEvents from "../../../../../service/dom/dom_events";
import DOMService from "../../../service/dom/dom_service";
import Duration from "../../../../../service/duration/duration";
import Events from "../../../enum/events";
import Factory from "../../../../../service/factory";
import FormConstraints from "../form_constraints";
import Formatter from "../../../utilities/formatters/formatter";
import GoogleAnalytics from "../../../service/google/analytics/google_analytics";
import HTTPStatus from "../../../../../service/http/http_status";
import InteractionService from "../interaction_service";
import Interactions from "../interactions";
import LeadComposer from "../composers/lead_composer";
import LeadTypes from "../../../enum/lead_types";
import Library from "../../../service/library/library";
import Logger from "../../../../../service/logger/logger";
import ModalComposer from "../composers/modal_composer";
import Notifier from "../../../service/notifier/notifier";
import Offer from "../../../model/offer";
import OfferComposer from "../composers/offer_composer";
import OfferEngagement from "../../../model/offer_engagement";
import OfferPicker from "../../../specification/offer/offer_picker";
import OffersRepository from "../../../repository/offer/offers_repository";
import { OnMessage } from "../../../../../persistent_chat/src/widget/typedef";
import OptionsService from "../../../service/options/options_service";
import PageTypes from "../../../enum/page_types";
import { PersistentChatMessageResponse } from "../../../service/backend/typedef";
import PersistentChatWidget from "../../../../../persistent_chat/src/widget/chat_widget";
import PersistentChatWidgetConfig from "../../../../../persistent_chat/src/widget/chat_widget_config";
import PersistentChatWidgetState from "../../../../../persistent_chat/src/widget/persistent_chat_widget_state";
import StandardPhoneFormatter from "../../../utilities/formatters/phone/standard_phone_formatter";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { StyleCSS } from "../../../asset/css/style";
import TimeUnits from "../../../../../service/time_units";
import URLService from "../../../service/url/url_service";
import Validation from "../../../utilities/validation/validation";

declare let _pi_rts_persistent_chat: any;

export default class BaseInteractionService implements InteractionService {
  private static _POPUP_TEST = new RegExp("liveoffer_pop", "i");
  private static _PULLUP_TEST = new RegExp("liveoffer_pull", "i");
  private static _VSP_POPUP_TEST = new RegExp("VSP_pop", "i");

  private _backend: BackendService | undefined;
  private _dom: DOMService | undefined;
  private _formConstraints: FormConstraints | undefined;
  private _googleAnalytics: GoogleAnalytics | undefined;
  private _leadComposer: LeadComposer | undefined;
  private _library: Library | undefined;
  private _logger: Logger | undefined;
  private _modalComposer: ModalComposer | undefined;
  private _notifier: Notifier | undefined;
  private _offerComposer: OfferComposer | undefined;
  private _options: OptionsService | undefined;
  private _phoneFormatter: Formatter | undefined;
  private _picker: OfferPicker | undefined;
  private _repository: OffersRepository | undefined;
  private _stateBuilder: StateBuilder | undefined;
  private _storage: StorageRegistrar | undefined;
  private _url: URLService | undefined;

  protected get backend(): BackendService {
    if (isUndefined(this._backend)) {
      this._backend = Factory.instance.build(BaseBackendService);
    }

    return this._backend;
  }

  protected get dom(): DOMService {
    if (isUndefined(this._dom)) {
      this._dom = Factory.instance.build(BaseDOMService);
    }

    return this._dom;
  }

  protected get formConstraints(): FormConstraints | undefined {
    if (isUndefined(this._formConstraints)) {
      const config = this.storage.getMasterConfig();
      this._formConstraints = config?.dealership?.constraints;
    }

    return this._formConstraints;
  }

  protected get googleAnalytics(): GoogleAnalytics {
    if (isUndefined(this._googleAnalytics)) {
      this._googleAnalytics = Factory.instance.build(BaseGoogleAnalytics);
    }

    return this._googleAnalytics;
  }

  get hasPendingChat(): boolean {
    return this.storage.hasPendingChat();
  }

  get hasPendingOffer(): boolean {
    return this.storage.hasPendingOffer();
  }

  get hasPullupSession(): boolean {
    return this.storage.getPullupSession() === true;
  }

  get isChatSession(): boolean {
    return this.storage.hasChatSession();
  }

  get isDealershipPaused(): boolean {
    const config = this.storage.getMasterConfig();
    return config?.dealership?.paused === true;
  }

  get isPopupTest(): boolean {
    const search = this.url.getCurrentSearch() ?? "";
    return BaseInteractionService._POPUP_TEST.test(search);
  }

  get isSpecificPopupTest(): boolean {
    const search = this.url.getCurrentSearch() ?? "";
    return BaseInteractionService._VSP_POPUP_TEST.test(search);
  }

  get isPullupTest(): boolean {
    const search = this.url.getCurrentSearch() ?? "";
    return BaseInteractionService._PULLUP_TEST.test(search);
  }

  get isVDP(): boolean {
    return PageTypes.isVDP(this.state.pageType);
  }

  get isVisitorBlocked(): boolean {
    const config = this.storage.getMasterConfig();
    return config?.blocked === true;
  }

  get isVisitorConverted(): boolean {
    return this.storage.getVisitorConverted() === true;
  }

  protected get leadComposer(): LeadComposer {
    if (isUndefined(this._leadComposer)) {
      this._leadComposer = Factory.instance.build(LeadComposer);
    }

    return this._leadComposer;
  }

  protected get library(): Library {
    if (isUndefined(this._library)) {
      this._library = Factory.instance.build(BaseLibrary);
    }

    return this._library;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get modalComposer(): ModalComposer {
    if (isUndefined(this._modalComposer)) {
      this._modalComposer = Factory.instance.build(ModalComposer);
    }

    return this._modalComposer;
  }

  protected get notifier(): Notifier {
    if (isUndefined(this._notifier)) {
      this._notifier = Factory.instance.build(BaseNotifier);
    }

    return this._notifier;
  }

  protected get offerComposer(): OfferComposer {
    if (isUndefined(this._offerComposer)) {
      this._offerComposer = Factory.instance.build(OfferComposer);
    }

    return this._offerComposer;
  }

  protected get options(): OptionsService {
    if (isUndefined(this._options)) {
      this._options = Factory.instance.build(BaseOptionsService);
    }

    return this._options;
  }

  protected get phoneFormatter(): Formatter {
    if (isUndefined(this._phoneFormatter)) {
      this._phoneFormatter = Factory.instance.build(StandardPhoneFormatter);
    }

    return this._phoneFormatter;
  }

  protected get picker(): OfferPicker {
    if (isUndefined(this._picker)) {
      this._picker = Factory.instance.build(BaseOfferPicker);
    }

    return this._picker;
  }

  protected get repository(): OffersRepository {
    if (isUndefined(this._repository)) {
      this._repository = Factory.instance.build(BaseOffersRepository);
    }

    return this._repository;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  addClass(selector: string, className: string): void {
    this.dom.addClassAll(selector, className);
  }

  attachDOMEventListener(event: DOMEvents, callback: Function): void {
    attachDocumentListener(event, callback);
  }

  attachEventListener(event: Events, callback: Function): void {
    attachWindowListener(event, callback);
  }

  clearChatSession(): void {
    this.storage.clearChatSession();
  }

  clearPendingChat(): void {
    this.storage.clearPendingChat();
  }

  clearPendingOffer(): void {
    this.storage.clearPendingOffer();
  }

  composeOffer(engagement: OfferEngagement): string {
    return this.offerComposer.composeEngagementOffer(engagement);
  }

  composePopup(offer: Offer): string {
    return this.offerComposer.composePopupOffer(offer);
  }

  composePullup(offer: Offer): string {
    return this.offerComposer.composePullupOffer(offer);
  }

  composePriBanner(offer: Offer): string {
    return this.offerComposer.composePrimaryBanner(offer);
  }

  disable(selector: string): void {
    this.dom.disableAll(selector);
  }

  enable(selector: string): void {
    this.dom.enableAll(selector);
  }

  formatPhoneNumber(input: string): string {
    return this.phoneFormatter.format(input);
  }

  getChatSession(): ChatOptions {
    return this.storage.getChatSession();
  }

  getFormData(): any {
    return this.dom.serializeForm(Interactions.PopupSelectors.form);
  }

  getOfferById(id: string): Offer | undefined {
    return this.repository.getById(id);
  }

  getOfferTimeout(offer: Offer): number | undefined {
    const seconds = this.isVDP
      ? offer.minimumVDPDuration
      : offer.minimumSessionDuration;
    const timeout: Duration | undefined = isNumber(seconds)
      ? new BaseDuration(seconds, TimeUnits.seconds)
      : undefined;
    return timeout?.toMilliseconds();
  }

  getPendingChat(): ChatOptions {
    return this.storage.getPendingChat();
  }

  getPendingOffer(): OfferEngagement {
    return this.storage.getPendingOffer();
  }

  getPersistentChatWidget(onMessage: OnMessage): Promise<PersistentChatWidget> {
    return new Promise((resolve, reject) => {
      this.library
        .loadPersistentChat()
        .then((_response) => {
          const config: PersistentChatWidgetConfig = {
            onMessage: onMessage,
            zIndex: this.options.zIndex,
            avatar: this.options.persistentChatOptions.avatar,
            greeting: this.options.persistentChatOptions.message,
          };
          const widget = new _pi_rts_persistent_chat.widget(config);
          resolve(widget);
        })
        .catch((error) => reject(error));
    });
  }

  getPullupOfferId(event: any): string | undefined {
    const elements = event.composedPath();
    const predicate = (e: any) => hasClass(e, Interactions.PullupKeys.item);
    const pullup = elements.find(predicate);
    const offerId = pullup?.dataset?.[Interactions.PullupKeys.offerIdDataKey];

    return offerId;
  }

  getPrimaryBannerOfferId(event: any): string | undefined {
    const elements = event.composedPath();
    const predicate = (e: any) =>
      hasClass(e, Interactions.PrimaryBannerKeys.item);
    const pullup = elements.find(predicate);
    const offerId = pullup?.dataset?.[Interactions.PullupKeys.offerIdDataKey];

    return offerId;
  }

  hidePersistentChat(widget: Promise<PersistentChatWidget>): Promise<void> {
    return new Promise((resolve, reject) => {
      widget
        .then((instance) => {
          instance.close();
          instance.hide();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  initializePersistentChatWidget(
    widget: Promise<PersistentChatWidget>
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      widget
        .then((instance) => {
          instance.initialize();
          instance.show();

          const widgetState = this.storage.getPersistentChatWidgetState();

          if (widgetState === PersistentChatWidgetState.opened) {
            instance.open();
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  insertStyle(): void {
    if (!isInDOM(Interactions.StyleSelectors.container)) {
      insertHeadContent(StyleCSS);
    }
  }

  notifyPersistentChatShown(): void {
    const hasNotified = this.storage.getPersistentChatShownSent() === true;

    if (!hasNotified && isString(this.state.dealershipId)) {
      this.backend
        .notifyPersistentChatShown({
          dealershipExtRefId: this.state.dealershipId,
        })
        .then((response) => {
          if (response.status === HTTPStatus.OK) {
            this.storage.setPersistentChatShownSent();
          }
        })
        .catch((error) => this.logger.error(error));
    }
  }

  pickOffer(): Offer | undefined {
    let offer;

    /**
     * If there is saved "ever visited offer" we should show it on every page,
     * all other offers should be ignored.
     */
    const everVisitedOffer = this.storage.getEverVisitedOffer();

    if (isString(everVisitedOffer)) {
      const candidate = this.repository.getById(everVisitedOffer);
      const shown = this.storage.getShownOffersCount() ?? 0;
      const limit = candidate?.maxOffersPerSession ?? 0;

      if (shown < limit) {
        offer = candidate;
      }
    } else {
      offer = this.picker.pickOffer();

      if (!isUndefined(offer)) {
        const hasModel = !isEmpty(offer.includeModels);
        const isEverVisited = offer.showOnEveryVisit;

        if (hasModel && isEverVisited) {
          if (isString(offer.externalRefId)) {
            this.storage.setEverVisitedOffer(offer.externalRefId);
          }
        }
      }
    }

    return offer;
  }

  pickTestOffer(): Offer | undefined {
    let offer: Offer | undefined;
    const picked = this.picker.pickOffer();

    if (isUndefined(picked)) {
      const offers = this.repository.list();

      if (!isEmpty(offers)) {
        offer = sampleRank(offers, true);
      }
    } else {
      offer = picked;
    }

    return offer;
  }

  pickSpecificTestOffer(): Offer | undefined {
    let offer: Offer | undefined;
    const picked = this.picker.pickSpecificOffer();

    if (isUndefined(picked)) {
      const offers = this.repository.list();

      if (!isEmpty(offers)) {
        offer = sampleRank(
          offers?.filter(
            (offer) => offer?.offerName && offer.offerName.includes("VSP")
          ),
          true
        );
      }
    } else {
      offer = picked;
    }
    return offer;
  }

  pop(content: string): void {
    this.insertStyle();
    const zIndex = AppConfig.instance.zIndex;
    const modalHTML = this.modalComposer.composeSimpleModal(content, zIndex);
    insertBodyContent(modalHTML);
    const modal = findById(Interactions.ModalKeys.container);

    if (!isUndefined(modal)) {
      fadeIn_(modal);
    }
  }

  pull(content: string): void {
    const containers = this.dom.findAll(Interactions.PullupSelectors.container);
    containers?.forEach((c) => (c.innerHTML = content));
  }

  remove(selector: string): void {
    this.dom.remove(selector);
  }

  removeClass(selector: string, className: string): void {
    this.dom.removeClassAll(selector, className);
  }

  schedule(callback: Function, timeout: number): any {
    return this.dom.setTimeout(callback, timeout);
  }

  sendPersistentChatMessage(
    message: string
  ): Promise<PersistentChatMessageResponse> {
    return this.backend.sendPersistenChatMessage({
      botOrgPaid: this.state.botOrgPaid as string,
      dealershipRefId: this.state.dealershipId as string,
      engagementScore: this.state.visitorScore,
      make: this.state.customItems?.make,
      message: message,
      model: this.state.customItems?.model,
      sessionTime: this.state.timeSpent,
      startedInUrl: this.state.url as string,
      vehicleType: this.state.customItems?.type ?? null,
      visitorRefId: this.state.visitorId as string,
      visitorSession: this.state.sessionId as string,
      year: this.state.customItems?.year,
      pageType: this.state.pageType as string,
    });
  }

  setDOMService(update: DOMService): void {
    this._dom = update;
  }

  startPullupSession(duration: Duration): void {
    this.storage.setPullupSession(duration);
  }

  submitLead(
    type: LeadTypes,
    data: any,
    engagement?: OfferEngagement
  ): Promise<void> {
    const lead = this.leadComposer.composeLead(type, data, engagement);
    return this.backend.postLead(lead);
  }

  unschedule(handle: any): void {
    this.dom.clearTimeout(handle);
  }

  validateFormData(data: any): string | undefined {
    const maxLength = AppConfig.instance.inputMaxLength;
    const length = Validation.size(maxLength);
    const required = Validation.required;

    if (Interactions.hasFirstNameControl()) {
      if (this.formConstraints?.isRequiredFirstName) {
        const placeholder = Interactions.getFirstNamePlaceholder();
        const validRequired = required.validate(data.first_name);
        const validLength = length.validate(data.first_name);

        if (!validRequired) {
          return required.error(placeholder);
        } else if (!validLength) {
          return length.error(placeholder);
        }
      }
    }

    if (Interactions.hasLastNameControl()) {
      if (this.formConstraints?.isRequiredLastName) {
        const placeholder = Interactions.getLastNamePlaceholder();
        const validRequired = required.validate(data.last_name);
        const validLength = length.validate(data.last_name);

        if (!validRequired) {
          return required.error(placeholder);
        } else if (!validLength) {
          return length.error(placeholder);
        }
      }
    }

    if (Interactions.hasPhoneControl()) {
      if (this.formConstraints?.isRequiredPhone) {
        const phone = Validation.phone;
        const placeholder = Interactions.getPhonePlaceholder();
        const validRequired = required.validate(data.phone);
        const validPhone = phone.validate(data.phone);

        if (!validRequired) {
          return required.error(placeholder);
        } else if (!validPhone) {
          return phone.error(placeholder);
        }
      }
    }

    if (Interactions.hasEmailControl()) {
      if (this.formConstraints?.isRequiredEmail) {
        const email = Validation.email;
        const placeholder = Interactions.getEmailPlaceholder();
        const validRequired = required.validate(data.email);
        const validEmail = email.validate(data.email);

        if (!validRequired) {
          return required.error(placeholder);
        } else if (!validEmail) {
          return email.error(placeholder);
        }
      }
    }

    if (Interactions.hasCRMControl()) {
      if (this.formConstraints?.isRequiredCRMEmail) {
        const crm = Validation.crm;
        const placeholder = Interactions.getCRMPlaceholder();
        const validCRM = crm.validate(data.crmEmail);

        if (!validCRM) {
          return crm.error(placeholder);
        }
      }
    }

    if (Interactions.hasADFDetailsControl()) {
      if (this.formConstraints?.isRequiredAdfDetails) {
        const placeholder = Interactions.getADFDetailsPlaceholder();
        const validRequired = required.validate(data.adfDetails);

        if (!validRequired) {
          return required.error(placeholder);
        }
      }
    }

    if (Interactions.hasDisclaimer()) {
      const disclaimer = Validation.disclaimer;
      const checkbox = Interactions.getDisclaimer();
      const validDisclaimer = disclaimer.validate(checkbox);

      if (!validDisclaimer) {
        return disclaimer.error();
      }
    }
  }
}
