import { BasePopup, DialogTest, PopupShown, PopupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PopupTest extends BasePopup implements PopupState {
  /**
   * @override
   */
  showPopup(offer: Offer): void {
    super.showPopup(offer);
    this.stateBuilder.setOfferId(offer.externalRefId);
    this.switchDialogState(DialogTest);
    this.switchPopupState(PopupShown);
  }
}
