import {
  isEmpty,
  isNumber,
  isString,
  isUndefined,
} from "../../../../../service/lang";

import BaseDOMService from "../../../service/dom/base/base_dom_service";
import BaseLeadBuilder from "../base/base_lead_builder";
import BaseStateBuilder from "../../../state/base/base_state_builder";
import BaseURLService from "../../../service/url/base/base_url_service";
import CreativeTypes from "../../../enum/creative_types";
import DOMElements from "../../../../../service/dom/dom_elements";
import DOMService from "../../../service/dom/dom_service";
import Factory from "../../../../../service/factory";
import Interactions from "../interactions";
import LeadBuilder from "../lead_builder";
import LeadTypes from "../../../enum/lead_types";
import OfferEngagement from "../../../model/offer_engagement";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import URLService from "../../../service/url/url_service";
import { createElement } from "../../../../../service/dom/dom_utilities";

export default class LeadComposer {
  private _builder: LeadBuilder | undefined;
  private _dom: DOMService | undefined;
  private _stateBuilder: StateBuilder | undefined;
  private _url: URLService | undefined;

  protected get builder(): LeadBuilder {
    if (isUndefined(this._builder)) {
      this._builder = Factory.instance.build(BaseLeadBuilder);
    }

    return this._builder;
  }

  protected get dom(): DOMService {
    if (isUndefined(this._dom)) {
      this._dom = Factory.instance.build(BaseDOMService);
    }

    return this._dom;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  composeLead(type: LeadTypes, data: any, engagement?: OfferEngagement): any {
    this.builder.reset();
    this.builder.setFormData(data);
    this.builder.setLeadType(type);
    this.builder.setStateData(this.state);

    const utm = this.url.getUTMParams();

    if (!isEmpty(utm)) {
      this.builder.setUTMData(utm);
    }

    if (!isUndefined(engagement)) {
      if (isString(engagement.campaignId)) {
        this.builder.setCampaignId(engagement.campaignId);
      }

      if (isString(engagement.source)) {
        const creativeHTML = this.getCreativeHTML(engagement.source);
        this.builder.setCreativeHTML(creativeHTML);
      }

      this.builder.setCreativeType(CreativeTypes.asset);

      if (isString(engagement.notificationId)) {
        this.builder.setNotificationId(engagement.notificationId);
      }

      if (isNumber(engagement.historyId)) {
        this.builder.setOfferHistoryId(engagement.historyId);
      }

      if (isString(engagement.userId)) {
        this.builder.setUserId(engagement.userId);
      }
    }

    return this.builder.build();
  }

  composeNativeLead(): any {
    this.builder.reset();
    this.builder.setLeadType(LeadTypes.native);
    this.builder.setStateData(this.state);

    return this.builder.build();
  }

  protected getCreativeHTML(source: string): string {
    const container = createElement(DOMElements.div);
    container.innerHTML = source;
    const contents = container.getElementsByClassName(
      Interactions.PopupKeys.content
    );
    const creativeHTML = isEmpty(contents) ? source : contents[0]?.outerHTML;

    return creativeHTML;
  }
}
