import { BaseDialog, DialogReady, DialogState } from "../../index";

import EngagementCodes from "../../../../../enum/engagement_codes";
import LeadTypes from "../../../../../enum/lead_types";

export class DialogPullup extends BaseDialog implements DialogState {
  /**
   * @override
   */
  get leadType(): LeadTypes {
    return LeadTypes.pullup;
  }

  /**
   * @override
   */
  close(): void {
    super.close();
    this.switchDialogState(DialogReady);
  }

  /**
   * @overrride
   */
  protected notifyFailure(): void {
    this.notifier.notifyEngagement({ engagement: EngagementCodes.popupIgnored });
  }

  /**
   * @override
   */
  protected notifySuccess(): void {
    this.notifier.notifyEngagement({ engagement: EngagementCodes.popupAccepted });
  }
}
