import BaseStorageService from "../../storage/base/base_storage_service";
import Factory from "../../factory";
import Logger from "../logger";
import StorageKeys from "../../storage/storage_keys";
import StorageService from "../../storage/storage_service";
import { isUndefined } from "../../lang";

export default class BaseLogger implements Logger {
  private static readonly _prefix: string = 'PI_RTS_LOGGER:';
  protected readonly prefix: string;
  private _storage: StorageService | undefined;

  constructor(prefix: string = BaseLogger._prefix) {
    this.prefix = prefix;
  }

  get isEnabled(): boolean {
    return this.storage.hasKey(StorageKeys.loggerEnabled);
  }

  protected get storage(): StorageService {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageService);
    }

    return this._storage;
  }

  disable(): void {
    if (this.isEnabled) {
      this.storage.deleteKey(StorageKeys.loggerEnabled);
    }
  }

  enable(): void {
    if (!this.isEnabled) {
      this.storage.setKey(StorageKeys.loggerEnabled, { data: true });
    }
  }

  error(...error: any): void {
    if (this.isEnabled) {
      console.error(this.prefix, error);
      console.trace(`${this.prefix} STACK_TRACE`);
    }
  }

  info(...info: any): void {
    if (this.isEnabled) {
      console.log(this.prefix, info);
    }
  }

  warning(...warning: any): void {
    if (this.isEnabled) {
      console.warn(this.prefix, warning);
    }
  }
}
