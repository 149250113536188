export default class RTSDataKeys {
  static readonly botOrgPaid = 'botOrgPaid';
  static readonly dealershipId = 'dealershipID';
  static readonly deviceType = 'deviceType';
  static readonly engagement = 'engagement';
  static readonly engagementAcknowledgment = 'ackEngagement';
  static readonly engagementKey = 'engagementKey';
  static readonly engagementOwner = 'engagementOwner';
  static readonly isBlocked = 'isBlocked';
  static readonly make = 'make';
  static readonly model = 'model';
  static readonly metadata = 'metadata';
  static readonly offerId = 'offerID';
  static readonly pageClass = 'pageClass';
  static readonly pageViewId = 'pageViewID';
  static readonly popupShown = 'popupShown';
  static readonly pullupClicked = 'pullupClicked';
  static readonly pullupShown = 'pullupShown';
  static readonly scrollDepth = 'pageViewScrollDepth';
  static readonly sessionId = 'sessionID';
  static readonly timeSpent = 'sessionTime';
  static readonly visitorId = 'visitorID';
  static readonly url = 'URL';
  static readonly year = 'year';
  static readonly zip = 'sessionZIP';
  static readonly trim = 'trim'
  static readonly type = 'type'
}
