import { addClass, fadeIn_, findById, findBySelector, removeClass } from "../../../../../service/dom/dom_utilities";

import BaseCalculator from "../../calculator/base/base_calculator";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import Calculator from "../../calculator/calculator";
import DOMService from "../dom_service";
import Factory from "../../../../../service/factory";
import Interactions from "../../../manager/interaction/interactions";
import Logger from "../../../../../service/logger/logger";
import { isUndefined } from "../../../../../service/lang";

export default class BaseDOMService implements DOMService {
  private _document: Document | undefined;
  private _logger: Logger | undefined;
  private _window: Window | undefined;

  protected get calc(): Calculator {
    return Factory.instance.build(BaseCalculator);
  }

  protected get document(): Document {
    if (isUndefined(this._document)) {
      this._document = this.window.document;
    }

    return this._document;
  }

  protected get documentHeight(): number {
    const height = Math.max(
      this.document.body.scrollHeight,
      this.document.documentElement.scrollHeight,
      this.document.body.offsetHeight,
      this.document.documentElement.offsetHeight,
      this.document.body.clientHeight,
      this.document.documentElement.clientHeight);

    return height ?? 0;
  }

  get isActive(): boolean {
    let active;
    let hiddenKey;

    if (typeof this.document.hidden !== 'undefined') {
      hiddenKey = 'hidden';
      // @ts-ignore
    } else if (typeof this.document.msHidden !== 'undefined') {
      hiddenKey = 'msHidden';
      // @ts-ignore
    } else if (typeof this.document.webkitHidden !== 'undefined') {
      hiddenKey = 'webkitHidden';
    }

    if (this.document.hasFocus !== undefined) {
      active = this.document.hasFocus();
    } else if (hiddenKey !== undefined) {
      // @ts-ignore
      active = !this.document[hiddenKey];
    } else {
      this.logger.warning('Browser does not support hasFocus or PVAPI!');
      active = false;
    }

    return active;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  get scrollDepth(): number {
    const scrollTop = this.scrollTop;
    const difference = this.documentHeight - this.windowHeight;
    const quotient = this.calc.percentage(scrollTop, difference);
    const scrollPercent = Math.round(quotient);

    return scrollPercent;
  }

  protected get scrollTop(): number {
    let top;

    if (isUndefined(this.window.pageYOffset)) {
      const documentElement = this.document.documentElement;
      const target = documentElement.clientHeight ? documentElement : this.document.body;
      top = target.scrollTop;
    } else {
      top = this.window.pageYOffset;
    }

    return top ?? 0;
  }

  protected get window(): Window {
    if (isUndefined(this._window)) {
      this._window = window;
    }

    return this._window;
  }

  protected get windowHeight(): number {
    const height = this.window.innerHeight ||
      this.document.documentElement.clientHeight ||
      this.document.body.clientHeight;

    return height ?? 0;
  }

  addClassAll(selector: string, className: string): void {
    this.document.querySelectorAll(selector).forEach(element => addClass(element, className));
  }

  clearTimeout(handle: number): void {
    this.window.clearTimeout(handle);
  }

  disable(element: any): void {
    addClass(element, Interactions.StyleKeys.disabled);
  }

  disableAll(selector: string): void {
    this.document
      .querySelectorAll(selector)
      .forEach(element => this.disable(element));
  }

  empty(element: any): void {
    if (element?.innerHTML) {
      element.innerHTML = '';
    }
  }

  emptyFirst(selector: string): void {
    const element = findBySelector(selector);
    this.empty(element);
  }

  enable(element: any): void {
    removeClass(element, Interactions.StyleKeys.disabled);
  }

  enableAll(selector: string): void {
    this.document
      .querySelectorAll(selector)
      .forEach(element => this.enable(element));
  }

  findAll(selector: string): any[] {
    return this.document.querySelectorAll(selector) as unknown as any[];
  }

  findByClass(className: string): any {
    const elements = this.document.getElementsByClassName(className);
    return elements?.length ? elements[0] as HTMLElement : undefined;
  }

  getPlaceholder(selector: string): string {
    const article = findBySelector(selector);

    return article?.placeholder || article?.dataset?.placeholder;
  }

  hideElement(element: any): void {
    element?.style?.setProperty?.('display', 'none', 'important');
  }

  remove(selector: string): void {
    const elements = this.document.querySelectorAll(selector);
    elements?.forEach(e => this.removeElement(e));
  }

  removeClassAll(selector: string, className: string): void {
    this.document
      .querySelectorAll(selector)
      .forEach(element => removeClass(element, className));
  }

  removeElement(element: any): void {
    element?.remove();
  }

  removeElementById(id: string): void {
    const element = findById(id);
    element?.remove();
  }

  serializeForm(selector: string): object {
    const data: any = {};
    const controlsSelector = `${selector} input, ${selector} select`;
    const controls = this.document.querySelectorAll(controlsSelector);

    controls.forEach((c: any) => data[c.name] = c.value?.trim?.());

    return data;
  }

  setDocument(update: Document): void {
    this._document = update;
  }

  setTimeout(callback: Function, timeout: number): number {
    return this.window.setTimeout(callback, timeout);
  }

  setWindow(update: Window): void {
    this._window = update;
  }

  show(selector: string): void {
    const elements = this.document.querySelectorAll(selector);
    elements?.forEach(e => fadeIn_(e));
  }
}
