import { appendHeadElement, createElement } from "../../dom/dom_utilities";

import BaseLogger from "../../logger/base/base_logger";
import DOMElements from "../../dom/dom_elements";
import Factory from "../../factory";
import LoaderService from "../loader_service";
import Logger from "../../logger/logger";
import MIMETypes from "../../mime_types";
import { isUndefined } from "../../lang";

export default class BaseLoaderService implements LoaderService {
  private _logger: Logger | undefined;
  private _registry: Set<string> | undefined;

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get registry(): Set<string> {
    if (isUndefined(this._registry)) {
      this._registry = new Set();
    }

    return this._registry;
  }

  protected isLoaded(src: string): boolean {
    return this.registry.has(src);
  }

  protected register(src: string): void {
    this.registry.add(src);
  }

  script(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded(src)) {
        this.logger.warning(`${src} is already loaded!`);
        resolve();
      } else {
        const script = createElement(DOMElements.script);
        script.async = true;
        script.onerror = (error: any) => reject(error);
        script.onload = () => {
          this.register(src);
          resolve();
        };
        script.src = src;
        script.type = MIMETypes.applicationJavascipt;
        appendHeadElement(script);
      }
    });
  }
}
