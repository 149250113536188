import Factory from "../../../../service/factory";
import OfferPickerLog from "./offer_picker_log";
import SpecificationLogger from "../specification_logger";
import { isUndefined } from "../../../../service/lang";

export default class OfferSpecificationLogger implements SpecificationLogger {
  private _pickerLog: OfferPickerLog | undefined;

  private get pickerLog(): OfferPickerLog {
    if (isUndefined(this._pickerLog)) {
      this._pickerLog = Factory.instance.build(OfferPickerLog);
    }

    return this._pickerLog;
  }

  log(candidate: any, specification: any, result: any): void {
    this.pickerLog.write(candidate, specification, result);
  }
}
