import CreativeTypes from "../../../enum/creative_types";
import LeadBuilder from "../lead_builder";
import LeadKeys from "../lead_keys";
import LeadTypes from "../../../enum/lead_types";
import State from "../../../state/state";
import UTMParams from "../../../service/url/utm_params";

export default class BaseLeadBuilder implements LeadBuilder {
  private _lead: any = {};

  build() {
    return this._lead;
  }

  reset(): void {
    this._lead = {};
  }

  setCampaignId(update: string): void {
    this._lead[LeadKeys.campaignId] = update;
  }

  setCreativeHTML(update: string): void {
    this._lead[LeadKeys.creativeHTML] = update;
  }

  setCreativeType(update: CreativeTypes): void {
    this._lead[LeadKeys.creativeType] = update;
  }

  setFormData(update: any): void {
    Object.assign(this._lead, update);
  }

  setLeadType(update: LeadTypes): void {
    this._lead[LeadKeys.leadType] = update;
  }

  setNotificationId(update: string): void {
    this._lead[LeadKeys.notificationId] = update;
  }

  setOfferHistoryId(update: number): void {
    this._lead[LeadKeys.offerHistoryId] = update;
  }

  setStateData(update: State): void {
    const data: any = {};
    data[LeadKeys.campaignType] = update.botOrgPaid;
    data[LeadKeys.dealershipId] = update.dealershipId;
    data[LeadKeys.engagementScore] = update.visitorScore;
    data[LeadKeys.make] = update.customItems?.make;
    data[LeadKeys.model] = update.customItems?.model;
    data[LeadKeys.offerId] = update.offerId;
    data[LeadKeys.pageType] = update.pageClass;
    data[LeadKeys.pageViewId] = update.pageViewId;
    data[LeadKeys.scrollDepth] = update.scrollDepth;
    data[LeadKeys.sessionId] = update.sessionId;
    data[LeadKeys.sessionTime] = update.timeSpent;
    data[LeadKeys.url] = update.url ?? window.location.href;
    data[LeadKeys.vehicleType] = update.customItems?.type ?? null;
    data[LeadKeys.visitorId] = update.visitorId;
    data[LeadKeys.year] = update.customItems?.year;

    Object.assign(this._lead, data);
  }

  setUserId(update: string): void {
    this._lead[LeadKeys.userId] = update;
  }

  setUTMData(update: any): void {
    const utmParams: any = {};
    utmParams[LeadKeys.utmCampaign] = update[UTMParams.campaign];
    utmParams[LeadKeys.utmContent] = update[UTMParams.content];
    utmParams[LeadKeys.utmMedium] = update[UTMParams.medium];
    utmParams[LeadKeys.utmSource] = update[UTMParams.source];
    utmParams[LeadKeys.utmTerm] = update[UTMParams.term];

    Object.assign(this._lead, utmParams);
  }
}
