import DatetimeService from "../datetime_service";
import Duration from "../../duration/duration";

export default class BaseDatetimeService implements DatetimeService {
  get timestamp(): number {
    return new Date().getTime();
  }

  getFutureTimestamp(duration: Duration): number {
    return this.timestamp + duration.toMilliseconds();
  }
}
