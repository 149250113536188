import Offer from "../../../../../model/offer";
import SegmentExcluded from "./segment_excluded";

export default class VinExcluded extends SegmentExcluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.excludeVins;
  }
}