import FormConstraints from "../form_constraints";

export default class BaseFormConstraints implements FormConstraints {
  private _json: any;

  constructor(json: any) {
    this._json = json;
  }

  get isRequiredCRMEmail(): boolean {
    return this._json?.contactCrmEmailRequired ?? false;
  }

  get isRequiredEmail(): boolean {
    return this._json?.contactEmailRequired ?? false;
  }

  get isRequiredFirstName(): boolean {
    return this._json?.contactFirstNameRequired ?? false;
  }

  get isRequiredLastName(): boolean {
    return this._json?.contactLastNameRequired ?? false;
  }

  get isRequiredPhone(): boolean {
    return this._json?.contactPhoneNameRequired ?? false;
  }

  get isRequiredAdfDetails(): boolean {
    return this._json?.adfDetailsRequired ?? false;
  }
}
