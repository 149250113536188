import AppConfig from "../../app.config";
import Factory from "../../../../service/factory";
import RequiredValidator from "./required_validator";
import Validator from "./validator";
import { isUndefined } from "../../../../service/lang";

export default class CRMValidator implements Validator {
  private _required : RequiredValidator | undefined;

  private get required(): RequiredValidator {
    if (isUndefined(this._required)) {
      this._required = Factory.instance.build(RequiredValidator);
    }

    return this._required;
  }

  error(name: string): string {
    return AppConfig.instance.locale.validationCRM(name);
  }

  validate(input: any): boolean {
    return this.required.validate(input);
  }
}
