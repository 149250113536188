import {
  isObject,
  parseBooleanDemographics,
  parseNumber,
  parseString,
} from "../../../../service/lang";
import DemographicsFilterDto from "../demographics_filter_dto";

export class BaseDemographicsFilterDto implements DemographicsFilterDto {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get isMarried(): boolean | null | undefined {
    return parseBooleanDemographics(
      this._json?.[BaseDemographicsKeys.isMarried]
    );
  }

  get gender(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.gender]);
  }

  get hasChildren(): boolean | null | undefined {
    return parseBooleanDemographics(
      this._json?.[BaseDemographicsKeys.hasChildren]
    );
  }

  get numberOfChildren(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.numberOfChildren]);
  }

  get homeOwner(): boolean | null | undefined {
    return parseBooleanDemographics(
      this._json?.[BaseDemographicsKeys.homeOwner]
    );
  }

  get yearsInResidenceFrom(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.yearsInResidenceFrom]);
  }

  get yearsInResidenceTo(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.yearsInResidenceTo]);
  }

  get year(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.year]);
  }

  get make(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.make]);
  }

  get model(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.model]);
  }

  get firstSeenFrom(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.firstSeenFrom]);
  }

  get firstSeenTo(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.firstSeenTo]);
  }

  get lastSeenFrom(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.lastSeenFrom]);
  }

  get lastSeenTo(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.lastSeenTo]);
  }

  get incomeFrom(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.incomeFrom]);
  }

  get incomeTo(): number | undefined {
    return parseNumber(this._json?.[BaseDemographicsKeys.incomeTo]);
  }

  get education(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.education]);
  }

  get occupation(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.occupation]);
  }

  get businessOwner(): boolean | null | undefined {
    return parseBooleanDemographics(
      this._json?.[BaseDemographicsKeys.businessOwner]
    );
  }

  get language(): string | undefined {
    return parseString(this._json?.[BaseDemographicsKeys.language]);
  }
}

export class BaseDemographicsKeys {
  static isMarried: string = "isMarried";
  static gender: string = "gender";
  static hasChildren: string = "hasChildren";
  static numberOfChildren: string = "numberOfChildren";
  static homeOwner: string = "homeOwner";
  static yearsInResidenceFrom: string = "yearsInResidenceFrom";
  static yearsInResidenceTo: string = "yearsInResidenceTo";
  static year: string = "year";
  static make: string = "make";
  static model: string = "model";
  static firstSeenFrom: string = "firstSeenFrom";
  static firstSeenTo: string = "firstSeenTo";
  static lastSeenFrom: string = "lastSeenFrom";
  static lastSeenTo: string = "lastSeenTo";
  static incomeFrom: string = "incomeFrom";
  static incomeTo: string = "incomeTo";
  static education: string = "education";
  static occupation: string = "occupation";
  static businessOwner: string = "businessOwner";
  static language: string = "language";
}
