import { BaseOffer, ChatAccepting, DialogOffer, OfferAccepting, OfferState, PopupSuspended, PullupSuspended } from "../../index";

import OfferEngagement from "../../../../../model/offer_engagement";

export class OfferReady extends BaseOffer implements OfferState {
  /**
   * @override
   */
  showOffer(engagement: OfferEngagement): void {
    super.showOffer(engagement);
    this.setOfferEngagement(engagement);
    this.switchChatState(ChatAccepting);
    this.switchDialogState(DialogOffer);
    this.switchOfferState(OfferAccepting);
    this.switchPopupState(PopupSuspended);
    this.switchPullupState(PullupSuspended);
  }
}
