import BaseDeviceService from "../device/base/base_device_service";
import BaseRTSDataBuilder from "./base/base_rts_data_builder";
import BaseStateBuilder from "../../state/base/base_state_builder";
import BaseStorageRegistrar from "../storage/base/base_storage_registrar";
import DeviceService from "../device/device_service";
import Factory from "../../../../service/factory";
import RTSDataBuilder from "./rts_data_builder";
import RTSEngagementData from "./rts_engagement_data";
import State from "../../state/state";
import StateBuilder from "../../state/state_builder";
import StorageRegistrar from "../storage/storage_registrar";
import { isUndefined } from "../../../../service/lang";

export default class RTSDataComposer {
  private _device: DeviceService | undefined;
  private _stateBuilder: StateBuilder | undefined;
  private _storage: StorageRegistrar | undefined;
  protected _builder: RTSDataBuilder | undefined;

  protected get builder(): RTSDataBuilder {
    if (isUndefined(this._builder)) {
      this._builder = Factory.instance.build(BaseRTSDataBuilder);
    }

    return this._builder;
  }

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  get isBlocked(): boolean {
    return !!this.storage.getMasterConfig?.()?.blocked;
  }

  protected get isBlockedSent(): boolean {
    return !!this.storage.getBlockedSent();
  }

  protected get isBot(): boolean {
    return this.device.isBot();
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  composeAcknowledgement(state: State): any {
    this.builder.reset();
    this.builder.setEngagementAcknowledgement();
    this.builder.setState(state);

    return this.builder.build();
  }

  composeInitial(state: State): any {
    this.builder.reset();

    /**
     * We set the metadata if session ID, visitor ID ot campaign (botOrgPaid)
     * is missing. The RTS will return the campaign and also visitor ID if
     * necessary.
     * 
     * We need to always set the botOrgPaid to null to force the RTS to return
     * the parsed value. This is done in case that we miss session ID (are in a
     * new session) and we need to see if the visitor has come with paid
     * traffic.
     */
    if (!state.sessionId || !state.visitorId || !state.botOrgPaid) {
      this.stateBuilder.setSessionId();
      this.builder.setState(state);
      this.builder.setBotOrgPaid(null);
      this.builder.setMetadata({
        isBot: this.isBot,
        city: state?.geolocation?.city ?? null,
        country: state?.geolocation?.country ?? null,
        fingerprint: state?.fingerprint ?? null,
        state: state?.geolocation?.region ?? null,
      });
    } else {
      this.builder.setState(state);
    }

    return this.builder.build();
  }

  composeEngagement(state: State, data: RTSEngagementData): any {
    this.builder.reset();
    this.builder.setState(state);

    if (data.engagement) {
      this.builder.setEngagement(data.engagement);
    }

    if (data.engagementKey) {
      this.builder.setEngagementKey(data.engagementKey);
    }

    if (data.engagementOwner) {
      this.builder.setEngagementOwner(data.engagementOwner);
    }

    return this.builder.build();
  }

  composeStandard(state: State): any {
    this.builder.reset();

    if (!this.isBlockedSent) {
      this.builder.setBlocked(this.isBlocked);
      this.storage.setBlockedSent();
    }

    this.builder.setState(state);

    return this.builder.build();
  }
}
