import { isArray, isEmpty } from "../../../../../../../service/lang";

import Offer from "../../../../../model/offer";
import SegmentSpecification from "../segment_specification";

export default class SegmentIncluded extends SegmentSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const base: string = this.getOriginlessHref() ?? "";

    if (!isEmpty(base)) {
      const segments: string[] = this.getSegmentsData(candidate);

      if (isArray(segments) && !isEmpty(segments)) {
        isSatisfied = segments.some((s) => {
          const matchMakeModel1 = s.replace(/\s+/g, "-");
          const pattern1: RegExp = new RegExp(matchMakeModel1, "i");

          const matchMakeModel2 = s.replace(/\s+/g, "_");
          const pattern2: RegExp = new RegExp(matchMakeModel2, "i");

          const matchMakeModel3 = s.replace(/\s+/g, "\\+");
          const pattern3: RegExp = new RegExp(matchMakeModel3, "i");

          const matchMakeModel4 = s.replace(/\s+/g, "%20");
          const pattern4: RegExp = new RegExp(matchMakeModel4, "i");

          const pattern: RegExp = new RegExp(s, "i");
          return (
            pattern1.test(base) ||
            pattern2.test(base) ||
            pattern3.test(base) ||
            pattern4.test(base) ||
            pattern.test(base)
          );
        });
      } else {
        isSatisfied = true;
      }
    } else {
      isSatisfied = false;
    }

    this.log(candidate, SegmentIncluded, isSatisfied);

    return isSatisfied;
  }
}
