export default class SpecificationError extends Error {
  private constructor(message: string) {
    super(message);
  }

  private static _build(message: string): SpecificationError {
    return new SpecificationError(message);
  }

  static criteriaMethodNotImplemented(): SpecificationError {
    return this._build('Is satisfied method not implemented!');
  }

  static logNotImplemented(): SpecificationError {
    return this._build('Log method not implemented!');
  }

  static noLoggerProvided(): SpecificationError {
    return this._build('No logger provided!');
  }
}
