import { BaseInteractionState, PopupState } from "../../index";

import Offer from "../../../../../model/offer";

export abstract class BasePopup extends BaseInteractionState implements PopupState {
  showPopup(offer: Offer): void {
    const content = this.service.composePopup(offer);
    this.service.pop(content);
  }
}
