import Interactions from "../interactions";
import ModalBuilder from "../modal_builder";
import { ModalHTML } from "../../../asset/html/modal";

export default class BaseModalBuilder implements ModalBuilder {
  private _source: string = '';

  build(): string {
    return this._source;
  }

  reset(): void {
    this._source = ModalHTML;
  }

  setBase(update: string): void {
    this._source = update;
  }

  setContent(update: string): void {
    this._source = this._source.replace(Interactions.ModalPlaceholders.content, update);
  }

  setZIndex(update: number | string): void {
    this._source = this._source.replace(Interactions.ModalPlaceholders.zIndex, `${update}`);
  }
}
