import { BaseChat, ChatState } from "../../index";

import { ChatOptions } from "./typedef";

export class ChatSuspended extends BaseChat implements ChatState {
  /**
   * @override
   */
  accept(): void {
    this._explain();
  }

  /**
   * @override
   */
  askPermission(_engagement: ChatOptions): void {
    this._explain();
  }

  /**
   * @override
   */
  continue(_engagement: ChatOptions): void {
    this._explain();
  }

  /**
   * @override
   */
  closeNotify(): void {
    this._explain();
  }

  /**
   * @override
   */
  deny(): void {
    this._explain();
  }

  /**
   * @override
   */
  destroy(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('ChatSuspended: chat functionality is suspended!');
  }

  join(_engagement: ChatOptions): Promise<void> {
    this._explain();
    throw new Error();
  }
}
