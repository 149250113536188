import Interactions from "../interactions";
import { PrimaryBannerHTML } from "../../../asset/html/primary_banner";
import PrimaryBannerBuilder from "../primary_banner_builder";

export default class BasePrimaryBannerBuilder implements PrimaryBannerBuilder {
  private _source = PrimaryBannerHTML;

  build(): string {
    return this._source;
  }

  reset(): void {
    this._source = PrimaryBannerHTML;
  }

  setOfferId(update: string): void {
    this._source = this._source.replace(
      Interactions.PullupPlaceholders.offerId,
      update
    );
  }

  setStyle(update: string): void {
    const cleaned = update.replace(/[\n\t\r]/gm, "").replace(/\s\s+/gm, " ");
    const wrapped = `<style>${cleaned}</style>`;
    this._source = wrapped + this._source;
  }

  setTemplate(update: string): void {
    this._source = this._source.replace(
      Interactions.PullupPlaceholders.template,
      update
    );
  }
}
