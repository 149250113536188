import DealershipError from "../dealership_error";
import DealershipService from "../dealership_service";

declare global {
  interface Window {
    myo_pigv?: { dealership_id?: string };
    pure_influencer?: { dealership_id?: string };
  }
}

export default class BaseDealershipService implements DealershipService {
  findDealershipId(): string {
    const dealershipId = window?.myo_pigv?.dealership_id || window?.pure_influencer?.dealership_id

    if (!dealershipId) {
      throw DealershipError.noDealershipId();
    }

    return dealershipId;
  }
}