import {ChatOptions} from "./typedef";
import InteractionState from "../interaction_state";

export interface ChatState extends InteractionState {
  accept(): void;

  askPermission(engagement: ChatOptions): void;

  closeNotify(): void;

  continue(engagament: ChatOptions): void;

  deny(): void;

  destroy(): void;

  join(engagament: ChatOptions): Promise<void>;

  sendMessage(message: string): Promise<void>;

  sendInfoMessage(message: string): void;
}
