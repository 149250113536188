import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class VinIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.ignoreVins ? [] : source?.includeVins;
  }
}
