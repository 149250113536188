import Asset from "../asset";
import { isObject } from "../../../../service/lang";

export class BaseAsset implements Asset {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get objectId(): string | undefined {
    return this._json?.[BaseAssetKeys.objectId];
  }
}

export class BaseAssetKeys {
  static objectId: string = 'objectId';
}
