import { BaseChat, ChatRunning, ChatState, DialogSuspended, OfferAccepting, PopupSuspended, PullupSuspended } from "../../index";

import { ChatOptions } from "./typedef";

export class ChatSession extends BaseChat implements ChatState {
  /**
   * @override
   */
  accept(): void {
    this._explain();
  }

  /**
   * @override
   */
  askPermission(): void {
    this._explain();
  }

  /**
   * @override
   */
  continue(engagament: ChatOptions): void {
    super.continue(engagament);
    this.switchChatState(ChatRunning);
    this.switchDialogState(DialogSuspended);
    this.switchOfferState(OfferAccepting);
    this.switchPopupState(PopupSuspended);
    this.switchPullupState(PullupSuspended);
  }

  /**
   * @override
   */
  closeNotify(): void {
    this._explain();
  }

  /**
   * @override
   */
  deny(): void {
    this._explain();
  }

  /**
   * @override
   */
  destroy(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('ChatSession: there is an active chat session!');
  }

  /**
   * @override
   */
  join(_engagement: ChatOptions): Promise<void> {
    this._explain();
    throw new Error();
  }
}
