import { isArray, isUndefined } from "../../../../service/lang";

import AppRunner from "../app_runner";
import BaseDOMService from "../../service/dom/base/base_dom_service";
import BaseInteractionManager from "../../manager/interaction/base/base_interaction_manager";
import BaseLogger from "../../../../service/logger/base/base_logger";
import BaseRequest from "../../../../service/http/base/base_request";
import BaseResourceManager from "../../manager/resource/base/base_resource_manager";
import BaseStateManager from "../../manager/state/base/base_state_manager";
import BaseStatusService from "../../service/status/base/base_status_service";
import { ChatNetworkListener } from "../../network/listener/chat_network_listener";
import DOMService from "../../service/dom/dom_service";
import Events from "../../enum/events";
import Factory from "../../../../service/factory";
import InteractionManager from "../../manager/interaction/interaction_manager";
import Logger from "../../../../service/logger/logger";
import { NetworkListener } from "../../../../service/http/network_listener";
import ResourceManager from "../../manager/resource/resource_manager";
import StateManager from "../../manager/state/state_manager";
import StatusService from "../../service/status/status_service";
import { attachWindowListener } from "../../../../service/dom/dom_utilities";

export default class BaseRunner implements AppRunner {
  private _dom: DOMService | undefined;
  private _interaction: InteractionManager | undefined;
  private _logger: Logger | undefined;
  private _resource: ResourceManager | undefined;
  private _state: StateManager | undefined;
  private _status: StatusService | undefined;

  constructor() {
    this._registerNetworkListeners(this.networkListeners);
  }

  protected get dom(): DOMService {
    if (isUndefined(this._dom)) {
      this._dom = Factory.instance.build(BaseDOMService);
    }

    return this._dom;
  }

  protected get interaction(): InteractionManager {
    if (isUndefined(this._interaction)) {
      this._interaction = Factory.instance.build(BaseInteractionManager);
    }

    return this._interaction;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get networkListeners(): NetworkListener[] {
    return [Factory.instance.build(ChatNetworkListener)];
  }

  protected get resource(): ResourceManager {
    if (isUndefined(this._resource)) {
      this._resource = Factory.instance.build(BaseResourceManager);
    }

    return this._resource;
  }

  protected get state(): StateManager {
    if (isUndefined(this._state)) {
      this._state = Factory.instance.build(BaseStateManager);
    }

    return this._state;
  }

  protected get status(): StatusService {
    if (isUndefined(this._status)) {
      this._status = Factory.instance.build(BaseStatusService);
    }

    return this._status;
  }

  protected beforeInteraction(): void { }

  protected configureManagers(): void {
    /**
     * Subscribe intereaction manager to state events.
     */
    this.state.subscribe(this.interaction, [
      Events.engagementReceived,
      Events.persistentChatThresholdReached
    ]);
  }

  private _registerNetworkListeners(listeners: NetworkListener[]): void {
    if (isArray(listeners)) {
      this.logger.info('Registering network listeners:', listeners);
      listeners.forEach(listener => {
        const callback = (event: any) => listener.handle(event.detail);
        attachWindowListener(BaseRequest.eventsKey, callback);
      });
    }
  }

  public run(): Promise<void> {
    this.logger.info("Run")
    return new Promise((resolve) => {
      this.status.startScript();
      this.configureManagers();
      Promise.all([
        this.resource.initialize(),
        this.state.initialize()
      ])
        .then(() => {
          this.beforeInteraction();
          this.interaction.initialize();
          resolve();
        })
        .catch(error => {
          this.logger.error(error);
          resolve();
        });
    });
  }
}
