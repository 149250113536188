import { MachineSuspended } from "../index";

export class MachinePaused extends MachineSuspended {
  /**
   * @override
   */
  get message(): string {
    return 'Will not execute operation, dealership is paused!';
  }
}
