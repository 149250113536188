import { ChatOptions } from "../typedef";
import { ChatTypes } from "../../../../../../enum/chat_types";

export default class PersistentChatOptionsAdapter implements ChatOptions {
  readonly apiKey: string;
  readonly channelId: string;
  readonly historyId: number;
  readonly type: ChatTypes = ChatTypes.persistent;
  readonly user?: {
    readonly avatar?: string,
    readonly nickname?: string,
  };
  readonly visitorId: string;

  constructor(source: any) {
    this.apiKey = source?.apiKey;
    this.channelId = source?.channelId;
    this.historyId = source?.chatHistoryId;
    this.user = {
      avatar: source?.avatar,
      nickname: source?.nickname,
    };
    this.visitorId = source?.visitorId;
  }
}
