import AppConfig from "../../app.config";
import Validator from "./validator";

export default class PhoneValidator implements Validator {
  private readonly _pattern = new RegExp(/^(\()?[2-9]{1}\d{2}(\)) (-|\s)?\d{3}(-|\s)\d{4}$/);

  error(name: string): string {
    return AppConfig.instance.locale.validationPhone(name);
  }

  validate(input: any): boolean {
    return this._pattern.test(input);
  }
}
