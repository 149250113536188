import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";

export default class UniquePageViewsMet extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    const currentUniqueViews: number = this.getUniqueTypeViews();
    const targetUniqueViews: number = candidate?.uniquePageViews ?? 0;
    const isSatisfied: boolean = currentUniqueViews >= targetUniqueViews;

    this.log(candidate, UniquePageViewsMet, isSatisfied);

    return isSatisfied;
  }
}
