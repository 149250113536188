import { isUndefined } from "../../../../../../../service/lang";
import Offer from "../../../../../model/offer";
import StateSpecification from "../../../state/state_specification";


export default class RankIncluded extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(source: Offer): boolean {
    const isSatisfied: boolean = !isUndefined(source?.rank);
    return isSatisfied;
  }
}