import { isArray, isEmpty, isString, isUndefined } from "../../../../../service/lang";

import BaseDeviceService from "../../../service/device/base/base_device_service";
import DeviceService from "../../../service/device/device_service";
import Factory from "../../../../../service/factory";
import Offer from "../../../model/offer";
import ZIPSpecification from "./zip_specification";

export default class ZIPMobileExcluded extends ZIPSpecification {
  private _device: DeviceService | undefined;

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  isMobile(): boolean {
    return this.device.isMobile();
  }

  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const zip: string | undefined = this.getCurrentZIP();

    if (isString(zip) && !isEmpty(zip) && this.isMobile()) {
      const excluded: string[] = candidate?.excludeZIPsMobile;

      if (isArray(excluded) && !isEmpty(excluded)) {
        isSatisfied = excluded.includes(zip);
      } else {
        isSatisfied = false;
      }
    } else {
      isSatisfied = false;
    }

    this.log(candidate, ZIPMobileExcluded, isSatisfied);

    return isSatisfied;
  }

  setDeviceService(update: DeviceService): void {
    this._device = update;
  }
}