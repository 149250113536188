import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import Factory from "../../../../../service/factory";
import OfferSpecification from "../offer_specification";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { isUndefined } from "../../../../../service/lang";

export default class ZIPSpecification extends OfferSpecification {
  private _storage: StorageRegistrar | undefined;

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  getCurrentZIP(): string | undefined {
    return this.storage.getGeolocation()?.zip;
  }

  setStorage(update: StorageRegistrar): void {
    this._storage = update;
  }
}