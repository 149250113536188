import AppConfig from "../../../app.config";
import Interactions from "../interactions";
import PopupBuilder from "../popup_builder";

export default class BasePopupBuilder implements PopupBuilder {
  private _source: string = '';

  build(): string {
    return this._source;
  }

  reset(): void {
    this._source = '';
  }

  setImage(update: string): void {
    const pattern = new RegExp(Interactions.PopupPlaceholders.image, 'gm');
    const src = `${AppConfig.instance.urlAssets}/${update}`;
    this.setPattern(pattern, src);
  }

  setName(update: string): void {
    const pattern = new RegExp(Interactions.PopupPlaceholders.offerName, 'gm');
    this.setPattern(pattern, update);
  }

  protected setPattern(pattern: RegExp, update: string): void {
    this._source = this._source.replace(pattern, update);
  }

  setStyle(update: string): void {
    const cleaned = update.replace(/[\n\t\r]/gm, '').replace(/\s\s+/gm, ' ');
    const wrapped = `<style>${cleaned}</style>`;
    this._source = wrapped + this._source;
  }

  setTemplate(update: string): void {
    this._source = update;
  }

  setTermsAndConditions(update: string): void {
    const pattern = new RegExp(`${Interactions.PopupKeys.termsAndConsReplace}[^<]+`, 'igm');
    const match = this._source.match(pattern);
    const fragment = match?.[0] ?? '';
    const replace = fragment + update;
    this.setPattern(pattern, replace);
  }
}