import Offer from "../../../../../model/offer";
import SegmentExcluded from "./segment_excluded";

export default class ModelExcluded extends SegmentExcluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.excludeModels;
  }
}
