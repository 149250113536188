import { BaseInteractionMachine } from "../index";
import { ChatOptions } from "../states/chat/typedef";
import Offer from "../../../../model/offer";
import OfferEngagement from "../../../../model/offer_engagement";

export abstract class MachineSuspended extends BaseInteractionMachine {
  abstract get message(): string;

  /**
   * @override
   */
  acceptChat(): void {
    this.explain();
  }

  /**
   * @override
   */
  askChatPermission(_engagement: ChatOptions): void {
    this.explain();
  }

  /**
   * @override
   */
  close(): void {
    this.explain();
  }

  /**
   * @override
   */
  closeChatNotify(): void {
    this.explain();
  }

  /**
   * @override
   */
  closeTerms(): void {
    this.explain();
  }

  /**
   * @override
   */
  continueChat(_engagement: ChatOptions): void {
    this.explain();
  }

  /**
   * @override
   */
  denyChat(): void {
    this.explain();
  }

  /**
   * @pverride
   */
  destroyChat(): void {
    this.explain();
  }

  protected explain(): void {
    this.logger.warning(this.message);
  }

  /**
   * @override
   */
  formatPhone(): void {
    this.explain();
  }

  /**
   * @override
   */
  openPullup(_offer: Offer): void {
    this.explain();
  }

  /**
   * @override
   */
  openTerms(): void {
    this.explain();
  }

  /**
   * @override
   */
  showOffer(_engagement: OfferEngagement): void {
    this.explain();
  }

  /**
   * @override
   */
  showPopup(_offer: Offer): void {
    this.explain();
  }

  /**
   * @override
   */
  showPullup(_offer: Offer): void {
    this.explain();
  }

  /**
   * @override
   */
  showScheduled(_offer: Offer): void {
    this.explain();
  }

  /**
   * @override
   */
  submitForm(): void {
    this.explain();
  }
}
