import Offer from "../../model/offer";
import OfferSpecification from "./offer_specification";

export default class EngagementOffer extends OfferSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    const isSatisfied = candidate?.useEngagementScore === true;

    return isSatisfied;
  }
}
