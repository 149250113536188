import { isUndefined, throwError } from "../../../../../service/lang";

import BaseDeviceService from "../../device/base/base_device_service";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseStorageService from "../../../../../service/storage/base/base_storage_service";
import BaseURLService from "../../url/base/base_url_service";
import DeviceService from "../../device/device_service";
import Factory from "../../../../../service/factory";
import Logger from "../../../../../service/logger/logger";
import StatusService from "../status_service";
import StorageService from "../../../../../service/storage/storage_service";
import URLService from "../../url/url_service";

export default class BaseStatusService implements StatusService {
  private static readonly _debugLabel: string = "_pi_debug";
  private _device: DeviceService | undefined;
  private _logger: Logger | undefined;
  private _storage: StorageService | undefined;
  private _url: URLService | undefined;

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  protected get isDebugMode(): boolean {
    return this.url.URLContains(BaseStatusService._debugLabel);
  }

  protected get isRunning(): boolean {
    return window.__pi_rts_is_script_running ?? false;
  }

  protected set isRunning(update: boolean) {
    window.__pi_rts_is_script_running = update;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get storage(): StorageService {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageService);
    }

    return this._storage;
  }

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  startScript(): void {
    if (this.isDebugMode) {
      if (!this.logger.isEnabled) {
        this.logger.enable();
        this.logger.warning("DEBUG MODE: logger turned on!");
      }
    }

    /**
     * We should not collect data for bots.
     */
    if (this.device.isBot()) {
      this.logger.error("Bot visitor, not skipping.");
      // throwError();
    }

    /**
     * The script cannot work properly in environment where the storage is not
     * enabled.
     */
    if (!this.storage.isEnabled) {
      this.logger.error("Storage is disabled, skipping.");
      throwError();
    }

    /**
     * Prevent multiple scripts running on the same page.
     */
    if (this.isRunning) {
      this.logger.error("Client Script is already running, skipping.");
      throwError();
    }

    this.isRunning = true;
  }
}
