import {
  composedPathHasClass,
  composedPathHasId,
  elementHasId,
  findBySelector,
  hasClass,
  isInDOM,
} from "../../../../service/dom/dom_utilities";

import BaseDOMService from "../../service/dom/base/base_dom_service";
import DOMService from "../../service/dom/dom_service";
import Factory from "../../../../service/factory";
import { isUndefined } from "../../../../service/lang";

namespace Interactions {
  class Helper {
    private static _dom: DOMService | undefined;

    private static get dom(): DOMService {
      if (isUndefined(this._dom)) {
        this._dom = Factory.instance.build(BaseDOMService);
      }

      return this._dom;
    }

    static getControl(selector: string): any {
      return findBySelector(selector);
    }

    static getPlaceholder(selector: string): string {
      return this.dom.getPlaceholder(selector);
    }

    static hasControl(selector: string): boolean {
      return isInDOM(selector);
    }

    static hasId(element: any, id: string): boolean {
      return elementHasId(element, id);
    }

    static pathHasClass(event: any, className: string): boolean {
      const elements = event.composedPath();
      return elements.some((e: any) => hasClass(e, className));
    }
  }

  export class ChatPermissionKeys {
    static readonly acceptButton = "rts-chat-permission-question-button";
    static readonly denyButton = "rts-chat-permission-close-svg";
    static readonly fadeOut = "rts-chat-fade-out";
    static readonly notifyCloseButton = "rts-chat-notify-close-svg";
    static readonly notifyContainer = "rts-chat-notify-container";
    static readonly permissionContainer = "rts-chat-permission-container";
    static readonly permissionContainerStyle = "_pi_rts_chat_permission_style";
    static readonly permissionModal = "rts-chat-permission-modal";
    static readonly push = "rts-chat-push";
  }

  export class ChatPermissionSelectors {
    static readonly acceptButton = `#${ChatPermissionKeys.acceptButton}`;
    static readonly denyButton = `#${ChatPermissionKeys.denyButton}`;
    static readonly notifyCloseButton = `#${ChatPermissionKeys.notifyCloseButton}`;
    static readonly notifyContainer = `#${ChatPermissionKeys.notifyContainer}`;
    static readonly permissionContainer = `#${ChatPermissionKeys.permissionContainer}`;
    static readonly permissionContainerStyle = `#${ChatPermissionKeys.permissionContainerStyle}`;
    static readonly permissionModal = `#${ChatPermissionKeys.permissionModal}`;
  }

  export class ModalKeys {
    static readonly container = "_pi_rts_modal_container";
  }

  export class ModalPlaceholders {
    static readonly content = "{{content}}";
    static readonly zIndex = "{{zIndex}}";
  }

  export class ModalSelectors {
    static readonly container = `#${ModalKeys.container}`;
  }

  export class PopupKeys {
    static readonly closeButton = "pi_pop_up_close_button";
    static readonly container = "_pi_popup_container";
    static readonly content = "pi_pop_up_content";
    static readonly controlsContainer = "pi_pop_up_input_fields";
    static readonly disclaimer = "pi_disclaimer";
    static readonly emailControl = "pi_pop_up_email_control";
    static readonly error = "pi_pop_up_errors_text";
    static readonly firstNameControl = "pi_pop_up_first_name_control";
    static readonly form = "pi_pop_up_form";
    static readonly lastNameControl = "pi_pop_up_last_name_control";
    static readonly phoneControl = "pi_pop_up_phone_control";
    static readonly submitButton = "pi_pop_up_submit_button";
    static readonly success = "pi_pop_up_success_text";
    static readonly termsAndConsClose =
      "pi_pop_up_terms_and_conditions_close_button";
    static readonly termsAndConsLink = "pi_pop_up_terms_and_conditions_link";
    static readonly termsAndConsReplace = "pi_terms_and_conditions_replace";
    static readonly termsAndConsText = "pi_pop_up_terms_and_conditions_text";
    static readonly zipControl = "pi_pop_up_zip_control";
  }

  export class PopupPlaceholders {
    static readonly image = "{{pi_pop_up_bg}}";
    static readonly offerName = "{{offerName}}";
  }

  export class PopupSelectors {
    static readonly closeButton = `.${PopupKeys.closeButton}`;
    static readonly container = `#${PopupKeys.container}`;
    static readonly content = `.${PopupKeys.content}`;
    static readonly crmControl = `.${PopupKeys.controlsContainer} select[name="crmEmail"]`;
    static readonly adfControl = `.${PopupKeys.controlsContainer} select[name="adfDetails"]`;
    static readonly disclaimer = `#${PopupKeys.disclaimer}`;
    static readonly emailControl = `.${PopupKeys.emailControl}`;
    static readonly error = `.${PopupKeys.error}`;
    static readonly firstNameControl = `.${PopupKeys.firstNameControl}`;
    static readonly form = `.${PopupKeys.form}`;
    static readonly lastNameControl = `.${PopupKeys.lastNameControl}`;
    static readonly phoneControl = `.${PopupKeys.phoneControl}`;
    static readonly submitButton = `.${PopupKeys.submitButton}`;
    static readonly success = `.${PopupKeys.success}`;
    static readonly termsAndConsClose = `.${PopupKeys.termsAndConsClose}`;
    static readonly termsAndConsLink = `.${PopupKeys.termsAndConsLink}`;
    static readonly termsAndConsReplace = `.${PopupKeys.termsAndConsReplace}`;
    static readonly termsAndConsText = `.${PopupKeys.termsAndConsText}`;
    static readonly zipControl = `.${PopupKeys.zipControl}`;
  }

  export class PullupKeys {
    static readonly container = "pi-pullup-container";
    static readonly item = "_pi_rts_pullup_item";
    static readonly offerIdDataKey = "offerId";
  }

  export class PrimaryBannerKeys {
    static readonly item = "_pi_rts_primary_banner_item";
  }

  export class PullupPlaceholders {
    static readonly offerId = "{{offerId}}";
    static readonly template = "{{template}}";
  }

  export class PullupSelectors {
    static readonly container = `.${PullupKeys.container}`;
  }

  export class StyleKeys {
    static readonly buttonLoading = "_pi_rts_button_loading";
    static readonly container = "_pi_rts_style_container";
    static readonly disabled = "_pi_rts_disabled";
  }

  export class StyleSelectors {
    static readonly buttonLoading = `.${StyleKeys.buttonLoading}`;
    static readonly container = `#${StyleKeys.container}`;
    static readonly disabled = `.${StyleKeys.disabled}`;
  }

  export function getCRMPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.crmControl);
  }

  export function getADFDetailsPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.adfControl);
  }

  export function getDisclaimer(): any {
    return Helper.getControl(PopupSelectors.disclaimer);
  }

  export function getEmailPlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.emailControl);
  }

  export function getFirstNamePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.firstNameControl);
  }

  export function getLastNamePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.lastNameControl);
  }

  export function getPhonePlaceholder(): string {
    return Helper.getPlaceholder(PopupSelectors.phoneControl);
  }

  export function hasCRMControl(): boolean {
    return Helper.hasControl(PopupSelectors.crmControl);
  }

  export function hasADFDetailsControl(): boolean {
    return Helper.hasControl(PopupSelectors.adfControl);
  }

  export function hasDisclaimer(): boolean {
    return Helper.hasControl(PopupSelectors.disclaimer);
  }

  export function hasEmailControl(): boolean {
    return Helper.hasControl(PopupSelectors.emailControl);
  }

  export function hasFirstNameControl(): boolean {
    return Helper.hasControl(PopupSelectors.firstNameControl);
  }

  export function hasLastNameControl(): boolean {
    return Helper.hasControl(PopupSelectors.lastNameControl);
  }

  export function hasPhoneControl(): boolean {
    return Helper.hasControl(PopupSelectors.phoneControl);
  }

  export function hasZIPControl(): boolean {
    return Helper.hasControl(PopupSelectors.zipControl);
  }

  export function isAcceptChat(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.acceptButton);
  }

  export function isClickPullup(event: any): boolean {
    return composedPathHasClass(event, PullupKeys.item);
  }

  export function isClickPrimaryBanner(event: any): boolean {
    return composedPathHasClass(event, PrimaryBannerKeys.item);
  }

  export function isClosePopup(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.closeButton);
  }

  export function isCloseTermsAndConditions(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.termsAndConsClose);
  }

  export function isDenyChat(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.denyButton);
  }

  export function isNotifyCloseButton(event: any): boolean {
    return composedPathHasId(event, ChatPermissionKeys.notifyCloseButton);
  }

  export function isOpenTermsAndConditions(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.termsAndConsLink);
  }

  export function isPhoneInput(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.phoneControl);
  }

  export function isSubmitForm(event: any): boolean {
    return composedPathHasClass(event, PopupKeys.submitButton);
  }

  export function isSupported(event: any): boolean {
    return (
      isAcceptChat(event) ||
      isClickPullup(event) ||
      isClickPrimaryBanner(event) ||
      isClosePopup(event) ||
      isCloseTermsAndConditions(event) ||
      isDenyChat(event) ||
      isNotifyCloseButton(event) ||
      isOpenTermsAndConditions(event) ||
      isPhoneInput(event) ||
      isSubmitForm(event)
    );
  }
}

export default Interactions;
