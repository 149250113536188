import { isArray, isEmpty, isString } from "../../../../../service/lang";

import Offer from "../../../model/offer";
import ZIPSpecification from "./zip_specification";

export default class ZIPIncluded extends ZIPSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const zip: string | undefined = this.getCurrentZIP();

    if (isString(zip) && !isEmpty(zip)) {
      const included: string[] = candidate?.includeZIPs;

      if (isArray(included) && !isEmpty(included)) {
        isSatisfied = included.includes(zip);
      } else {
        isSatisfied = true;
      }
    } else {
      isSatisfied = true;
    }

    this.log(candidate, ZIPIncluded, isSatisfied);

    return isSatisfied ?? false;
  }
}
