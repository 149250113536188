import AppConfig from "../../app.config";
import Validator from "./validator";
import { isString } from "../../../../service/lang";

export default class LengthValidator implements Validator {
  private _length: number;

  constructor(length: number) {
    this._length = length;
  }

  protected get length(): number {
    return this._length;
  }

  error(name: string): string {
    return AppConfig.instance.locale.validationLength(name, length);
  }

  validate(input: any): boolean {
    return isString(input) && input.length <= this.length;
  }
}
