import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class MakeIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.ignoreMakes ? [] : source?.includeMakes;
  }
}
