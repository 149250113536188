export default class PageClass {
  static readonly contact: string = 'Contact';
  static readonly finance: string = 'Finance';
  static readonly home = 'Home';
  static readonly inventory: string = 'Inventory';
  static readonly newSearch: string = 'NewSearch';
  static readonly other: string = 'Other';
  static readonly service: string = 'Service';
  static readonly specials: string = 'Specials';
  static readonly usedSearch: string = 'UsedSearch';

  private static _PATTERN_NEW: RegExp = new RegExp('new', 'i');
  private static _PATTERN_USED: RegExp = new RegExp('used', 'i');
  private static _PATTERN_SERVICE: RegExp = new RegExp('service', 'i');

  static isGeneral(value: any): boolean {
    return !this.isNew(value) && !this.isService(value) && !this.isUsed(value);
  }

  static isNew(value: any): boolean {
    return PageClass._PATTERN_NEW.test(value) ?? false;
  }

  static isService(value: any): boolean {
    return PageClass._PATTERN_SERVICE.test(value) ?? false;
  }

  static isUsed(value: any): boolean {
    return PageClass._PATTERN_USED.test(value) ?? false;
  }
}