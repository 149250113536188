import { BasePullup, ChatAccepting, DialogTestPullup, PopupSuspended, PullupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PullupTest extends BasePullup implements PullupState {
  /**
   * @override
   */
  openPullup(offer: Offer): void {
    super.openPullup(offer);
    this.stateBuilder.setPullupClicked(true);
    this.switchChatState(ChatAccepting);
    this.switchDialogState(DialogTestPullup);
    this.switchPopupState(PopupSuspended);
  }

  /**
   * @override
   */
  showPullup(offer: Offer): void {
    super.showPullup(offer);
    this.stateBuilder.setOfferId(offer.externalRefId);
    this.stateBuilder.setPullupShown(true);
  }
}
