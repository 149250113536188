import BackendService from "../../../service/backend/backend_service";
import BaseBackendService from "../../../service/backend/base/base_backend_service";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import Factory from "../../../../../service/factory";
import Logger from "../../../../../service/logger/logger";
import MasterConfig from "../../../model/master_config";
import ResourceManager from "../resource_manager";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import { isUndefined } from "../../../../../service/lang";

export default class BaseResourceManager implements ResourceManager {
  private _backend: BackendService | undefined;
  private _logger: Logger | undefined;
  private _storage: StorageRegistrar | undefined;

  protected get backend(): BackendService {
    if (isUndefined(this._backend)) {
      this._backend = Factory.instance.build(BaseBackendService);
    }

    return this._backend;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadConfiguration()
        .then((_result) => resolve())
        .catch((error) => reject(error));
    });
  }

  loadConfiguration(force?: boolean, visitorId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (force) {
        this.loadData(visitorId)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        return;
      }

      const deactivated = this.storage.getDealershipDeactivated();
      const current: MasterConfig | undefined = this.storage.getMasterConfig();

      if (isUndefined(current) && !deactivated) {
        this.storage.setDealershipTs(Math.round(new Date().getTime() / 1000));
        this.loadData()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        return;
      }

      resolve();
    });
  }

  private loadData(visitorId?: string): Promise<void> {
    return new Promise((resolve) => {
      this.backend
        .getMasterConfiguration(visitorId)
        .then((result) => {
          this.storage.setMasterConfig(result);
          resolve();
        })
        .catch((error) => {
          this.logger.error(error);
          resolve();
        });
    });
  }
}
