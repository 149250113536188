import AppConfig from "../../../app.config";
import BackendService from "../../backend/backend_service";
import BaseBackendService from "../../backend/base/base_backend_service";
import BaseGoogleAnalytics from "../../google/analytics/base/base_google_analytics";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseRTSService from "../../rts/base/base_rts_service";
import BaseStateBuilder from "../../../state/base/base_state_builder";
import Factory from "../../../../../service/factory";
import GoogleAnalytics from "../../google/analytics/google_analytics";
import Logger from "../../../../../service/logger/logger";
import Notifier from "../notifier";
import RTSDataComposer from "../../rts/rts_data_composer";
import RTSEngagementData from "../../rts/rts_engagement_data";
import RTSService from "../../rts/rts_service";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import { isUndefined } from "../../../../../service/lang";

export default class BaseNotifier implements Notifier {
  private _backend: BackendService | undefined;
  private _composer: RTSDataComposer | undefined;
  private _googleAnalytics: GoogleAnalytics | undefined;
  private _logger: Logger | undefined;
  private _rts: RTSService | undefined;
  private _stateBuilder: StateBuilder | undefined;

  protected get backend(): BackendService {
    if (isUndefined(this._backend)) {
      this._backend = Factory.instance.build(BaseBackendService);
    }

    return this._backend;
  }

  protected get composer(): RTSDataComposer {
    if (isUndefined(this._composer)) {
      this._composer = Factory.instance.build(RTSDataComposer);
    }

    return this._composer;
  }

  protected get googleAnalytics(): GoogleAnalytics {
    if (isUndefined(this._googleAnalytics)) {
      this._googleAnalytics = Factory.instance.build(BaseGoogleAnalytics);
    }

    return this._googleAnalytics;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get rts(): RTSService {
    if (isUndefined(this._rts)) {
      this._rts = Factory.instance.build(BaseRTSService);
    }

    return this._rts;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  protected get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  acknowledgeEngagement(): void {
    this.logger.info("Acknowledgeing engagement...");
    const data = this.composer.composeAcknowledgement(this.state);
    this.rts.sendData(data);
  }

  notifyDeliveredChat(id: any): void {
    this.logger.info("Notifying delivered chat engagement:", id);
    this.backend.ping(AppConfig.instance.urlChatDelivered(id));
  }

  notifyDeliveredOffer(id: any): void {
    this.logger.info("Notifying delivered offer engagement:", id);
    this.backend.ping(AppConfig.instance.urlOfferDelivered(id));
  }

  notifyDeniedChat(id: any): void {
    this.logger.info("Notifying denied chat engagement:", id);
    this.backend.ping(AppConfig.instance.urlChatRejected(id));
  }

  notifyEngagementChatAccepted(args: { id: any; isBot: boolean }): void {
    this.logger.info("Notifying engagement chat accepted:", args);
    this.backend.notifyChatEngagementAccept({
      chatHistoryId: args.id,
      isBot: args.isBot,
      vehicleType: this.state.customItems?.type ?? null,
    });
  }

  notifyGoogleAnalyticsLead(): void {
    this.logger.info("Submitting Google Analytics lead...");
    this.googleAnalytics.sendLead();
  }

  notifyGoogleAnalyticsMitsubishiLead(lead: any): void {
    this.logger.info("Submitting Google Analytics lead for Mitsubishi...");
    this.googleAnalytics.sendMitsubishiLead(lead);
  }

  notifyPersistentChatActivity(id: number): void {
    this.logger.info("Notifying chat activity:", id);
    this.backend.notifyPersistentChatActivity({
      botOrgPaid: this.state.botOrgPaid ?? "",
      chatHistoryId: id,
      dealershipRefId: this.state.dealershipId ?? "",
      engagementScore: this.state.visitorScore,
      make: this.state.customItems?.make ?? "",
      model: this.state.customItems?.model ?? "",
      sessionTime: this.state.timeSpent,
      startedInUrl: this.state.url ?? "",
      visitorRefId: this.state.visitorId ?? "",
      visitorSession: this.state.sessionId ?? "",
      year: this.state.customItems?.year ?? "",
    });
  }

  notifyRejectedOffer(id: any): void {
    this.logger.info("Notifying rejected offer engagement:", id);
    this.backend.ping(AppConfig.instance.urlOfferRejected(id));
  }

  notifyEngagement(data: RTSEngagementData): void {
    this.logger.info("Notifying engagement:", data);
    const request = this.composer.composeEngagement(this.state, data);
    this.rts.sendData(request);
  }
}
