import Factory from "../../../../../service/factory";
import {
  isArray,
  isEmpty,
  isString,
  isUndefined,
} from "../../../../../service/lang";

import Offer from "../../../model/offer";
import ZipRanges from "../../../model/zip_ranges";
import BaseDeviceService from "../../../service/device/base/base_device_service";
import DeviceService from "../../../service/device/device_service";
import ZIPSpecification from "./zip_specification";

export default class ZIPRangeMobileExcluded extends ZIPSpecification {
  private _device: DeviceService | undefined;

  protected get device(): DeviceService {
    if (isUndefined(this._device)) {
      this._device = Factory.instance.build(BaseDeviceService);
    }

    return this._device;
  }

  isMobile(): boolean {
    return this.device.isMobile();
  }
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    const zip: string | undefined = this.getCurrentZIP();
    if (!isString(zip) || isEmpty(zip) || !this.isMobile()) {
      this.log(candidate, ZIPRangeMobileExcluded, false);
      return false;
    }

    const excluded: ZipRanges[] | undefined = candidate?.excludeZIPsMobileRange;
    if (!isArray(excluded) || isEmpty(excluded)) {
      this.log(candidate, ZIPRangeMobileExcluded, false);
      return false;
    }

    const isExcluded = excluded.some(
      (zipExcluded: ZipRanges) =>
        Number(zip.replace(/\s/g, "")) >= Number(zipExcluded?.start) &&
        Number(zip.replace(/\s/g, "")) <= Number(zipExcluded?.end)
    );

    this.log(candidate, ZIPRangeMobileExcluded, isExcluded);
    return isExcluded;
  }
}
