// @ts-nocheck

import BaseDuration from "../../service/duration/base/base_duration";
import Duration from "../../service/duration/duration";
import Locale from "./locale/i18n/en_US";
import LocaleSchema from "./locale/locale_schema";
import TimeUnits from "../../service/time_units";
import { isUndefined } from "../../service/lang";

export default class AppConfig {
  private static _instance: AppConfig;

  private constructor() {}

  static get instance(): AppConfig {
    if (isUndefined(this._instance)) {
      this._instance = new AppConfig();
    }

    return this._instance;
  }

  private readonly urlBaseAsset: string = "https://pureinfluencer-assets-develop.s3.amazonaws.com";
  private readonly urlBaseLib: string = "https://demodealer.dev.idrove.it";
  private readonly urlBasePI: string = "https://dev.idrove.it";
  private readonly urlBaseRTS: string = "https://dev.idrove.it/rts";

  /**
   * The interval after which the chat animation notification container will
   * automatically close.
   */
  readonly chatAnimationCloseDuration: Duration = new BaseDuration(
    2,
    TimeUnits.seconds
  );

  /**
   * The default avatar that will be shown in the chat widget.
   */
  readonly chatAvatarURL: string =
    "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/7_avatar-512.png";

  /**
   * The timeout after which the no response ping will be send to the backend
   * when chatting with bot.
   */
  readonly chatTimeoutNoResponse: Duration = new BaseDuration(
    10,
    TimeUnits.seconds
  );

  /**
   * Default chat bot user title.
   */
  readonly chatbotTitle = "Chat Bot";

  /**
   * The default nickname for the chat widget.
   */
  readonly chatDefaultNickname: string = "";

  /**
   * The default user title for the chat widget.
   */
  readonly chatDefaultUserTitle: string = "";

  /**
   * The interval for sending data to RTS service.
   */
  readonly dataCollectionInterval: Duration = new BaseDuration(
    3,
    TimeUnits.seconds
  );

  /**
   * The default threshold for persistent chat.
   */
  readonly defaultPersistentChatThreshold: number = 3;

  /**
   * The default length of the IDs that are generated natively in the client
   * script.
   */
  readonly idsLength: number = 20;

  /**
   * Maximum length of the input fields for validation.
   */
  readonly inputMaxLength: number = 54;

  /**
   * Locale object that could be overriden if necessary.
   */
  readonly locale: LocaleSchema = new Locale();

  /**
   * The duration after which the shown offer or popup will automatically
   * close on submit.
   */
  readonly offerCloseTimeout: Duration = new BaseDuration(
    1500,
    TimeUnits.milliseconds
  );

  /**
   * Security token for the request to RTS.
   */
  readonly rtsSectok: string = "6a55156a-1699-4175-97a1-34ce326fa4f6";

  /**
   * The default session duration.
   */
  readonly sessionDuration: Duration = new BaseDuration(30, TimeUnits.minutes);

  /**
   * The interval of session time incrementation; since the RTS accepts the
   * session time value in seconds, it should be in seconds.
   */
  readonly sessionTimeIncrementInterval: Duration = new BaseDuration(
    1,
    TimeUnits.seconds
  );

  /**
   * URLs
   */
  readonly urlAssets: stirng = `${this.urlBaseAsset}`;
  readonly urlChatbotNoResponse: string = `${this.urlBasePI}/api/v1/public/chatbot/no-response`;

  urlChatDelivered(id: any): string {
    return `${this.urlChatLog(id)}/status-delivered`;
  }

  readonly urlChatEnded: string = `${this.urlBasePI}/api/v1/public/chat/end`;
  readonly urlChatEngagementAccept = `${this.urlBasePI}/api/v1/public/chat/engagement/accept`;

  protected urlChatLog(id: any): string {
    return `${this.urlBasePI}/api/v1/public/chat-log/${id}`;
  }

  urlChatRejected(id: any): string {
    return `${this.urlChatLog(id)}/status-rejected`;
  }

  readonly urlChatWidget: string = `${this.urlBaseLib}/cs/library/chatWidget.min.js`;
  readonly urlChatWidgetCalls: string = `${this.urlBaseLib}/cs/library/chatWidgetCalls.min.js`;
  readonly urlChatWidgetText: string = `${this.urlBaseLib}/cs/library/chatWidgetText.min.js`;

  urlDealershipConfig(id: any): string {
    return `${this.urlBasePI}/api/v1/public/dealership-info/${id}`;
  }

  urlVisitorInfo(visitorExtRefId: any): string {
    return `${this.urlBasePI}/api/v1/public/visitor-info/${visitorExtRefId}`;
  }

  readonly urlFingerprint: string = `${this.urlBaseLib}/cs/library/fp.min.js`;
  readonly urlGeolocation: string =
    "https://pro.ip-api.com/json?key=d7MEgV5HQJbkgGw";

  urlOfferDelivered(id: any): string {
    return `${this.urlOfferLog(id)}/status-delivered`;
  }

  protected urlOfferLog(id: any): string {
    return `${this.urlBasePI}/api/v1/public/offer-log/${id}`;
  }

  urlOfferRejected(id: any): string {
    return `${this.urlOfferLog(id)}/status-rejected`;
  }

  readonly urlPersistentChatActivity: string = `${this.urlBasePI}/api/v1/public/chat/persistent/activity`;
  readonly urlPersistentChatEnded: string = `${this.urlBasePI}/api/v1/public/chat/persistent/end`;
  readonly urlPersistentChatMessage: string = `${this.urlBasePI}/api/v1/public/chat/persistent/message`;
  readonly urlPersistentChatShown: string = `${this.urlBasePI}/api/v1/public/chat/persistent/open`;
  readonly urlPersistentChatWidget: string = `${this.urlBaseLib}/cs/library/persistent.chat.min.js`;
  readonly urlPILead: string = `${this.urlBasePI}/api/v1/public/lead/submit`;
  readonly urlRTSLead: string = `${this.urlBaseRTS}/lead`;
  readonly urlRTSPayload: string = `${this.urlBaseRTS}/payload`;

  /**
   * The default duration for which the visitor will be perceived as converted.
   * This will suspend all interactions.
   */
  readonly visitorConversionDuration = new BaseDuration(120, TimeUnits.days);

  /**
   * The expiry duration of the visitor ID.
   */
  readonly visitorIdDuration: Duration = new BaseDuration(5000, TimeUnits.days);

  /**
   * Default z-index for the modals that are used for the interactions.
   */
  readonly zIndex = 2147483647;
}
