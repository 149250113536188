import {ChatJoinArgs, ChatWidgetConfig} from "../typedef";

import BaseLibrary from "../../../../../../../service/library/base/base_library";
import BaseLogger from "../../../../../../../../../service/logger/base/base_logger";
import ChatWidget from "../chat_widget";
import ChatWidgetError from "../chat_widget_error";
import Factory from "../../../../../../../../../service/factory";
import Library from "../../../../../../../service/library/library";
import Logger from "../../../../../../../../../service/logger/logger";
import {isUndefined} from "../../../../../../../../../service/lang";

declare let _pi_lib: any;

export default class BaseChatWidget implements ChatWidget {
  private _config: ChatWidgetConfig | undefined;
  private _library: Library | undefined;
  private _logger: Logger | undefined;
  private _widget: any;

  protected get config(): ChatWidgetConfig {
    if (isUndefined(this._config)) {
      throw ChatWidgetError.notConfigured();
    }

    return this._config;
  }

  protected get library(): Library {
    if (isUndefined(this._library)) {
      this._library = Factory.instance.build(BaseLibrary);
    }

    return this._library;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get widget(): any {
    if (isUndefined(this._widget)) {
      throw ChatWidgetError.notInitialized();
    }

    return this._widget;
  }

  destroy(): void {
    this.widget.destroy();
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.library
        .loadChatWidget()
        .then(() => {
          if (isUndefined(this._widget)) {
            this._widget = new _pi_lib.ChatWidget(this.config);

            try {
              this._widget.initialize(() => resolve());
            } catch (_error) {
              resolve();
            }
          } else {
            this.logger.warning('Chat widget is already initialized!');
            resolve();
          }
        })
        .catch(error => reject(error));
    });
  }

  join(args: ChatJoinArgs): Promise<void> {
    return this.widget.join(args);
  }

  open(): void {
    try {
      this.widget.open();
    } catch (_error) {
    }
  }

  sendMessage(message: string, customType: string): Promise<void> {
    return this.widget.sendMessage(message, customType);
  }

  setConfig(update: ChatWidgetConfig): void {
    this._config = update;
  }
}
