import Factory from "../../../../../../service/factory";
import { InteractionMachine } from "../index";
import InteractionManager from "../../interaction_manager";
import InteractionState from "./interaction_state";

export default class InteractionStateFactory {
  static build(
    type: { new(): InteractionState },
    context: InteractionManager,
    machine: InteractionMachine
  ): InteractionState {
    const state = Factory.instance.build(type);
    state.setContext(context);
    state.setMachine(machine);
    return state;
  }
}
