import { isArray, isEmpty, isString } from "../../../../../service/lang";

import URLSpecification from "./url_specification";

export default class URLIncluded extends URLSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: any): boolean {
    let isSatisfied: boolean;
    const path: string | undefined = this.getCurrentPath();

    if (isString(path)) {
      const included: string[] = candidate?.includeURLs;

      if (isArray(included)) {
        const paths: string[] = this.url.URLsToPaths(included);

        if (isEmpty(paths)) {
          isSatisfied = true;
        } else {
          isSatisfied = paths.includes(path);
        }
      } else {
        isSatisfied = true;
      }
    } else {
      isSatisfied = true;
    }

    this.log(candidate, URLIncluded, isSatisfied);

    return isSatisfied;
  }
}