import { isArray, isEmpty, isString } from "../../../../../service/lang";

import Offer from "../../../model/offer";
import ZIPSpecification from "./zip_specification";

export default class ZIPExcluded extends ZIPSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const zip: string | undefined = this.getCurrentZIP();

    if (isString(zip) && !isEmpty(zip)) {
      const excluded: string[] = candidate?.excludeZIPs;

      if (isArray(excluded) && !isEmpty(excluded)) {
        isSatisfied = excluded.includes(zip);
      } else {
        isSatisfied = false;
      }
    } else {
      isSatisfied = false;
    }

    this.log(candidate, ZIPExcluded, isSatisfied);

    return isSatisfied;
  }
}
