import { isNumber, isString, isUndefined } from "../../../../../service/lang";

import AppConfig from "../../../app.config";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import BaseObservable from "../../../../../service/observer/base/base_observable";
import BaseStateBuilder from "../../../state/base/base_state_builder";
import BaseStateService from "./base_state_service";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import Events from "../../../enum/events";
import Factory from "../../../../../service/factory";
import Logger from "../../../../../service/logger/logger";
import Observable from "../../../../../service/observer/observable";
import RTSResponse from "../../../service/rts/rts_response";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import StateManager from "../state_manager";
import StateService from "../state_service";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import UnimplementedError from "../../../utilities/errors/unimplemented_error";
import HTTPService from "../../../../../service/http/http_service";
import BaseHTTPService from "../../../../../service/http/base/base_http_service";

export default class BaseStateManager
  extends BaseObservable
  implements StateManager
{
  private _builder: StateBuilder | undefined;
  private _logger: Logger | undefined;
  private _sessionTimeInterval: NodeJS.Timer | undefined;
  private _storage: StorageRegistrar | undefined;
  protected _service: StateService | undefined;
  private _http: HTTPService | undefined;

  protected get http(): HTTPService {
    if (isUndefined(this._http)) {
      this._http = Factory.instance.build(BaseHTTPService);
    }

    return this._http;
  }

  protected get builder(): StateBuilder {
    if (isUndefined(this._builder)) {
      this._builder = Factory.instance.build(BaseStateBuilder);
    }

    return this._builder;
  }

  protected get collectionInterval(): number {
    return AppConfig.instance.dataCollectionInterval.toMilliseconds();
  }

  get isActive(): boolean {
    return (
      isString(this.state.sessionId) &&
      (this.service.isVisitorActive() || this.storage.hasChatSession())
    );
  }

  get customItems(): object | undefined {
    return this.state.customItems;
  }

  setCustomItems(update?: object) {
    this.state.setCustomItems(update);
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get service(): StateService {
    if (isUndefined(this._service)) {
      this._service = Factory.instance.build(BaseStateService);
    }

    return this._service;
  }

  protected get state(): State {
    return this.builder.snapshot;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  protected checkPersistentChatState(response: RTSResponse): void {
    if (isNumber(response.eScore)) {
      if (this.service.hasReachedPersistentChatThreshold(response.eScore)) {
        this.notify(Events.persistentChatThresholdReached);
      }
    }
  }

  protected handleConversionStatus(response: RTSResponse): void {
    const isNotConverted = !this.service.isVisitorConverted;
    const hasLeads = response?.hasLeads === true;

    if (isNotConverted && hasLeads) {
      this.service.setVisitorConverted();
    }
  }

  handleInitialRTSResponse(response: RTSResponse): void {
    this.setStateData(response);
    this.checkPersistentChatState(response);
  }

  handleSmartPixlRequest(response: RTSResponse): void {
    const visitorStatus = response.status;
    const config = this.storage.getMasterConfig();
    const pixlID = config?.dealership?.pixlId;
    const m1Enabled = config?.dealership?.m1Enabled;
    const visitorId = response?.visitorID;

    if (m1Enabled && visitorId && pixlID) {
      // SmartPiXL API call
      this.http
        .get({
          url: `https://smart-pixl.com/12730/${pixlID}_SMART.GIF?ref=${visitorId}${encodeURIComponent(
            window?.location?.href
          )}`,
        })
        .then((response) => {
          this.logger.info("SmartPiXL data sent!");
        });
    }

    // this.storage.setVisitorInfo(JSON.parse(JSON.stringify(obj.data)));
    if (m1Enabled && visitorId && visitorStatus === "returning") {
      //and returning status
      this.http
        .post({
          url: AppConfig.instance.urlVisitorInfo(visitorId),
        })
        .then((response) => {
          if (response.data)
            this.storage.setVisitorInfo(
              JSON.parse(JSON.stringify(response.data))
            );
          //this response visitor info to compare with demographicsFilter in offers
          this.logger.info("Visitor info from SmartPiXL!");
        });
    }
  }

  handleRTSError(error: any): void {
    if (!isUndefined(error)) {
      this.logger.warning(error);
    }
  }

  handleRTSReponse(response: RTSResponse): void {
    if (!isUndefined(response?.pendingEngagement)) {
      this.notify(Events.engagementReceived, response.pendingEngagement);
    }
  }

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      const exit = () => {
        this.initializeScrollListener();
        this.initializeSessionTimeIncrementer();
        this.initializeDataCollection()
          .then(() => resolve())
          .catch((error) => {
            this.logger.error("Error initializing data collection:", error);
            resolve();
          });
      };

      this.initializeState()
        .then(() => {
          exit();
        })
        .catch((error) => {
          this.logger.error("Error initializing state:", error);
          exit();
        });
    });
  }

  protected initializeDataCollection(): Promise<void> {
    return new Promise((resolve) => {
      const exit = (): void => {
        this.sendSnapshot();
        resolve();
      };

      this.sendInitial()
        .then((response) => {
          this.handleConversionStatus(response);
          this.handleInitialRTSResponse(response);
          this.handleSmartPixlRequest(response);
          exit();
        })
        .catch((error) => {
          this.logger.error("Error sending initial data:", error);
          exit();
        });
    });
  }

  initializeState(): Promise<void> {
    return new Promise((resolve) => {
      this.builder.reset();
      this.builder
        .initialize()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          this.logger.error("Error initializing state builder:", error);
          resolve();
        });
    });
  }

  protected initializeScrollListener(): void {
    this.service.attachScrollListener(
      Factory.instance.callback(this.onScroll, this)
    );
  }

  protected initializeSessionTimeIncrementer(): void {
    if (!isUndefined(this._sessionTimeInterval)) {
      clearInterval(this._sessionTimeInterval);
    }

    const duration = AppConfig.instance.sessionTimeIncrementInterval;
    const interval = duration.toMilliseconds();
    const seconds = interval / 1000;
    const callback = () =>
      this.isActive && this.builder.incrementTimeSpent(seconds);
    this._sessionTimeInterval = setInterval(callback, interval);
  }

  protected onScroll(_event: Event): void {
    const max = this.builder.snapshot.scrollDepth ?? 0;
    const current = this.service.getScrollDepth();

    if (current > max) {
      this.builder.setScrollDepth(current);
    }
  }

  protected sendInitial(): Promise<RTSResponse> {
    const data = this.service.composeInitialData(this.state);
    //we call this twice because once it may come from state and other from response
    this.handleSmartPixlRequest(data);
    this.logger.info("Sending initial request:", data);
    return this.service.sendRTSData(data, true);
  }

  protected sendSchedule(): NodeJS.Timeout {
    const callback = this.sendSnapshot.bind(this);
    const timeout = this.collectionInterval;
    return setTimeout(callback, timeout);
  }

  protected sendSnapshot(): void {
    if (this.isActive) {
      const data = this.service.composeStandardData(this.state);

      this.service
        .sendRTSData(data)
        .then((response) => {
          this.handleRTSReponse(response);
          this.sendSchedule();
        })
        .catch((error) => {
          this.handleRTSError(error);
          this.sendSchedule();
        });
    } else {
      this.sendSchedule();
    }
  }

  setBuilder(update: StateBuilder): void {
    this._builder = update;
  }

  protected setStateData(response: RTSResponse): void {
    this.builder.setFromJSON(response);
  }

  update(_observable: Observable, event: Events, _data: any): void {
    switch (event) {
      default:
        throw new UnimplementedError(event);
    }
  }
}
