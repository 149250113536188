import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";

export default class StaticScoreReached extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const usesEngagementScore = candidate?.useEngagementScore;

    if (usesEngagementScore) {
      const usesStaticThreshold = candidate?.hasStaticEngagementThreshold;

      if (usesStaticThreshold) {
        const visitorScore: number = this.getVisitorScore();

        if (visitorScore === 0) {
          isSatisfied = true;
        } else {
          const threshold: number = candidate?.engagementThreshold ?? 0;
          isSatisfied = visitorScore >= threshold;
        }
      } else {
        isSatisfied = false;
      }
    } else {
      isSatisfied = false;
    }

    return isSatisfied;
  }
}