import BaseModalBuilder from "../base/base_modal_builder";
import Factory from "../../../../../service/factory";
import ModalBuilder from "../modal_builder";
import { isUndefined } from "../../../../../service/lang";

export default class ModalComposer {
  private _builder: ModalBuilder | undefined;

  protected get builder(): ModalBuilder {
    if (isUndefined(this._builder)) {
      this._builder = Factory.instance.build(BaseModalBuilder);
    }

    return this._builder;
  }

  composeSimpleModal(content: string, zIndex: number | string): string {
    this.builder.reset();
    this.builder.setContent(content);
    this.builder.setZIndex(zIndex);

    return this.builder.build();
  }
}
