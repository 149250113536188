import AppConfig from "../../../app.config";
import BaseLoaderService from "../../../../../service/loader/base/base_loader_service";
import BaseOptionsService from "../../options/base/base_options_service";
import BaseStorageRegistrar from "../../storage/base/base_storage_registrar";
import ChatModes from "../../../enum/chat_modes";
import Factory from "../../../../../service/factory";
import Library from "../library";
import LoaderService from "../../../../../service/loader/loader_service";
import OptionsService from "../../options/options_service";
import StorageRegistrar from "../../storage/storage_registrar";
import { isUndefined } from "../../../../../service/lang";

export default class BaseLibrary implements Library {
  private _loader: LoaderService | undefined;
  private _options: OptionsService | undefined;
  private _storage: StorageRegistrar | undefined;

  protected get loader(): LoaderService {
    if (isUndefined(this._loader)) {
      this._loader = Factory.instance.build(BaseLoaderService);
    }

    return this._loader;
  }

  protected get options(): OptionsService {
    if (isUndefined(this._options)) {
      this._options = Factory.instance.build(BaseOptionsService);
    }

    return this._options;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  loadChatWidget(): Promise<void> {
    let src: string;

    switch (this.options.chatMode) {
      case ChatModes.calls:
        src = AppConfig.instance.urlChatWidgetCalls;
        break;
      case ChatModes.text:
        src = AppConfig.instance.urlChatWidgetText;
        break;
      default:
        throw new Error(`Chat mode is disabled or not implemented: ${this.options.chatMode}!`);
    }

    return this.loader.script(src);
  }

  loadFingerprint(): Promise<void> {
    return this.loader.script(AppConfig.instance.urlFingerprint);
  }

  loadPersistentChat(): Promise<void> {
    return this.loader.script(AppConfig.instance.urlPersistentChatWidget);
  }
}
