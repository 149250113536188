export default class EngagementCodes {
  static readonly AIChatAccepted = 'AIChatAccepted';
  static readonly AIChatIgnored = 'AIChatIgnored';
  static readonly chatAccepted = 'ChatAccepted';
  static readonly chatIgnored = 'ChatIgnored';
  static readonly offerAccepted = 'OfferAccepted';
  static readonly offerIgnored = 'OfferIgnored';
  static readonly popupAccepted = 'PopupAccepted';
  static readonly popupIgnored = 'PopupIgnored';
}
