import { BaseInteractionState, OfferState } from "../../index";

import OfferEngagement from "../../../../../model/offer_engagement";

export abstract class BaseOffer extends BaseInteractionState implements OfferState {
  showOffer(engagement: OfferEngagement): void {
    const content = this.service.composeOffer(engagement);
    this.service.pop(content);
  }
}
