import {
  isEmpty,
  isNull,
  isUndefined,
  maxBy,
  sample,
  sampleRank,
} from "../../../../../service/lang";

import BaseOffersRepository from "../../../repository/offer/base/base_offers_repository";
import DeviceTypeMet from "../state/device_type_met";
import DynamicScoreReached from "../state/dynamic_score_reached";
import EngagementMatched from "../state/engagement_matched";
import EngagementOffer from "../engagement_offer";
import Factory from "../../../../../service/factory";
import MakeExcluded from "../url/segment/excluded/make_excluded";
import VinExcluded from "../url/segment/excluded/vin_excluded";
import MakeIncluded from "../url/segment/included/make_included";
import VinIncluded from "../url/segment/included/vin_included";
import TypeIncluded from "../url/segment/included/type_included";
import ModelExcluded from "../url/segment/excluded/model_excluded";
import ModelIncluded from "../url/segment/included/model_included";
import Offer from "../../../model/offer";
import OfferActive from "../offer_active";
import OfferPicker from "../offer_picker";
import OfferPickerLog from "../offer_picker_log";
import OfferTypeMatched from "../state/offer_type_matched";
import OffersRepository from "../../../repository/offer/offers_repository";
import PageClassMatched from "../state/page_class_matched";
import SessionOffersReached from "../state/session_offers_reached";
import Specification from "../../specification";
import StaticScoreReached from "../state/static_score_reached";
import URLExcluded from "../url/url_excluded";
import URLIncluded from "../url/url_included";
import UniquePageViewsMet from "../state/unique_page_views_met";
import VisitorStatusMet from "../state/visitor_status_met";
import YearIncluded from "../url/segment/included/year_included";
import ZIPExcluded from "../zip/zip_excluded";
import ZIPIncluded from "../zip/zip_included";
import ZIPMobileExcluded from "../zip/zip_mobile_excluded";
import SourcesIncluded from "../url/segment/included/sources_included";
import KnownVisitorMet from "../state/known_visitor_met";
import RankIncluded from "../url/segment/included/rank_included";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import DemographicsFilterMet from "../state/demographics_filter_met";
import EngagementScoreReached from "../state/engagement_score_reached";
import ZIPRangeExcluded from "../zip/zip_range_excluded";
import ZIPRangeMobileExcluded from "../zip/zip_range_mobile_excluded";
import ZIPRangeIncluded from "../zip/zip_range_included";

export default class BaseOfferPicker implements OfferPicker {
  private _storage: StorageRegistrar | undefined;

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  readonly deviceTypeMet = Factory.instance.build(DeviceTypeMet);
  readonly dynamicScoreReached = Factory.instance.build(DynamicScoreReached);
  readonly engagementMatched = Factory.instance.build(EngagementMatched);
  readonly engagementOffer = Factory.instance.build(EngagementOffer);
  readonly makeExcluded = Factory.instance.build(MakeExcluded);
  readonly vinExcluded = Factory.instance.build(VinExcluded);
  readonly makeIncluded = Factory.instance.build(MakeIncluded);
  readonly vinIncluded = Factory.instance.build(VinIncluded);
  readonly typeIncluded = Factory.instance.build(TypeIncluded);
  readonly modelExcluded = Factory.instance.build(ModelExcluded);
  readonly modelIncluded = Factory.instance.build(ModelIncluded);
  readonly offerActive = Factory.instance.build(OfferActive);
  readonly offerTypeMatched = Factory.instance.build(OfferTypeMatched);
  readonly pageClassMatched = Factory.instance.build(PageClassMatched);
  readonly sessionOffersReached = Factory.instance.build(SessionOffersReached);
  readonly staticScoreReached = Factory.instance.build(StaticScoreReached);
  readonly uniquePageViewsMet = Factory.instance.build(UniquePageViewsMet);
  readonly visitorStatusMet = Factory.instance.build(VisitorStatusMet);
  readonly urlExcluded = Factory.instance.build(URLExcluded);
  readonly urlIncluded = Factory.instance.build(URLIncluded);
  readonly yearIncluded = Factory.instance.build(YearIncluded);
  readonly zipExcluded = Factory.instance.build(ZIPExcluded);
  readonly zipIncluded = Factory.instance.build(ZIPIncluded);
  readonly zipRangeExcluded = Factory.instance.build(ZIPRangeExcluded);
  readonly zipRangeMobileExcluded = Factory.instance.build(
    ZIPRangeMobileExcluded
  );
  readonly zipRangeIncluded = Factory.instance.build(ZIPRangeIncluded);
  readonly zipMobileExcluded = Factory.instance.build(ZIPMobileExcluded);
  readonly semVendorsSegmentsIncluded = Factory.instance.build(SourcesIncluded);
  readonly knownVisitorMet = Factory.instance.build(KnownVisitorMet);
  readonly rankIncluded = Factory.instance.build(RankIncluded);
  readonly demographicsFilterMet = Factory.instance.build(
    DemographicsFilterMet
  );
  readonly engScoreReached = Factory.instance.build(EngagementScoreReached);

  private _log: OfferPickerLog | undefined;
  private _repository: OffersRepository | undefined;

  protected get log(): OfferPickerLog {
    if (isUndefined(this._log)) {
      this._log = Factory.instance.build(OfferPickerLog);
    }

    return this._log;
  }

  get repository(): OffersRepository {
    if (isUndefined(this._repository)) {
      this._repository = Factory.instance.build(BaseOffersRepository);
    }

    return this._repository;
  }

  protected filterBy(source: Offer[], specification: Specification): Offer[] {
    return source.filter((a) => specification.isSatisfiedBy(a));
  }

  getAvailableOffers(): Offer[] {
    return this.repository.getAvailableOffers(
      this.offerActive
        .and(this.urlExcluded.not())
        .and(this.urlIncluded)
        .and(this.zipExcluded.not())
        .and(this.zipIncluded)
        .and(this.zipMobileExcluded.not())
        .and(this.zipRangeExcluded.not())
        .and(this.zipRangeIncluded)
        .and(this.zipRangeMobileExcluded.not())
        .and(this.makeExcluded.not())
        .and(this.vinExcluded.not())
        .and(this.makeIncluded)
        .and(this.vinIncluded)
        .and(this.typeIncluded)
        .and(this.modelExcluded.not())
        .and(this.modelIncluded)
        .and(this.yearIncluded)
        // .and(this.offerTypeMatched)
        .and(this.uniquePageViewsMet)
        .and(this.visitorStatusMet)
        .and(this.deviceTypeMet)
        .and(this.sessionOffersReached.not())
        .and(this.engagementMatched)
        .and(this.semVendorsSegmentsIncluded)
        .and(this.knownVisitorMet)
        .and(this.demographicsFilterMet)
        .and(this.engScoreReached.not())
    );
  }

  pickSpecificOffer(): Offer | undefined {
    this.log.reset();

    const available = this.getAvailableOffers();

    this.log.print();

    const availableWithOfferType = this.filterBy(
      available,
      this.offerTypeMatched
    );

    const VSPfiltration = availableWithOfferType.filter(
      (offer) => offer.offerName && offer.offerName.includes("VSP")
    );

    return sampleRank(VSPfiltration);
  }

  pickOffer(): Offer | undefined {
    this.log.reset();

    const available = this.getAvailableOffers();

    this.log.print();

    const matchedPageStatic = this.filterBy(
      available,
      this.pageClassMatched
        .and(this.staticScoreReached)
        .and(this.offerTypeMatched)
    );
    const matchedPageDynamic = this.filterBy(
      available,
      this.pageClassMatched
        .and(this.dynamicScoreReached)
        .and(this.offerTypeMatched)
    );
    const matchedPage = this.filterBy(
      available,
      this.pageClassMatched
        .andNot(this.engagementOffer)
        .and(this.offerTypeMatched)
    );
    const matchedStaticThreshold = this.filterBy(
      available,
      this.staticScoreReached
        .andNot(this.pageClassMatched)
        .and(this.offerTypeMatched)
    );
    const matchedDynamicThreshold = this.filterBy(
      available,
      this.dynamicScoreReached
        .andNot(this.pageClassMatched)
        .and(this.offerTypeMatched)
    );
    const rest = this.filterBy(
      available,
      this.pageClassMatched
        .not()
        .andNot(this.engagementOffer)
        .and(this.offerTypeMatched)
    );
    const ranking = this.filterBy(available, this.rankIncluded);

    const availableWithOfferType = this.filterBy(
      available,
      this.offerTypeMatched
    );
    const demographicsFiltration = availableWithOfferType.filter(
      (a) =>
        !isNull(a.hasDemographicsFilter) &&
        !isUndefined(a.hasDemographicsFilter)
    );

    let source: Offer[];

    /**
     * Offers picking has the following precendence:
     *
     * 1. an offer that's matching the page class and the static threshold;
     * 2. an offer that's matching the page class and the dynamic threshold;
     * 3. an offer that's matching the page class;
     * 4. general offer with static threshold;
     * 5. general offer with dynamic threshold;
     * 6. any offer that does not violate the filtering constraints.
     *
     * For the cases with static threshold offers—if more than one offer is
     * matching the conditions, we pick the one with the higher threshold.
     */
    if (!isEmpty(demographicsFiltration)) {
      source = demographicsFiltration;
    } else if (!isEmpty(ranking)) {
      source = ranking;
    } else if (!isEmpty(matchedPageStatic)) {
      source = [maxBy(matchedPageStatic, (p: Offer) => p?.engagementThreshold)];
    } else if (!isEmpty(matchedPageDynamic)) {
      source = matchedPageDynamic;
    } else if (!isEmpty(matchedPage)) {
      source = matchedPage;
    } else if (!isEmpty(matchedStaticThreshold)) {
      source = [
        maxBy(matchedStaticThreshold, (p: Offer) => p?.engagementThreshold),
      ];
    } else if (!isEmpty(matchedDynamicThreshold)) {
      source = matchedDynamicThreshold;
    } else {
      source = rest;
    }

    /**
     * We pick a random offer from the available.
     */
    return sampleRank(source);
  }
}
