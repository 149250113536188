import Offer from "../../../model/offer";
import OfferTypes from "../../../enum/offer_types";
import PageClass from "../../../enum/page_class";
import StateSpecification from "./state_specification";

export default class OfferTypeMatched extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const offerType: OfferTypes = candidate?.offerType;
    const pageClass: string | undefined = this.getPageClass();

    if (offerType === OfferTypes.new) {
      isSatisfied = PageClass.isNew(pageClass);
    } else if (offerType === OfferTypes.service) {
      isSatisfied = PageClass.isService(pageClass);
    } else if (offerType === OfferTypes.used) {
      isSatisfied = PageClass.isUsed(pageClass);
    } else {
      isSatisfied = true;
    }

    this.log(candidate, OfferTypeMatched, isSatisfied);

    return isSatisfied;
  }
}
