import {
  parseBooleanDemographics,
  parseNumber,
  parseString,
} from "../../../../service/lang";

import VisitorInfo from "../visitor_info";

export class BaseVisitorInfo implements VisitorInfo {
  private _data: any;

  constructor(data: any) {
    this._data = data;
  }

  get visitorRefId(): string | undefined | null {
    return parseString(this._data?.visitorRefId);
  }

  get isMarried(): boolean | undefined | null {
    return parseBooleanDemographics(this._data?.married);
  }

  get gender(): string | undefined | null {
    return parseString(this._data?.gender);
  }

  get hasChildren(): boolean | null | undefined {
    return parseBooleanDemographics(this._data?.hasChildren);
  }

  get numberOfChildren(): number | undefined | null {
    return parseNumber(this._data?.numberOfChildren);
  }

  get homeOwner(): boolean | null | undefined {
    return parseBooleanDemographics(this._data?.homeOwner);
  }

  get yearsInResidence(): number | undefined | null {
    return parseNumber(this._data?.yearsInResidence);
  }

  get autoVin(): string | undefined | null {
    return parseString(this._data?.autoVin);
  }

  get year(): number | undefined | null {
    return parseNumber(this._data?.year);
  }

  get make(): string | undefined | null {
    return parseString(this._data?.make);
  }

  get model(): string | undefined | null {
    return parseString(this._data?.model);
  }

  get firstSeen(): string | undefined | null {
    return parseString(this._data?.firstSeen);
  }

  get lastSeen(): string | undefined | null {
    return parseString(this._data?.lastSeen);
  }

  get age(): number | undefined | null {
    return parseNumber(this._data?.age);
  }

  get income(): number | undefined | null {
    return parseNumber(this._data?.income);
  }

  get education(): string | undefined | null {
    return parseString(this._data?.education);
  }

  get occupation(): string | undefined | null {
    return parseString(this._data?.occupation);
  }

  get businessOwner(): boolean | null | undefined {
    return parseBooleanDemographics(this._data?.businessOwner);
  }

  get language(): string | undefined | null {
    return parseString(this._data?.language);
  }

  get homeValueFrom(): number | undefined | null {
    return parseNumber(this._data?.homeValueFrom);
  }

  get homeValueTo(): number | undefined | null {
    return parseNumber(this._data?.homeValueTo);
  }

  toJSON(): object {
    return this._data;
  }
}
