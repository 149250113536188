import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";
import VisitorStatus from "../../../enum/visitor_status";
import { isUndefined } from "../../../../../service/lang";

export default class KnownVisitorMet extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const visitorStatus: VisitorStatus | undefined = this.getVisitorStatus();
    const offerKnownVisitor = candidate?.knownVisitor;
    if (!offerKnownVisitor) {
      isSatisfied = true;
    } else if (!isUndefined(visitorStatus) && offerKnownVisitor) {
      isSatisfied = visitorStatus === "returning" ? false : true;
    } else {
      isSatisfied = false;
    }

    this.log(candidate, KnownVisitorMet, isSatisfied);

    return isSatisfied;
  }
}
