import { MachineSuspended } from "../index";

export class MachineBlocked extends MachineSuspended {
  /**
   * @override
   */
  get message(): string {
    return 'Will not execute operation, the visitor is blocked!';
  }
}
