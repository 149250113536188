import AppConfig from "../../app.config";
import Validator from "./validator";

export default class EmailValidator implements Validator {
  private readonly _pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  error(name: string): string {
    return AppConfig.instance.locale.validationEmail(name);
  }

  validate(input: any): boolean {
    return this._pattern.test(input);
  }
}
