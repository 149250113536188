import { isObject, parseString } from "../../../../service/lang";

import Template from "../template";

export class BaseTemplate implements Template {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get assetObjectId(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.assetObjectId]);
  }

  get body(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.body]);
  }

  get style(): string | undefined {
    return parseString(this._json?.[BaseTemplateKeys.style]);
  }
}

export class BaseTemplateKeys {
  static assetObjectId: string = 'assetObjectId';
  static body: string = 'body';
  static style: string = 'style';
}
