import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class TypeIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.includeTypes;
  }
}