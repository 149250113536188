import { isDate, isUndefined, parseBoolean, parseDate } from "../../../../service/lang";

import BaseDatetimeService from "../../../../service/datetime/base/base_datetime_service";
import DatetimeService from "../../../../service/datetime/datetime_service";
import Factory from "../../../../service/factory";
import OfferSpecification from "./offer_specification";

export default class OfferActive extends OfferSpecification {
  private _datetime: DatetimeService | undefined;

  protected get datetime(): DatetimeService {
    if (isUndefined(this._datetime)) {
      this._datetime = Factory.instance.build(BaseDatetimeService);
    }

    return this._datetime;
  }

  get timestamp(): number {
    return this.datetime.timestamp;
  }

  /**
   * @override
   */
  isSatisfiedBy(candidate: any): boolean {
    let isSatisfied: boolean;
    const expirable: boolean = parseBoolean(candidate?.expirable);

    try {
      if (expirable) {
        const startDate: Date | undefined = parseDate(candidate?.startDate);
        const endDate: Date | undefined = parseDate(candidate?.endDate);
        const timestamp: number = this.timestamp;

        if (isDate(startDate) && startDate.getTime() > timestamp) {
          throw new Error();
        }

        if (isDate(endDate) && endDate.getTime() < timestamp) {
          throw new Error();
        }

        isSatisfied = true;
      } else {
        isSatisfied = true;
      }
    } catch (error) {
      isSatisfied = false;
    }

    this.log(candidate, OfferActive, isSatisfied);

    return isSatisfied;
  }

  setDatetimeService(update: DatetimeService): void {
    this._datetime = update;
  }
}