import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";

export default class SessionOffersReached extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    const requirement: number = candidate.maxOffersPerSession ?? 0;
    const shownOffersCount: number = this.getShownOffersCount();
    const isSatisfied: boolean = shownOffersCount >= requirement;

    this.log(candidate, SessionOffersReached, isSatisfied);

    return isSatisfied;
  }
}