import { BaseOffer, OfferState } from "../../index";

import OfferEngagement from "../../../../../model/offer_engagement";

export class OfferShown extends BaseOffer implements OfferState {
  /**
   * @override
   */
  showOffer(_engagment: OfferEngagement): void {
    this.logger.warning('OfferShown: offer already shown!');
  }
}
