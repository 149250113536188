import { BasePullup, ChatAccepting, DialogPullup, PopupSuspended, PullupOpened, PullupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PullupShown extends BasePullup implements PullupState {
  protected handleClicked(): void {
    this.stateBuilder.setPullupClicked(true);
  }

  /**
   * @override
   */
  openPullup(offer: Offer): void {
    super.openPullup(offer);
    this.handleClicked();
    this.switchChatState(ChatAccepting);
    this.switchDialogState(DialogPullup);
    this.switchPullupState(PullupOpened);
    this.switchPopupState(PopupSuspended);
  }

  /**
   * @override
   */
  showPullup(_offer: Offer): void {
    this.logger.warning('PullupShown: pullup already shown!');
  }
}
