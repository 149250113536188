import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";
import VisitorStatus from "../../../enum/visitor_status";
import { isNull, isUndefined } from "../../../../../service/lang";
import StorageRegistrar from "../../../service/storage/storage_registrar";
import Factory from "../../../../../service/factory";
import BaseStorageRegistrar from "../../../service/storage/base/base_storage_registrar";
import VisitorInfo from "../../../model/visitor_info";
import DemographicsFilterDto from "../../../model/demographics_filter_dto";

export default class DemographicsFilterMet extends StateSpecification {
  private _storage: StorageRegistrar | undefined;

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const visitorInfo: VisitorInfo | undefined = this.storage.getVisitorInfo();
    const demographicsFilter: DemographicsFilterDto | undefined =
      candidate.demographicsFilter;

    //We use candidate.hasDemographicsFilter for comparison because when we have an offer without demographicsFilter,
    //the demographicsFilter obj won't be null, so we get another extra prop from offer which will be null when no demographics are defined for comparison

    if (
      !isNull(candidate.hasDemographicsFilter) &&
      !isUndefined(candidate.hasDemographicsFilter) &&
      !isNull(visitorInfo) &&
      !isUndefined(visitorInfo) &&
      (visitorInfo.isMarried === demographicsFilter.isMarried ||
        isNull(demographicsFilter.isMarried) ||
        isUndefined(demographicsFilter.isMarried)) &&
      (visitorInfo.gender === demographicsFilter.gender ||
        (isUndefined(visitorInfo.gender) && demographicsFilter.gender === "") ||
        isNull(demographicsFilter.gender) ||
        isUndefined(demographicsFilter.gender)) &&
      (visitorInfo.hasChildren === demographicsFilter.hasChildren ||
        isNull(demographicsFilter.hasChildren) ||
        isUndefined(demographicsFilter.hasChildren)) &&
      (visitorInfo.numberOfChildren === demographicsFilter.numberOfChildren ||
        isNull(demographicsFilter.numberOfChildren) ||
        isUndefined(demographicsFilter.numberOfChildren)) &&
      (visitorInfo.homeOwner === demographicsFilter.homeOwner ||
        isNull(demographicsFilter.homeOwner) ||
        isUndefined(demographicsFilter.homeOwner)) &&
      ((!isNull(visitorInfo.yearsInResidence) &&
        !isUndefined(visitorInfo.yearsInResidence) &&
        !isNull(demographicsFilter.yearsInResidenceFrom) &&
        !isUndefined(demographicsFilter.yearsInResidenceFrom) &&
        !isNull(demographicsFilter.yearsInResidenceTo) &&
        !isUndefined(demographicsFilter.yearsInResidenceTo) &&
        visitorInfo.yearsInResidence >=
          demographicsFilter.yearsInResidenceFrom &&
        visitorInfo.yearsInResidence <=
          demographicsFilter.yearsInResidenceTo) ||
        ((isUndefined(demographicsFilter.yearsInResidenceFrom) ||
          isNull(demographicsFilter.yearsInResidenceFrom)) &&
          (isUndefined(demographicsFilter.yearsInResidenceTo) ||
            isNull(demographicsFilter.yearsInResidenceTo)))) &&
      (visitorInfo.year === demographicsFilter.year ||
        isNull(demographicsFilter.year) ||
        isUndefined(demographicsFilter.year)) &&
      (visitorInfo.make === demographicsFilter.make ||
        (isUndefined(visitorInfo.make) && demographicsFilter.make === "") ||
        isNull(demographicsFilter.make) ||
        isUndefined(demographicsFilter.make)) &&
      (visitorInfo.model === demographicsFilter.model ||
        (isUndefined(visitorInfo.model) && demographicsFilter.model === "") ||
        isNull(demographicsFilter.model) ||
        isUndefined(demographicsFilter.model)) &&
      ((!isNull(visitorInfo.firstSeen) &&
        !isUndefined(visitorInfo.firstSeen) &&
        !isNull(demographicsFilter.firstSeenFrom) &&
        !isUndefined(demographicsFilter.firstSeenFrom) &&
        !isNull(demographicsFilter.firstSeenTo) &&
        !isUndefined(demographicsFilter.firstSeenTo) &&
        visitorInfo.firstSeen >= demographicsFilter.firstSeenFrom &&
        visitorInfo.firstSeen <= demographicsFilter.firstSeenTo) ||
        ((isUndefined(demographicsFilter.firstSeenFrom) ||
          isNull(demographicsFilter.firstSeenFrom)) &&
          (isUndefined(demographicsFilter.firstSeenTo) ||
            isNull(demographicsFilter.firstSeenTo)))) &&
      ((!isNull(visitorInfo.lastSeen) &&
        !isUndefined(visitorInfo.lastSeen) &&
        !isNull(demographicsFilter.lastSeenFrom) &&
        !isUndefined(demographicsFilter.lastSeenFrom) &&
        !isNull(demographicsFilter.lastSeenTo) &&
        !isUndefined(demographicsFilter.lastSeenTo) &&
        visitorInfo.lastSeen >= demographicsFilter.lastSeenFrom &&
        visitorInfo.lastSeen <= demographicsFilter.lastSeenTo) ||
        ((isUndefined(demographicsFilter.lastSeenFrom) ||
          isNull(demographicsFilter.lastSeenFrom)) &&
          (isUndefined(demographicsFilter.lastSeenTo) ||
            isNull(demographicsFilter.lastSeenTo)))) &&
      ((!isNull(visitorInfo.income) &&
        !isUndefined(visitorInfo.income) &&
        !isNull(demographicsFilter.incomeFrom) &&
        !isUndefined(demographicsFilter.incomeFrom) &&
        !isNull(demographicsFilter.incomeTo) &&
        !isUndefined(demographicsFilter.incomeTo) &&
        visitorInfo.income >= demographicsFilter.incomeFrom &&
        visitorInfo.income <= demographicsFilter.incomeTo) ||
        ((isUndefined(demographicsFilter.incomeFrom) ||
          isNull(demographicsFilter.incomeFrom)) &&
          (isUndefined(demographicsFilter.incomeTo) ||
            isNull(demographicsFilter.incomeTo)))) &&
      (visitorInfo.education === demographicsFilter.education ||
        (isUndefined(visitorInfo.education) &&
          demographicsFilter.education === "") ||
        isNull(demographicsFilter.education) ||
        isUndefined(demographicsFilter.education)) &&
      (visitorInfo.occupation === demographicsFilter.occupation ||
        (isUndefined(visitorInfo.occupation) &&
          demographicsFilter.occupation === "") ||
        isNull(demographicsFilter.occupation) ||
        isUndefined(demographicsFilter.occupation)) &&
      (visitorInfo.businessOwner === demographicsFilter.businessOwner ||
        isNull(demographicsFilter.businessOwner) ||
        isUndefined(demographicsFilter.businessOwner)) &&
      (visitorInfo.language === demographicsFilter.language ||
        (isUndefined(visitorInfo.language) &&
          demographicsFilter.language === "") ||
        isNull(demographicsFilter.language) ||
        isUndefined(demographicsFilter.language))
    ) {
      //offer cases with demographicsFilter and visitorInfo defined and matching
      isSatisfied = true;
    } else if (
      (isNull(candidate.hasDemographicsFilter) ||
        isUndefined(candidate.hasDemographicsFilter)) &&
      (visitorInfo || isUndefined(visitorInfo) || isNull(visitorInfo))
    ) {
      //offer cases without demographicsFilter whether there is or no visitorInfo defined
      isSatisfied = true;
    } else {
      //offer cases with demographicsFilter but no matching with visitorInfo
      isSatisfied = false;
    }

    this.log(candidate, DemographicsFilterMet, isSatisfied);

    return isSatisfied;
  }
}
