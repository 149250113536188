import Formatter from "../formatter";

export default class StandardPhoneFormatter implements Formatter {
  format(input: any): string {
    let source = `${input}`;

    // Remove the "+1" or "1" prefix if it exists
    if (source.startsWith("+1") || source.startsWith("(1")) {
      source = source.substring(2);
    } else if (source.startsWith("1")) {
      source = source.substring(1);
    }

    const patternDigit = /\D/g;
    const patternNumber = /(\d{0,3})(\d{0,3})(\d{0,4})/;
    const matches = source.replace(patternDigit, "").match(patternNumber) ?? [];

    return !matches[2]
      ? matches[1]
      : "(" +
          matches[1] +
          ") " +
          matches[2] +
          (matches[3] ? "-" + matches[3] : "");
  }
}
