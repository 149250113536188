import { BasePopup, PopupState } from "../../index";

import Offer from "../../../../../model/offer";

export class PopupSuspended extends BasePopup implements PopupState {
  /**
   * @override
   */
  showPopup(_offer: Offer): void {
    this.logger.warning('PopupSuspended: popup is suspended!');
  }
}
