import ZipRanges from "../zip_ranges";
import { isObject } from "../../../../service/lang";

export class BaseZipRanges implements ZipRanges {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get start(): number | undefined {
    return this._json?.[BaseAssetKeys.start];
  }

  get end(): number | undefined {
    return this._json?.[BaseAssetKeys.end];
  }
}

export class BaseAssetKeys {
  static readonly start: string = "start";
  static readonly end: string = "end";
}
