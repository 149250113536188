import { BaseDialog, DialogState } from "../../index";

export class DialogSuspended extends BaseDialog implements DialogState {
  /**
   * @override
   */
  close(): void {
    this._explain();
  }

  /**
   * @override
   */
  closeTerms(): void {
    this._explain();
  }

  private _explain(): void {
    this.logger.warning('DialogSuspended: Dialog functionality is suspended!');
  }

  /**
   * @override
   */
  formatPhone(_element: any): void {
    this._explain();
  }

  /**
   * @override
   */
  openTerms(): void {
    this._explain();
  }

  /**
   * @override
   */
  submit(): Promise<void> {
    this._explain();
    return Promise.resolve();
  }
}
