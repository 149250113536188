import {BaseInteractionState, ChatState} from "../../index";
import {isString, isUndefined} from "../../../../../../../service/lang";

import AppConfig from "../../../../../app.config";
import BaseOptionsService from "../../../../../service/options/base/base_options_service";
import ChatComposer from "../../../composers/chat_composer";
import {ChatOptions} from "./typedef";
import ChatStateError from "./chat_state_error";
import ChatWidget from "./widget/chat_widget";
import Factory from "../../../../../../../service/factory";
import Interactions from "../../../interactions";
import OptionsService from "../../../../../service/options/options_service";
import {insertBodyContent} from "../../../../../../../service/dom/dom_utilities";
import {MessageTypes} from "./chat_message_types";

export abstract class BaseChat extends BaseInteractionState implements ChatState {
  private _composer: ChatComposer | undefined;
  private _options: OptionsService | undefined;

  protected get composer(): ChatComposer {
    if (isUndefined(this._composer)) {
      this._composer = Factory.instance.build(ChatComposer);
    }

    return this._composer;
  }

  protected get options(): OptionsService {
    if (isUndefined(this._options)) {
      this._options = Factory.instance.build(BaseOptionsService);
    }

    return this._options;
  }

  protected get widget(): ChatWidget {
    return this.machine.chatWidget;
  }

  accept(): void {
    this.transitionPermission();

    if (isUndefined(this.chatEngagement)) {
      throw ChatStateError.undefinedEngagement();
    } else {
      this.start(this.chatEngagement)
    }
  }

  askPermission(engagement: ChatOptions): void {
    const style = engagement?.greeting?.style;
    const template = engagement?.greeting?.template;
    const content = this.composer.composePermission(style, template);
    insertBodyContent(content);
    this.machine.setChatEngagement(engagement);
  }

  closeNotify(): void {
    this.removeContainer();
  }

  continue(engagement: ChatOptions): void {
    this.machine.setChatEngagement(engagement);
    this.start(engagement);
  }

  deny(): void {
    this.removeContainer();
  }

  destroy(): void {
    this.widget.destroy();
  }

  join(engagament: ChatOptions): Promise<void> {
    this.logger.info('Joining chat:', engagament);
    this.machine.setChatEngagement(engagament);
    this.saveChatEngagement();
    return this.start(engagament);
  }

  protected removeContainer(): void {
    this.service.remove(Interactions.ChatPermissionSelectors.permissionModal);
    this.service.remove(Interactions.ChatPermissionSelectors.permissionContainerStyle);
  }

  protected saveChatEngagement(): void {
    if (!isUndefined(this.chatEngagement)) {
      this.storage.setChatSession(this.chatEngagement);
    }
  }

  sendMessage(message: string): Promise<void> {
    this.logger.info('Sending chat message:', message);
    return this.widget.sendMessage(message, MessageTypes.user);
  }

  sendInfoMessage(message: string) {
    this.logger.info('Sending info chat message:', message);
    return this.widget.sendMessage(message, MessageTypes.info);
  }

  protected start(engagement: ChatOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      this.widget.initialize()
        .then(() => {
          if (isString(engagement.channelId)) {
            this.logger.info('isCallsEnabled:', this.options.isCallsEnabled);
            return this.widget.join({
              channelId: engagement.channelId,
              isCallsEnabled: this.options.isCallsEnabled,
            });
          } else {
            throw ChatStateError.invalidChannel(engagement.channelId);
          }
        })
        .then(() => resolve())
        .catch(error => {
          this.logger.error(error);
          this.context.endChat();
          reject(error);
        });
    });
  }

  protected transitionPermission(): void {
    const permission = Interactions.ChatPermissionSelectors.permissionContainer;
    const notification = Interactions.ChatPermissionSelectors.notifyContainer;
    const fadeOut = Interactions.ChatPermissionKeys.fadeOut;
    const push = Interactions.ChatPermissionKeys.push;

    this.service.addClass(permission, fadeOut);
    this.service.addClass(notification, push);

    const callback = () => this.removeContainer();
    const timeout = AppConfig.instance.chatAnimationCloseDuration.toMilliseconds();

    this.service.schedule(callback, timeout);
  }
}
