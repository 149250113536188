import { ChatWidgetConfig, MessageDataGetter, OnChatEnded, OnMessageSent } from "../typedef";

import ChatWidgetConfigBuilder from "../chat_widget_config_builder";
import { VoidCallback } from "../../../../../../../typedef";

export default class BaseChatWidgetConfigBuilder implements ChatWidgetConfigBuilder {
  private _config: ChatWidgetConfig = this._base;

  private get _base(): ChatWidgetConfig {
    return {
      callbacks: {},
      defaults: {},
      getters: {},
      options: {},
      user: {}
    };
  }

  build(): ChatWidgetConfig {
    return this._config;
  }

  reset(): void {
    this._config = this._base;
  }

  setAppId(update: string): void {
    this._config.appId = update;
  }

  setDefaultAvatar(update: string): void {
    this._config.defaults.avatar = update;
  }

  setDefaultNickname(update: string): void {
    this._config.defaults.nickname = update;
  }

  setDefaultUserTitle(update: string): void {
    this._config.defaults.userTitle = update;
  }

  setMessageDataGetter(update: MessageDataGetter): void {
    this._config.getters.messageData = update;
  }

  setOnChatEnded(update: OnChatEnded): void {
    this._config.callbacks.onChatEnded = update;
  }

  setOnChoicesNoResponse(update: VoidCallback): void {
    this._config.callbacks.onChoicesNoResponse = update;
  }

  setOnMessageSent(update: OnMessageSent): void {
    this._config.callbacks.onMessageSent = update;
  }

  setTimeoutChoicesNoResponse(update: number): void {
    this._config.options.timeoutChoicesNoResponse = update;
  }

  setUserAvatar(update: string): void {
    this._config.user.avatar = update;
  }

  setUserId(update: string): void {
    this._config.user.id = update;
  }

  setUserName(update: string): void {
    this._config.user.name = update;
  }
}
