import AppConfig from "../../../app.config";
import BaseStorageRegistrar from "../../storage/base/base_storage_registrar";
import ChatModes from "../../../enum/chat_modes";
import Factory from "../../../../../service/factory";
import MasterConfig from "../../../model/master_config";
import OptionsService from "../options_service";
import PersistentChatOptions from "../../../model/persistent_chat_options";
import StorageRegistrar from "../../storage/storage_registrar";
import {isUndefined} from "../../../../../service/lang";

export default class BaseOptionsService implements OptionsService {
  private _storage: StorageRegistrar | undefined;

  get chatMode(): ChatModes {
    return this.config?.dealership?.chatMode ?? ChatModes.disabled;
  }

  protected get config(): MasterConfig | undefined {
    return this.storage.getMasterConfig();
  }

  get hasPersistentChat(): boolean {
    return !isUndefined(this.config?.dealership?.persistentChatOptions);
  }

  get isCallsEnabled(): boolean {
    return this.chatMode === ChatModes.calls;
  }

  get persistentChatOptions(): PersistentChatOptions {
    const options = this.config?.dealership?.persistentChatOptions;

    if (isUndefined(options)) {
      throw new Error('Persistent chat options are undefined!');
    }

    return options;
  }

  get persistentChatThreshold(): number {
    return this.config?.dealership?.persistentChatOptions?.engagementScore ?? AppConfig.instance.defaultPersistentChatThreshold;
  }

  get zIndex(): number {
    return this.config?.dealership.zIndex || 0;
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }
}
