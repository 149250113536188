import LocaleSchema from "../locale_schema";

export default class en_US implements LocaleSchema {
  defaultErrorMessage: string = 'Something went wrong';
  offerTermsAndCons: string = 'Coupon must be presented up-front prior to negotiations. Cannot be combined with other offers. Internet Offers Only. A Test Drive Appointment and, or Appointment with Sales Management must be scheduled at Time of Offer in order to receive Offer. Offer not valid with previous price quotes. Additional terms on dealer email/certificate apply. Offers applicable only to shoppers with valid Driver’s License and valid auto insurance that have not purchased a vehicle from dealer or competing dealerships or independent sellers within the last 30 days. See dealer for details.';
  validationDisclaimer: string = 'Oops, let’s protect your privacy, please click/select the privacy box below.';

  validationCRM(name: string): string {
    return this.validationRequiredDropdown(name);
  }

  validationEmail(name: string): string {
    return `Invalid ${name}.`;
  }

  validationLength(name: string, length: number): string {
    return `${name} must be between 1 and ${length} characters.`;
  }

  validationPhone(name: string): string {
    return `Invalid ${name}.`;
  }

  validationRequired(name: string): string {
    return `${name} is required.`;
  }

  validationRequiredDropdown(name: string): string {
    return `Please select ${name}`;
  }
}
