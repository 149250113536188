import BaseLogger from "../../../../service/logger/base/base_logger";
import Factory from "../../../../service/factory";
import Logger from "../../../../service/logger/logger";
import { isUndefined } from "../../../../service/lang";

export default class OfferPickerLog {
  private _log: any = {};
  private _logger: Logger | undefined;

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  print(): void {
    this.logger.warning('OfferPickerLog:', this._log);
  }

  reset(): void {
    this._log = {};
  }

  write(candidate: any, specification: any, result: any): void {
    let entry = this._log[candidate] ?? {};
    entry[specification] = result;
    this._log[candidate] = entry;
  }
}
