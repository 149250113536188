import { parseNumber, parseString } from "../../../../service/lang";

import OfferEngagement from "../offer_engagement";

export class BaseOfferEngagement implements OfferEngagement {
  private _data: any;

  constructor(data: any) {
    this._data = data;
  }

  get campaignId(): string | undefined {
    return parseString(this._data?.campaignExtRefId);
  }

  get historyId(): number | undefined {
    return parseNumber(this._data?.offerHistoryId);
  }

  get notificationId(): string | undefined {
    return parseString(this._data?.rtsNotificationExtRefId);
  }

  get source(): string | undefined {
    return parseString(this._data?.htmlBody);
  }

  get style(): string | undefined {
    return parseString(this._data?.htmlStyle);
  }

  get userId(): string | undefined {
    return parseString(this._data?.userRefId);
  }

  toJSON(): object {
    return this._data;
  }
}
