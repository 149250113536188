import BaseStateBuilder from "../../../state/base/base_state_builder";
import DeviceTypes from "../../../enum/device_types";
import Factory from "../../../../../service/factory";
import OfferSpecification from "../offer_specification";
import State from "../../../state/state";
import StateBuilder from "../../../state/state_builder";
import VisitorStatus from "../../../enum/visitor_status";
import { isUndefined } from "../../../../../service/lang";

export default class StateSpecification extends OfferSpecification {
  private _stateBuilder: StateBuilder | undefined;

  private get stateBuilder(): StateBuilder {
    if (isUndefined(this._stateBuilder)) {
      this._stateBuilder = Factory.instance.build(BaseStateBuilder);
    }

    return this._stateBuilder;
  }

  protected get state(): State {
    return this.stateBuilder.snapshot;
  }

  getDealershipScore(): number {
    return this.state.dealershipScore;
  }

  getDeviceType(): DeviceTypes | undefined {
    return this.state.deviceType;
  }

  getPageClass(): string | undefined {
    return this.state.pageClass;
  }

  getPageType(): string | undefined {
    return this.state.pageType;
  }

  getUniqueTypeViews(): number {
    return this.state.pageViews;
  }

  getShownOffersCount(): number {
    return this.state.shownOffersCount;
  }

  getVisitorScore(): number {
    return this.state.visitorScore;
  }

  getVisitorStatus(): VisitorStatus | undefined {
    return this.state.visitorStatus;
  }

  setStateBuilder(update: StateBuilder): void {
    this._stateBuilder = update;
  }
}