import { isString, isUndefined } from "../../../../../service/lang";

import BaseChatPermissionBuilder from "../base/base_chat_permission_builder";
import ChatPermisionBuilder from "../chat_permission_builder";
import Factory from "../../../../../service/factory";

export default class ChatComposer {
  private _permissionBuilder: ChatPermisionBuilder | undefined;

  protected get permissionBuilder(): ChatPermisionBuilder {
    if (isUndefined(this._permissionBuilder)) {
      this._permissionBuilder = Factory.instance.build(BaseChatPermissionBuilder);
    }

    return this._permissionBuilder;
  }

  composePermission(style: any, template: any): string {
    this.permissionBuilder.reset();

    if (!isUndefined(template)) {
      this.permissionBuilder.setTemplate(template);
    }

    if (isString(style)) {
      this.permissionBuilder.setStyle(style);
    }

    return this.permissionBuilder.build();
  }
}
