import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class ModelIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    return source?.ignoreModels ? [] : source?.includeModels;
  }
}
