import Offer from "../../../model/offer";
import OfferTypes from "../../../enum/offer_types";
import PageClass from "../../../enum/page_class";
import StateSpecification from "./state_specification";

export default class PageClassMatched extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const pageClass: string = this.getPageClass() ?? PageClass.other;

    if (PageClass.isNew(pageClass)) {
      isSatisfied = candidate.offerType === OfferTypes.new;
    } else if (PageClass.isService(pageClass)) {
      isSatisfied = candidate.offerType === OfferTypes.service;
    } else if (PageClass.isUsed(pageClass)) {
      isSatisfied = candidate.offerType === OfferTypes.used;
    } else if (candidate.offerType === OfferTypes.general) {
      isSatisfied = true;
    } else {
      isSatisfied = false;
    }

    return isSatisfied;
  }
}