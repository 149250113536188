import { isArray, isEmpty, isString } from "../../../../../service/lang";

import Offer from "../../../model/offer";
import ZipRanges from "../../../model/zip_ranges";
import ZIPSpecification from "./zip_specification";

export default class ZIPRangeExcluded extends ZIPSpecification {
  /**
   * @override
   */

  isSatisfiedBy(candidate: Offer): boolean {
    const zip: string | undefined = this.getCurrentZIP();
    if (!isString(zip) || isEmpty(zip)) {
      this.log(candidate, ZIPRangeExcluded, false);
      return false;
    }

    const excluded: ZipRanges[] | undefined = candidate?.excludeZIPsRange;
    if (!isArray(excluded) || isEmpty(excluded)) {
      this.log(candidate, ZIPRangeExcluded, false);
      return false;
    }

    const isExcluded = excluded.some(
      (zipExcluded: ZipRanges) =>
        Number(zip.replace(/\s/g, "")) >= Number(zipExcluded?.start) &&
        Number(zip.replace(/\s/g, "")) <= Number(zipExcluded?.end)
    );

    this.log(candidate, ZIPRangeExcluded, isExcluded);
    return isExcluded;
  }
}
