export default class ChatWidgetError extends Error {
  private constructor(message: string) {
    super(message);
  }

  private static _build(message: string): ChatWidgetError {
    return new ChatWidgetError(message);
  }

  static notConfigured(): ChatWidgetError {
    return this._build('Chat widget config is not set!');
  }

  static notInitialized(): ChatWidgetError {
    return this._build('Chat widget is not initialized!');
  }
}
