import { BaseOffer, OfferState } from "../../index";

import OfferEngagement from "../../../../../model/offer_engagement";

export class OfferAccepting extends BaseOffer implements OfferState {
  /**
   * @override
   */
  showOffer(engagament: OfferEngagement): void {
    this.logger.warning('OfferAccepting: the offer is set as pending!');
    this.storage.setPendingOffer(engagament);
  }
}
