import { BaseDialog, DialogReady, DialogState, OfferAccepting, PopupSuspended } from "../../index";

import EngagementCodes from "../../../../../enum/engagement_codes";
import LeadTypes from "../../../../../enum/lead_types";

export class DialogOffer extends BaseDialog implements DialogState {
  protected get engagementKey(): any {
    return this.offerEngagement?.notificationId ?? this.offerEngagement?.campaignId;
  }

  protected get historyId(): number | undefined {
    return this.offerEngagement?.historyId;
  }

  /**
   * @override
   */
  get leadType(): LeadTypes {
    return LeadTypes.offer;
  }

  /**
   * @override
   */
  close(): void {
    super.close();
    this.notifyRejected();
    this.clearOfferEngagement();
    this.switchDialogState(DialogReady);
    this.switchOfferState(OfferAccepting);
    this.switchPopupState(PopupSuspended);
  }

  /**
   * @override
   */
  protected notifyFailure(): void {
    this.notifier.notifyEngagement({
      engagement: EngagementCodes.offerIgnored,
      engagementKey: this.engagementKey
    });
  }

  protected notifyRejected(): void {
    if (this.hasOfferEngagament) {
      this.notifier.notifyRejectedOffer(this.historyId);
      this.notifier.notifyEngagement({
        engagement: EngagementCodes.offerIgnored,
        engagementKey: this.engagementKey
      });
    }
  }

  /**
   * @override
   */
  protected notifySuccess(): void {
    this.notifier.notifyEngagement({
      engagement: EngagementCodes.offerAccepted,
      engagementKey: this.engagementKey
    });
  }
}
