import { BaseOffer, OfferState } from "../../index";

export class OfferSuspended extends BaseOffer implements OfferState {
  /**
   * @override
   */
  showOffer(): void {
    this.logger.warning('OfferSuspended: offer functionality is suspended!');
  }
}
