export default class ChatStateError extends Error {
  private constructor(message: string) {
    super(message);
  }

  private static _build(message: string): ChatStateError {
    return new ChatStateError(message);
  }

  static invalidChannel(id: any): ChatStateError {
    return this._build(`Invalid channel with ID: ${id}`);
  }

  static undefinedEngagement(): ChatStateError {
    return this._build('Chat engagement is undefined!');
  }
}
