import {
  csvToArray,
  isNumber,
  isObject,
  parseBoolean,
  parseDate,
  parseNumber,
  parseString,
  parseZipRanges,
} from "../../../../service/lang";

import AppConfig from "../../app.config";
import Asset from "../asset";
import { BaseAsset } from "./base_asset";
import BaseDuration from "../../../../service/duration/base/base_duration";
import { BaseTemplate } from "./base_template";
import DeviceTypes from "../../enum/device_types";
import Duration from "../../../../service/duration/duration";
import Offer from "../offer";
import OfferTypes from "../../enum/offer_types";
import PureinfluencerAdapter from "../../adapter/pureinfluencer_adapter";
import Template from "../template";
import TimeUnits from "../../../../service/time_units";
import VisitorStatus from "../../enum/visitor_status";
import DemographicsFilterDto from "../demographics_filter_dto";
import { BaseDemographicsFilterDto } from "./base_demographics_filter";
import ZipRanges from "../zip_ranges";

export class BaseOffer implements Offer {
  private _json: any;

  constructor(json: any) {
    if (isObject(json)) {
      this._json = json;
    }
  }

  get asset(): Asset | undefined {
    return new BaseAsset(this._json?.[BaseOfferKeys.asset]);
  }

  get includeZIPsRange(): ZipRanges[] | undefined {
    return parseZipRanges(this._json?.[BaseOfferKeys.includeZIPsRange]);
  }

  get excludeZIPsRange(): ZipRanges[] | undefined {
    return parseZipRanges(this._json?.[BaseOfferKeys.excludeZIPsRange]);
  }

  get excludeZIPsMobileRange(): ZipRanges[] | undefined {
    return parseZipRanges(this._json?.[BaseOfferKeys.excludeZIPsMobileRange]);
  }

  get deviceTypes(): DeviceTypes[] {
    return PureinfluencerAdapter.deviceType(
      this._json?.[BaseOfferKeys.deviceType]
    );
  }

  get endDate(): Date | undefined {
    return parseDate(this._json?.[BaseOfferKeys.endDate]);
  }

  get engagementThreshold(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.engagementThreshold]);
  }

  get excludeMakes(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeMakes]);
  }

  get excludeVins(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeVins]);
  }

  get excludeModels(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeModels]);
  }

  get excludeURLs(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeURLs]);
  }

  get excludeZIPs(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeZIPs]);
  }

  get excludeZIPsMobile(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.excludeZIPsMobile]);
  }

  get expirable(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.expirable]);
  }

  get externalRefId(): string | undefined {
    return parseString(this._json?.[BaseOfferKeys.externalRefId]);
  }

  get hasStaticEngagementThreshold(): boolean {
    return parseBoolean(
      this._json?.[BaseOfferKeys.hasStaticEngagementThreshold]
    );
  }

  get includeMakes(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeMakes]);
  }

  get includeVins(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeVins]);
  }

  get includeTypes(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeTypes]);
  }

  get includeModels(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeModels]);
  }

  get includeURLs(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeURLs]);
  }

  get includeYears(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeYears]);
  }

  get includeZIPs(): string[] {
    return csvToArray(this._json?.[BaseOfferKeys.includeZIPs]);
  }

  get isOnlyPopup(): boolean {
    return this.isPopUpOffer && !this.isPullupOffer;
  }

  get isOnlyPullup(): boolean {
    return !this.isPopUpOffer && this.isPullupOffer;
  }

  get isPopupAndPullup(): boolean {
    return this.isPopUpOffer && this.isPullupOffer;
  }

  get isPopUpOffer(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.isPopUpOffer]);
  }

  get isPullupOffer(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.isPullUpOffer]);
  }

  get isBlendedOffer(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.isBlendedOffer]);
  }

  get leadLimit(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.leadLimit]);
  }

  get maxOffersPerSession(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.maxOffersPerSession]);
  }

  get rank(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.rank]);
  }

  get maximumSessionLength(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.maxSessionLength]);
  }

  get minimumSessionDuration(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.minSessionDuration]);
  }

  get minimumVDPDuration(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.minVDPDuration]);
  }

  get offerType(): OfferTypes {
    return PureinfluencerAdapter.offerType(
      this._json?.[BaseOfferKeys.offerType]
    );
  }

  get pullupOfferSource(): string | undefined {
    return parseString(this._json?.[BaseOfferKeys.pullUpOfferSource]);
  }

  get pullupSessionDuration(): Duration {
    return isNumber(this.maximumSessionLength)
      ? new BaseDuration(this.maximumSessionLength, TimeUnits.seconds)
      : AppConfig.instance.sessionDuration;
  }

  get showOnEveryVisit(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.showOnEveryVisit]);
  }

  get showOnLeave(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.showOnLeave]);
  }

  get startDate(): Date | undefined {
    return parseDate(this._json?.[BaseOfferKeys.startDate]);
  }

  get template(): Template {
    return new BaseTemplate(this._json?.[BaseOfferKeys.template]);
  }

  get termsAndConditions(): string | undefined {
    return parseString(this._json?.[BaseOfferKeys.termsAndConditions]);
  }

  get uniquePageViews(): number | undefined {
    return parseNumber(this._json?.[BaseOfferKeys.uniquePageViews]);
  }

  get useEngagementScore(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.useEngagementScore]);
  }

  get visitorStatus(): VisitorStatus[] {
    return PureinfluencerAdapter.visitorStatus(
      this._json?.[BaseOfferKeys.visitorStatus]
    );
  }

  get knownVisitor(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.knownVisitor]);
  }

  get semVendorsSegments(): string[] {
    return this._json?.[BaseOfferKeys.semVendorsSegments];
  }

  get demographicsFilter(): DemographicsFilterDto {
    return new BaseDemographicsFilterDto(
      this._json?.[BaseOfferKeys.demographicsFilter]
    );
  }

  get hasDemographicsFilter(): any {
    return this._json?.[BaseOfferKeys.demographicsFilter];
  }

  get offerName(): string | undefined {
    return parseString(this._json?.[BaseOfferKeys.offerName]);
  }

  get ignoreMakes(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.ignoreMakes]);
  }

  get ignoreModels(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.ignoreModels]);
  }

  get ignoreYears(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.ignoreYears]);
  }

  get ignoreVins(): boolean {
    return parseBoolean(this._json?.[BaseOfferKeys.ignoreVins]);
  }

  toJSON(): object | undefined {
    return this._json;
  }
}

export class BaseOfferKeys {
  static readonly asset: string = "asset";
  static readonly deviceType: string = "deviceType";
  static readonly endDate: string = "endDate";
  static readonly engagementThreshold: string = "engagementThreshold";
  static readonly excludeMakes: string = "makesToExclude";
  static readonly excludeVins: string = "vinsToExclude";
  static readonly excludeModels: string = "modelsToExclude";
  static readonly excludeURLs: string = "excludeUrls";
  static readonly excludeZIPs: string = "excludeZips";
  static readonly excludeZIPsRange: string = "excludeZipsRange";
  static readonly excludeZIPsMobile: string = "excludeZipsMobile";
  static readonly excludeZIPsMobileRange: string = "excludeZipsMobileRange";
  static readonly expirable: string = "expirable";
  static readonly externalRefId: string = "externalRefId";
  static readonly hasStaticEngagementThreshold: string =
    "staticEngagementThreshold";
  static readonly includeMakes: string = "makesToLimit";
  static readonly includeVins: string = "vinsToLimit";
  static readonly includeTypes: string = "typesToLimit";
  static readonly includeModels: string = "modelsToLimit";
  static readonly includeURLs: string = "includeUrls";
  static readonly includeYears: string = "yearsToLimit";
  static readonly includeZIPs: string = "includeZips";
  static readonly includeZIPsRange: string = "includeZipsRange";
  static readonly isPopUpOffer: string = "popUpOffer";
  static readonly isPullUpOffer: string = "pullUpOffer";
  static readonly isBlendedOffer: string = "blendedOffer";
  static readonly leadLimit: string = "leadLimit";
  static readonly maxOffersPerSession: string = "maxOffersPerSession";
  static readonly maxSessionLength: string = "maxSessionLength";
  static readonly minSessionDuration: string = "minSessionDuration";
  static readonly minVDPDuration: string = "minVdpDuration";
  static readonly offerType: string = "offerType";
  static readonly pullUpOfferSource: string = "pullUpOfferSource";
  static readonly showOnEveryVisit: string = "showOnEveryVisit";
  static readonly showOnLeave: string = "showOnLeave";
  static readonly startDate: string = "startDate";
  static readonly template: string = "template";
  static readonly termsAndConditions: string = "termsAndConditions";
  static readonly uniquePageViews: string = "uniquePageViews";
  static readonly useEngagementScore: string = "useEngagementScore";
  static readonly visitorStatus: string = "visibilityType";
  static readonly knownVisitor: string = "knownVisitor";
  static readonly semVendorsSegments: string = "semVendorsSegments";
  static readonly rank: string = "rank";
  static readonly demographicsFilter: string = "demographicsFilter";
  static readonly offerName: string = "name";
  static readonly ignoreMakes: string = "ignoreMakesToLimit";
  static readonly ignoreModels: string = "ignoreModelsToLimit";
  static readonly ignoreYears: string = "ignoreYearsToLimit";
  static readonly ignoreVins: string = "ignoreVinsToLimit";
}
