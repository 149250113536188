import { AndNotSpecification, AndSpecification, NotSpecification, OrNotSpecification, OrSpecification } from "./index";

import Specification from "./specification";
import SpecificationError from "./specification_error";
import SpecificationLogger from "./specification_logger";

export abstract class BaseSpecification implements Specification {
  get specLogger(): SpecificationLogger {
    throw SpecificationError.noLoggerProvided();
  }

  isSatisfiedBy(_candidate: any): boolean {
    throw SpecificationError.criteriaMethodNotImplemented();
  }

  and(condition: Specification): Specification {
    return new AndSpecification(this, condition);
  }

  andNot(condition: Specification): Specification {
    return new AndNotSpecification(this, condition);
  }

  or(condition: Specification): Specification {
    return new OrSpecification(this, condition);
  }

  orNot(condition: Specification): Specification {
    return new OrNotSpecification(this, condition);
  }

  not(): Specification {
    return new NotSpecification(this);
  }

  log(_candidate: any, _specification: any, _result: any): void {
    throw SpecificationError.logNotImplemented();
  }
}
