import AppConfig from "../../../app.config";
import BaseHTTPService from "../../../../../service/http/base/base_http_service";
import BaseStorageRegistrar from "../../storage/base/base_storage_registrar";
import Factory from "../../../../../service/factory";
import HTTPHeaders from "../../../../../service/http/http_headers";
import HTTPResponse from "../../../../../service/http/http_response";
import HTTPService from "../../../../../service/http/http_service";
import MIMETypes from "../../../../../service/mime_types";
import RTSCodes from "../../../../../service/rts_codes";
import RTSResponse from "../rts_response";
import RTSService from "../rts_service";
import StorageRegistrar from "../../storage/storage_registrar";
import { isNull, isUndefined } from "../../../../../service/lang";
import BaseResourceManager from "../../../manager/resource/base/base_resource_manager";
import Logger from "../../../../../service/logger/logger";
import BaseLogger from "../../../../../service/logger/base/base_logger";

export default class BaseRTSService implements RTSService {
  private _headers: any;
  private _http: HTTPService | undefined;
  private _storage: StorageRegistrar | undefined;
  private _resource: BaseResourceManager | undefined;
  private _logger?: Logger | undefined;

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  protected get headers(): any {
    if (isUndefined(this._headers)) {
      this._headers = {};
      this._headers[HTTPHeaders.contentType] = MIMETypes.applicationJSON_UTF8;
      this._headers[HTTPHeaders.secToken] = AppConfig.instance.rtsSectok;
    }

    return this._headers;
  }

  protected get http(): HTTPService {
    if (isUndefined(this._http)) {
      this._http = Factory.instance.build(BaseHTTPService);
    }

    return this._http;
  }

  protected get resource(): BaseResourceManager {
    if (isUndefined(this._resource)) {
      this._resource = Factory.instance.build(BaseResourceManager);
    }

    return this._resource;
  }

  protected get isDeactivated(): boolean {
    return this.storage.getDealershipDeactivated();
  }

  protected get storage(): StorageRegistrar {
    if (isUndefined(this._storage)) {
      this._storage = Factory.instance.build(BaseStorageRegistrar);
    }

    return this._storage;
  }

  protected handleResponse(response: HTTPResponse): void {
    if (response?.data?.error_code === RTSCodes.dealershipNotFound) {
      this.storage.setDealershipDeactivated();
    }

    const dealershipTs = response?.data?.dealershipTs;
    const currentDealershipTs = this.storage.getDealershipTs();
    const visitorId = this.storage.getVisitorId();

    if (
      isUndefined(currentDealershipTs) ||
      currentDealershipTs < dealershipTs
    ) {
      this.resource?.loadConfiguration(true, visitorId);
      this.storage.setDealershipTs(dealershipTs);
    }
  }

  sendData(data: any, initial?: boolean): Promise<RTSResponse> {
    let dataObj = {
      ...data,
      hasSmartPixlData:
        !isUndefined(this.storage.getHasVisitorInfo()) &&
        !isNull(this.storage.getHasVisitorInfo())
          ? true
          : false,
      disposition:
        !isUndefined(this.storage.getMasterConfig()) &&
        !isNull(this.storage.getMasterConfig())
          ? this.storage.getMasterConfig()?.disposition
          : null,
    };

    return new Promise((resolve, reject) => {
      if (this.isDeactivated) {
        reject("The Dealership is deactivated!!!");
      } else {
        this.http
          .put({
            data: initial == true ? dataObj : data,
            headers: this.headers,
            url: AppConfig.instance.urlRTSPayload,
          })
          .then((response) => {
            this.handleResponse(response);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      }
    });
  }

  setHTTPService(update: HTTPService): void {
    this._http = update;
  }
}
