import Offer from "../../../../../model/offer";
import SegmentIncluded from "./segment_included";

export default class SourcesIncluded extends SegmentIncluded {
  /**
   * @override
   */
  protected getSegmentsData(source: Offer): string[] {
    if (source?.semVendorsSegments && source?.semVendorsSegments?.length > 0) {
      return source.semVendorsSegments;
    }
    return [];
  }
}
