import Offer from "../../../model/offer";
import StateSpecification from "./state_specification";
import { parseNumber } from "../../../../../service/lang";

export default class EngagementMatched extends StateSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: Offer): boolean {
    let isSatisfied: boolean;
    const useScore: boolean = candidate?.useEngagementScore;

    if (useScore) {
      let threshold: number;
      const visitorScore: number = this.getVisitorScore();

      if (visitorScore === 0) {
        isSatisfied = true
      } else {
        if (candidate.hasStaticEngagementThreshold) {
          threshold = parseNumber(candidate.engagementThreshold) ?? 0;
        } else {
          threshold = this.getDealershipScore();
        }

        isSatisfied = visitorScore > 0 && visitorScore >= threshold;
      }
    } else {
      isSatisfied = true;
    }

    this.log(candidate, EngagementMatched, isSatisfied);

    return isSatisfied;
  }
}
