import { isArray, isEmpty, isString } from "../../../../../service/lang";

import URLSpecification from "./url_specification";

export default class URLExcluded extends URLSpecification {
  /**
   * @override
   */
  isSatisfiedBy(candidate: any): boolean {
    let isSatisfied: boolean;
    const path: string | undefined = this.getCurrentPath();

    if (isString(path)) {
      const excluded = candidate?.excludeURLs;

      if (isArray(excluded)) {
        const paths = this.url.URLsToPaths(excluded);

        if (isEmpty(paths)) {
          isSatisfied = false;
        } else {
          isSatisfied = paths.includes(path);
        }
      } else {
        isSatisfied = false;
      }
    } else {
      isSatisfied = false;
    }

    this.log(candidate, URLExcluded, isSatisfied);

    return isSatisfied;
  }
}