export default class LeadKeys {
  static readonly campaignId = 'campaignExtRefId';
  static readonly campaignType = 'campaignType';
  static readonly creativeHTML = 'creativeHtml';
  static readonly creativeType = 'creativeType';
  static readonly dealershipId = 'dealershipExtRefId';
  static readonly engagementScore = 'engagementScore';
  static readonly leadType = 'leadType';
  static readonly make = 'make';
  static readonly model = 'model';
  static readonly notificationId = 'rtsNotificationExtRefId';
  static readonly offerHistoryId = 'offerHistoryId';
  static readonly offerId = 'offerRefId';
  static readonly pageType = 'pageType';
  static readonly pageViewId = 'pageViewId';
  static readonly scrollDepth = 'scrollDepth';
  static readonly sessionId = 'sessionId';
  static readonly sessionTime = 'sessionTime';
  static readonly url = 'url';
  static readonly userId = 'userRefId';
  static readonly utmCampaign = 'utmCampaign';
  static readonly utmContent = 'utmContent';
  static readonly utmMedium = 'utmMedium';
  static readonly utmTerm = 'utmTerm';
  static readonly utmSource = 'utmSource';
  static readonly vehicleType = 'vehicleType';
  static readonly visitorId = 'visitorExtRefId';
  static readonly year = 'year';
}
