import {
  ChatActivityPayload,
  ChatEngagementAcceptData,
  ChatbotTimeoutData,
  PersistenChatMessageData,
  PersistentChatMessageResponse,
  PersistentChatShownData,
} from "../typedef";

import AppConfig from "../../../app.config";
import BackendService from "../backend_service";
import BaseDealershipService from "../../../../../service/dealership/base/base_dealership_service";
import BaseHTTPService from "../../../../../service/http/base/base_http_service";
import BaseLogger from "../../../../../service/logger/base/base_logger";
import { BaseMasterConfig } from "../../../model/base/base_master_config";
import DealershipService from "../../../../../service/dealership/dealership_service";
import Factory from "../../../../../service/factory";
import HTTPHeaders from "../../../../../service/http/http_headers";
import HTTPResponse from "../../../../../service/http/http_response";
import HTTPService from "../../../../../service/http/http_service";
import HTTPStatus from "../../../../../service/http/http_status";
import Logger from "../../../../../service/logger/logger";
import MIMETypes from "../../../../../service/mime_types";
import MasterConfig from "../../../model/master_config";
import { isUndefined } from "../../../../../service/lang";

export default class BaseBackendService implements BackendService {
  private _dealership: DealershipService | undefined;
  private _headers: any;
  private _http: HTTPService | undefined;
  private _logger: Logger | undefined;

  protected get dealership(): DealershipService {
    if (isUndefined(this._dealership)) {
      this._dealership = Factory.instance.build(BaseDealershipService);
    }

    return this._dealership;
  }

  protected get dealershipId(): string {
    return this.dealership.findDealershipId();
  }

  protected get headers(): any {
    if (isUndefined(this._headers)) {
      this._headers = {};
      this._headers[HTTPHeaders.contentType] = MIMETypes.applicationJSON_UTF8;
    }

    return this._headers;
  }

  protected get http(): HTTPService {
    if (isUndefined(this._http)) {
      this._http = Factory.instance.build(BaseHTTPService);
    }

    return this._http;
  }

  protected get logger(): Logger {
    if (isUndefined(this._logger)) {
      this._logger = Factory.instance.build(BaseLogger);
    }

    return this._logger;
  }

  getMasterConfiguration(visitorId?: string): Promise<MasterConfig> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          data: { visitorExtRefId: visitorId ?? null },
          url: AppConfig.instance.urlDealershipConfig(this.dealershipId),
        })
        .then((response) => {
          if (response.status === HTTPStatus.OK) {
            resolve(new BaseMasterConfig(response.data));
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  }

  notifyChatbotTimeout(data: ChatbotTimeoutData): Promise<HTTPResponse> {
    return this.http.post({
      data: data,
      headers: this.headers,
      url: AppConfig.instance.urlChatbotNoResponse,
    });
  }

  notifyChatEnded(historyId: number): Promise<HTTPResponse> {
    return this.http.post({
      data: { chatHistoryId: historyId },
      headers: this.headers,
      url: AppConfig.instance.urlChatEnded,
    });
  }

  notifyChatEngagementAccept(
    data: ChatEngagementAcceptData
  ): Promise<HTTPResponse> {
    return this.http.post({
      data: data,
      headers: this.headers,
      url: AppConfig.instance.urlChatEngagementAccept,
    });
  }

  notifyPersistentChatActivity(
    data: ChatActivityPayload
  ): Promise<HTTPResponse> {
    return this.http.post({
      data: data,
      headers: this.headers,
      url: AppConfig.instance.urlPersistentChatActivity,
    });
  }

  notifyPersistentChatEnded(historyId: number): Promise<HTTPResponse> {
    return this.http.post({
      data: { chatHistoryId: historyId },
      headers: this.headers,
      url: AppConfig.instance.urlPersistentChatEnded,
    });
  }

  notifyPersistentChatShown(
    data: PersistentChatShownData
  ): Promise<HTTPResponse> {
    return this.http.post({
      data: data,
      headers: this.headers,
      url: AppConfig.instance.urlPersistentChatShown,
    });
  }

  ping(url: string): Promise<HTTPResponse> {
    return this.http.get({ headers: this.headers, url: url });
  }

  postLead(data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          data: data,
          headers: this.headers,
          url: AppConfig.instance.urlPILead,
        })
        .then((response) => {
          if (response.status == HTTPStatus.OK) {
            resolve();
          } else {
            reject(response.data);
          }
        })
        .catch((error) => reject(error));
    });
  }

  sendPersistenChatMessage(
    data: PersistenChatMessageData
  ): Promise<PersistentChatMessageResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          data: data,
          headers: this.headers,
          url: AppConfig.instance.urlPersistentChatMessage,
        })
        .then((response) => {
          if (response.status === HTTPStatus.OK) {
            resolve(response.data);
          } else {
            reject();
          }
        })
        .catch((error) => reject(error));
    });
  }
}
