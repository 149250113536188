import Geolocation from "../geolocation";

export default class BaseGeolocation implements Geolocation {
  private _json: any;

  constructor(json: any) {
    this._json = json;
  }

  get city(): string {
    return this._json?.city;
  }

  get country(): string {
    return this._json?.country;
  }

  get ip(): string {
    return this._json?.query;
  }

  get region(): string {
    return this._json?.regionName;
  }

  get zip(): string {
    return this._json?.zip;
  }

  toJSON(): object {
    return this._json;
  }
}