import BaseURLService from "../../../service/url/base/base_url_service";
import Factory from "../../../../../service/factory";
import OfferSpecification from "../offer_specification";
import URLService from "../../../service/url/url_service";
import { isUndefined } from "../../../../../service/lang";

export default class URLSpecification extends OfferSpecification {
  private _url: URLService | undefined;

  protected get url(): URLService {
    if (isUndefined(this._url)) {
      this._url = Factory.instance.build(BaseURLService);
    }

    return this._url;
  }

  getCurrentPath(): string | undefined {
    return this.url.getCurrentPath();
  }

  setURLService(update: URLService): void {
    this._url = update;
  }
}