import AppConfig from "../../app.config";
import Validator from "./validator";

export default class DisclaimerValidator implements Validator {
  error(_name?: string): string {
    return AppConfig.instance.locale.validationDisclaimer;
  }

  validate(input: any): boolean {
    return input?.checked === true;
  }
}
