import Generator from "../generator";

export default class BaseGenerator implements Generator {
  private readonly _characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  id(length: number): string {
    let id = '';

    for (let i = 0; i < length; i++) {
      const random = Math.random();
      const charIndex = random * this._characters.length;
      const actualIndex = Math.floor(charIndex);
      const char = this._characters.charAt(actualIndex);
      id += char;
    }

    return id;
  }
}
